import Map from 'ol/Map';
import VectorSource from 'ol/source/Vector';
import { Feature, MapBrowserEvent } from 'ol';
import { GeometryCollection, Point } from 'ol/geom';
import { Subject } from 'rxjs';
import { Coordinate } from 'ol/coordinate';
import { FeatureOrCollection } from '../../classes/collection-flattener';
import { FeatureChangeType, MapFeatureChange } from '../../classes/map-feature-change';
import { OpenLayersUtilities } from '../../classes/open-layers-utilities';
import { Interaction } from 'ol/interaction';
import Styles from '../styles';

export class StickerTool extends Interaction {
  private readonly sourceRef: VectorSource;
  private readonly coords: Coordinate;
  private readonly olUtilities: OpenLayersUtilities;
  private readonly sticker: Sticker;
  private readonly mapRef: Map;
  private featureChanges$: Subject<MapFeatureChange<FeatureOrCollection>>;

  constructor({
    mapRef,
    sourceRef,
    sticker,
    featureChanges,
    olUtilities,
  }: {
    mapRef: Map;
    sourceRef: VectorSource;
    sticker: Sticker;
    featureChanges: Subject<MapFeatureChange<FeatureOrCollection>>;
    olUtilities: OpenLayersUtilities;
  }) {
    super({
      handleEvent,
    });
    this.sourceRef = sourceRef;
    this.sticker = sticker;
    this.featureChanges$ = featureChanges;
    this.olUtilities = olUtilities;
    this.mapRef = mapRef;
  }

  public addSticker(coords: Coordinate) {
    const feature = new Feature();
    const image = new Image();
    image.src = this.sticker.StickerFile;
    const featureStyle = Styles.sticker(image, this.mapRef);
    feature.setStyle(featureStyle);
    feature.setGeometry(this.buildGeom(coords));
    feature.setProperties(
      {
        shape_type_id: 7,
        image_source: this.sticker.StickerFile,
      },
      true
    );
    this.sourceRef.addFeature(feature);
    this.featureChanges$.next(new MapFeatureChange(FeatureChangeType.added, feature, undefined, this.sourceRef));
  }

  private buildGeom(coords: Coordinate): GeometryCollection {
    const img = new Image();
    img.src = this.sticker.StickerFile;
    const poly = this.olUtilities.createRectangleGeometry(this.mapRef, coords, img.naturalWidth, img.naturalHeight);
    const point = new Point(coords);
    return new GeometryCollection([point, poly]);
  }
}

function handleEvent(evt: MapBrowserEvent<UIEvent>): boolean {
  if (evt.type === "singleclick") {
    this.addSticker(evt.coordinate);
    return false;
  }
  return true;
}

type ISODateTimeString = string;
export type Sticker = {
  CompanyID: number;
  ID: number;
  LastChanged: ISODateTimeString;
  Name: string;
  StickerFile: string;
  StickerFileName: string;
  StickerID: number;
};
