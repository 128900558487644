<div id="homeContainer" fxLayout="column" class="home-container">
  <!-- top navigation -->
  <app-navigation-bar [pageTitle]="currentTitle" [showNavigateBack]="showSettingsBackButton"></app-navigation-bar>
  <mat-sidenav-container class="side-nav-container" [hasBackdrop]="hasBackdrop">
    <mat-sidenav class="rounded-r-[12px]" #drawer [disableClose]="disableClose" [mode]="sidenavMode" [opened]="opened">
      <app-side-navigation [sidenavState]="sidenavState" (titleChange)="onTitleChange($event)"></app-side-navigation>
    </mat-sidenav>
    <mat-sidenav-content [@onMainContentChange]="onMainContentChange()">
      <div class="router-page-container max-w-[calc(100vw - 72px)] w-full overflow-hidden">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
