import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { SnackbarService } from '../../snackbar/snackbar.service';
import { SnackbarType } from '../../snackbar/snackbar/snackbar';
import { LonLat } from '../../../drawing-module/services/open-layers.service';
import { LayerQueryFilters } from '../../../drawing-module/utilities/types';
import { Ticket } from '../ticket.service';
import { DrawingComponent, FormType } from 'src/app/modules/drawing-module/drawing/drawing.component';

export interface TicketMapObject {
  Latitude: number;
  Longitude: number;
  Address: string;
  EsriAvailable: boolean;
  EsriFirst: boolean;
  RequestNumber: number;
  CallCenterID: number;
  AssignmentID: string;
}

@Component({
  selector: 'app-ticket-drawing',
  template: `
    <app-drawing
      class="flex-grow h-full"
      [formType]="drawingFormType"
      [layerFilterParameters]="layerFilterParameters"
      [ticket]="ticket"
      [primaryID]="primaryID"
      [assignmentID]="assignmentID"
      [coordinates]="ticketCoords"
      [isActive]="isActive"
      (drawingModeChange)="drawingModeChange.emit($event)" />
  `,
})
export class TicketDrawingComponent implements OnChanges {
  @ViewChild(DrawingComponent) drawingComponent: DrawingComponent;
  layerFilterParameters: Partial<LayerQueryFilters>;
  @Input() ticket: Ticket;
  @Input() primaryID: number;
  @Input() assignmentID: number;
  @Input() utilities: number[];
  @Input() isActive: boolean;
  @Input() drawingFormType: FormType;
  @Output() Swipe = new EventEmitter<any>();
  @Output() mapControlEnabled = new EventEmitter<any>();
  @Output() documentUpload = new EventEmitter<any>();
  @Output() drawingModeChange = new EventEmitter<any>();

  // Location
  ticketCoords: LonLat;

  // Other
  bottomMenuOpen = false;

  constructor(private snackbarService: SnackbarService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.ticket) {
      this.layerFilterParameters = {
        requestNumber: this.ticket.RequestNumber,
        utilityID: this.utilities,
      };
      this.setupLocation();
    }
  }

  setupLocation() {
    if (this.ticket?.Longitude && this.ticket?.Latitude) {
      this.ticketCoords = [this.ticket.Longitude, this.ticket.Latitude].map((x) => parseFloat(x)) as LonLat;
    } else {
      this.snackbarService.openSnackbar('Failed to get Ticket Location', SnackbarType.error);
    }
  }
}
