import { LayerQueryFilters } from '../../../utilities/types';
import Layer from 'ol/layer/Layer';
import { DatabaseLayer } from '../types/layer.types';

export abstract class BaseLayerBuilder {
  abstract build(layer: DatabaseLayer, filters: Partial<LayerQueryFilters>): Promise<Layer>;

  protected initLayerConfig(layer: DatabaseLayer) {
    layer.config = {
      showLabels: false,
      isVisible: layer.isDefaultOn === 1,
    };
  }
}
