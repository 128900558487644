<div class="flex flex-col justify-start max-w-full lg:max-w-[90vw] max-h-[90vh]">
  <!-- header showing name of file  -->
  <div class="mat-headline-1 gallery-header-container flex items-center justify-between">
    <h1 mat-dialog-title class="gallery-header font-rajdhani uppercase font-semibold">{{ data.name }}</h1>
    <div class="pb-[9px]">
      <button mat-icon-button aria-label="Example icon button with a vertical three dot icon" (click)="prevImg()">
        <mat-icon class="icon h-4 w-4">arrow_back_ios</mat-icon>
      </button>
      <button mat-icon-button aria-label="Example icon button with a vertical three dot icon" (click)="nextImg()">
        <mat-icon class="icon h-4 w-4">arrow_forward_ios</mat-icon>
      </button>
    </div>
  </div>
  <!-- photo itself  -->
  <div mat-dialog-content class="flex flex-row items-center justify-center w-full h-full p-4 photo max-w-full max-h-full">
    <mat-progress-spinner
    *ngIf="loading"
    mode="indeterminate"
    diameter="36"
    class="mat-small-spinner"
  ></mat-progress-spinner>
    <img
      [alt]="data.name"
      *ngIf="isImage === true && !loading"
      [src]="url"
      class="max-w-full max-h-[80vh] w-auto h-auto object-contain rounded-lg p-12" /> <!-- Added 'rounded-lg' for rounded corners and 'p-12' for padding -->
    <!-- 
      see https://tailwindcss.com/docs/object-fit#scaling-down-if-too-large 
      for more info on object-contain 
    -->
    <p *ngIf="isPlaintext === true" class="scrollable-text font-montserrat">{{ message }}</p>
    <div *ngIf="dbFile === true"><mat-icon class="w-full h-[80vh] blue" svgIcon="reports"></mat-icon></div>
    <iframe *ngIf="isPdf === true" class="min-h-[79vh]" [src]="safeUrl" width="100%" height="100%"></iframe>
  </div>

  <!-- bottom buttons  -->
  <div class="flex flex-row justify-between p-2 pl-4 absolute bottom-0 right-0 w-[98%]">
    <p class="font-montserrat text-md">{{ data.caption }}</p>

    <div mat-dialog-actions class="flex flex-row items-end justify-end space-x-2">
      <button mat-dialog-close class="btn-cancel">Close</button>
      <button class="btn-save" (click)="onConfirm($event)">Download</button>
    </div>
  </div>
</div>