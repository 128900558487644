import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDrawerMode, MatSidenav } from "@angular/material/sidenav";
import { Subscription } from "rxjs";
import { NavigationBarService } from "../../shared/navigation-bar/navigation-bar.service";
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";
import {
  BreakpointService,
  BreakpointType,
} from "../../core/services/breakpoint/breakpoint.service";
import { NavigationEnd, Router } from "@angular/router";

export const onMainContentChange = trigger("onMainContentChange", [
  state(
    "close",
    style({
      "margin-left": "0px",
    }),
  ),
  state(
    "mini",
    style({
      "margin-left": "72px",
    }),
  ),
  state(
    "open",
    style({
      "margin-left": "240px",
    }),
  ),
  transition("close => mini", animate("250ms ease-in")),
  transition("mini => close", animate("250ms ease-in")),

  transition("close => open", animate("250ms ease-in")),
  transition("open => close", animate("250ms ease-in")),

  transition("mini => open", animate("250ms ease-in")),
  transition("open => mini", animate("250ms ease-in")),
]);
@Component({
  selector: "app-nav",
  templateUrl: "./app-nav.component.html",
  styleUrls: ["./app-nav.component.scss"],
  animations: [onMainContentChange],
})
export class AppNavComponent implements OnInit, OnDestroy {
  // menuToggled$:Observable<boolean>
  @ViewChild("drawer", { static: true }) drawer: MatSidenav;

  navigationSubscription: Subscription;
  breakpointSubscription: Subscription;
  public onSideNavChange: boolean = false;
  hasBackdrop: boolean = false;
  disableClose: boolean = true;
  opened: boolean = true;
  isNavRail: boolean = true;
  sidenavMode: MatDrawerMode = "side";
  sidenavState: string = "mini";
  currentTitle: string = '';
  showSettingsBackButton: boolean = false;

  constructor(
    private navigationBar: NavigationBarService,
    private breakpointService: BreakpointService,
    private router: Router, 
  ) {
    this.breakpointSubscription = this.breakpointService
      .breakpointChanges()
      .subscribe((breakpointType) => {
        this.breakpointSetup(breakpointType);
      });
      this.setRouteForGivenPath(this.router.url);
  }

  ngOnInit(): void {
    this.setupNavigationSubscription();
    this.router.events.pipe().subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.setRouteForGivenPath(event.url);
      }
    });
  }

  setRouteForGivenPath(url: string) {

    const dontShowSubrouteRoutes = ['reports', 'system-maintenance'];

    const myURL = url.split('/app/')[1];
    const eventUrlPieces = myURL.split('/');
    if (eventUrlPieces.length == 1 || dontShowSubrouteRoutes.includes(eventUrlPieces[0]) || (['settings'].includes(eventUrlPieces[0]) && ['list'].includes(eventUrlPieces[1])) ) {
      this.currentTitle = eventUrlPieces[0].replace('-', ' ');
    } else {
      this.currentTitle = eventUrlPieces[1].replace('-', ' ');
    }
    if  (['settings'].includes(eventUrlPieces[0]) && eventUrlPieces[1] != null && !['list'].includes(eventUrlPieces[1])) {
      this.showSettingsBackButton = true;
    } else {
      this.showSettingsBackButton = false;
    }
  }

  /**
   * Actions when navigation menu toggled
   */
  setupNavigationSubscription() {
    try {
      this.navigationSubscription = this.navigationBar.menuToggled$.subscribe(
        (active) => {
          if (this.isNavRail) {
            if (active) {
              this.sidenavState = "open";
            } else {
              this.sidenavState = "mini";
            }
          } else {
            this.sidenavState = "open";
            this.drawer.toggle();
          }
        },
      );
    } catch (error) {
      console.error(error);
    }
  }

  /**
   *
   * @returns set sidenav state based on whether nav rail or not
   */
  onMainContentChange() {
    if (this.isNavRail) {
      return this.sidenavState;
    } else {
      return "close";
    }
  }

  onTitleChange(title: string){
    console.log("onTitleChange");
    // By deferring the assignment using setTimeout, the change will occur after Angular's change detection cycle is complete

    // setTimeout(() => {
    //   this.currentTitle = title;
    //   this.cdr.detectChanges(); // Detect changes after setting the title
    // });
    // if (title.toLowerCase() === 'settings') {
    //   this.showSettingsBackButton = true;
    //   this.router.events.pipe().subscribe((event) => {
    //     if (event instanceof NavigationEnd) {
    //       // const url = event.url;
    //       //get the url (ex: '/app/settings/user-manager')
    //       //get the third word, and replace all dashes with spaces 
    //       const newTitle = event.url.split('/')[3].replace('-', ' ');
          
    //       //if our title isn't list, then make the title settings 
    //       if (newTitle != 'list') {
    //         this.currentTitle = newTitle;
    //       } else {
    //         this.currentTitle = 'settings';
    //       }
    //     }
    //   });
    // } else {
    //   this.showSettingsBackButton = false;
    // }
  }

  /**
   * Setup actions for each breakpoint
   * @param breakpointType
   */
  breakpointSetup(breakpointType: BreakpointType) {
    try {
      switch (breakpointType) {
        case BreakpointType.xs:
        case BreakpointType.sm:
          this.hasBackdrop = true;
          this.disableClose = false;
          this.opened = false;
          this.isNavRail = false;
          this.sidenavMode = "over";
          break;

        case BreakpointType.md:
        case BreakpointType.lg:
        case BreakpointType.xl:
          this.hasBackdrop = false;
          this.disableClose = true;
          this.opened = true;
          this.isNavRail = true;
          this.sidenavMode = "side";
          this.sidenavState = "mini";
          this.navigationBar.closeMenu();
          break;

        default:
          this.hasBackdrop = false;
          this.disableClose = true;
          this.opened = true;
          this.isNavRail = true;
          this.sidenavMode = "side";
          this.sidenavState = "mini";
          this.navigationBar.closeMenu();
          break;
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Unsubscribe to observables
   */
  ngOnDestroy(): void {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
    if (this.breakpointSubscription) {
      this.breakpointSubscription.unsubscribe();
    }
  }
}
