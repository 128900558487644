import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { apiKeys } from 'src/app/ENDPOINTS';
import { UtilocateApiRequest } from 'src/app/modules/core/api/baseapi.service';
import { UtilocateApiService } from 'src/app/modules/core/api/utilocateapi.service';
import { isNumber, safeAwait } from 'src/app/modules/core/validators/validator';
import * as Template from 'src/app/modules/shared/ticket/form-template-inputs/default-form-input-template.json';
import { FormTemplateValidators } from './form-template-helper';
import { FormTemplateField, FormTemplateGroup, FormTemplateView, FormTemplateViews } from './form-template.interface';
import { CacheService, StoreType } from 'src/app/modules/core/cache/cache.service';

export enum TicketDetailInputType {
  Checkbox = 1,
  String = 2,
  Integer = 3,
  Float = 4,
  CheckboxHS = 5,
  StringHS = 6,
  IntegerHS = 7,
  FloatHS = 8,
  Photo = 9,
  Datepicker = 10,
  Singleselect = 11,
  Multiselect = 12,
  Autocomplete = 13,
  DateTimePicker = 17,
}

@Injectable({
  providedIn: "root",
})
export class FormTemplateService {

  DEFAULT_TEMPLATE: any = Template


  constructor(
    private utilocateApiService: UtilocateApiService,
    private formbuilderService: FormBuilder,
    private idb: CacheService,
  ) {}

  async getTemplateViews(formInputTemplateTypeID: number, callTypeID: string) {
    let callTypeTemplate = null;
    let finalTemplate = null;
    const formTemplate = [];
    let parsedTemplate = null;
    let insertResult = null;

    try {
      const queryResult = await this.idb.query(
        StoreType.FORM_TEMPLATES,
        "formTemplate"
      );
      if(queryResult){
        callTypeTemplate = queryResult.find((template: any) => template.callType == callTypeID && template.formType == formInputTemplateTypeID);

        if (callTypeTemplate != null) {
          parsedTemplate = JSON.parse(callTypeTemplate.template)
          finalTemplate = await this.getViews(parsedTemplate)
          return finalTemplate
        } else {
          const defaultTemplate = queryResult.find((template: any) => template.callType == -1 && template.formType == formInputTemplateTypeID);
          if(defaultTemplate != null){
            parsedTemplate = JSON.parse(defaultTemplate.template)
            finalTemplate = await this.getViews(parsedTemplate)
            return finalTemplate
          }
        }
      }

      const [templates, error] = await safeAwait(
        this.getTemplateFromServer(formInputTemplateTypeID)
      );
      
      if (!error && Array.isArray(templates)) {
        for (const template of templates) {
          
          if (!template.views) {
            console.error("FAILED TO LOAD FROM SERVER");
            finalTemplate = await this.getViews(this.DEFAULT_TEMPLATE)
            return finalTemplate
          }
          const serializedForm = JSON.stringify(template);

          formTemplate.push({
            template: serializedForm,
            formType: formInputTemplateTypeID,
            callType: template.callTypeID
          })

          if (callTypeID == template.callTypeID) {
            callTypeTemplate = await this.getViews(template)
          } else if (template.callTypeID == -1) {
            finalTemplate = await this.getViews(template);
          }
        }

        insertResult = await this.idb.insert(
          StoreType.FORM_TEMPLATES,
          "formTemplate",
          formTemplate
        );

        if(!insertResult){
          console.error('Insert failed')
        }

        if (callTypeTemplate != null) {
          finalTemplate = callTypeTemplate;
        }

        if (!finalTemplate) {
          finalTemplate = await this.getViews(this.DEFAULT_TEMPLATE)
        }
        return finalTemplate
      } else {
        console.log(error);
        finalTemplate = await this.getViews(this.DEFAULT_TEMPLATE)
        return finalTemplate
      }
    } catch (error) {
      console.error("Form Error", error)
    }
  }

  async getViews(template){
    const views = {};
    const promiseArr = [];
    for (const viewKey in template.views) {
      const view: FormTemplateView = template.views[viewKey];
      promiseArr.push(this.createFormGroupsFromView(view));
    }

    const [generatedViews, errors] = await safeAwait(Promise.all(promiseArr));
    if (!errors) {
      for (let i = 0; i < generatedViews.length; i++) {
        const curView: FormTemplateView = generatedViews[i];
        views[curView.key] = curView;
      }
      return views;
    } else {
      throw errors;
    }
  }



  convertBillingDetailsToFormTemplate(
    utilityRows: any[],
    utilityBillingCatRows: any[],
    utilityBillingFields: any[],
    tbCompletions_AuxiliaryDetails: any[],
    tbCompletions_Billing: any[],
    tbCompletions_Primary: any[],
    fieldsViewOnly: boolean = false
  ): FormTemplateViews {
    const utilityBillingViews: FormTemplateViews = new FormTemplateViews();
    utilityBillingViews.views = {};
    try {
      if (tbCompletions_AuxiliaryDetails.length > 0) {
        const utilityBillingView: FormTemplateView = new FormTemplateView(
          "Tracking",
          "Tracking"
        );
        utilityBillingView.groups = {};

        for (let i = 0; i < tbCompletions_AuxiliaryDetails.length; i++) {
          const { UtilityID, AuxiliaryDetailID, UtilityExpiryDate } =
            tbCompletions_AuxiliaryDetails[i];
          // eslint-disable-next-line no-var
          var { UtilityName } = utilityRows.find(
            (row) => row["UtilityID"] == UtilityID
          );


          //check if the ticket has a DateCompleted in tbCompletions_Primary
          let dateCompleted = null;
          if (tbCompletions_Primary.length > 0) {
            dateCompleted = tbCompletions_Primary.find((row) => row['PrimaryID'] === tbCompletions_AuxiliaryDetails[i]['PrimaryID'])['DateCompleted'];
          }


          // IF we have an expiry date to show,
          // AND we have a dateCompleted,
          // THEN then set the subtitle

          let subtitle = null;
          if (UtilityExpiryDate && dateCompleted) {

            //find the numbert of days between UtilityExpiryDate and dateCompleted
            const utilityExpiryDateObj: Date = new Date(UtilityExpiryDate);
            const dateCompletedObj: Date = new Date(dateCompleted);

            const diffTime: number = Math.abs(utilityExpiryDateObj.getTime() - dateCompletedObj.getTime());
            const diffDays: number = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

            subtitle = `Valid for ${diffDays} Days until ${new Date(UtilityExpiryDate).toISOString().split("T")[0]}`
          }

          const utilityBillingGroup: FormTemplateGroup = new FormTemplateGroup(
            UtilityName,
            AuxiliaryDetailID.toString(),
            1,
            subtitle,
          );
          utilityBillingGroup.fields = {};

          if (tbCompletions_Billing.length > 0) {
            const billingRowsForAuxDetailID = tbCompletions_Billing.filter(
              (row) => row["AuxiliaryDetailID"] == AuxiliaryDetailID.toString()
            );
            for (let j = 0; j < billingRowsForAuxDetailID.length; j++) {
              const { BillingID, UtilityBillingCatID } =
                billingRowsForAuxDetailID[j];
              const { DataType, FieldTypeCat } =
                utilityBillingCatRows[UtilityBillingCatID];

              const FieldOrder = utilityBillingFields[UtilityBillingCatID] ? utilityBillingFields[UtilityBillingCatID].FieldOrder : utilityBillingCatRows[UtilityBillingCatID].FieldOrder;

              let inputTypeID = 1;
              switch (DataType) {
                case "boolean":
                  inputTypeID = 1;
                  break;
                case "integer":
                  inputTypeID = 3;
                  break;
                case "decimal":
                  inputTypeID = 4;
                  break;
              }

              const utilityBillingField: FormTemplateField =
                new FormTemplateField(
                  BillingID,
                  FieldTypeCat,
                  inputTypeID,
                  FieldOrder,
                  fieldsViewOnly
                );
              utilityBillingGroup.fields[BillingID] = utilityBillingField;
            }

            if (Object.keys(utilityBillingGroup.fields).length > 0) {
              utilityBillingView.groups[AuxiliaryDetailID] =
                utilityBillingGroup;
            }
          }
        }

        if (Object.keys(utilityBillingView.groups).length > 0) {
          utilityBillingViews.views[UtilityName] = utilityBillingView;
        }
        return utilityBillingViews;
      } else {
        // will make all child views too
        utilityBillingViews.makeDefault();
        return utilityBillingViews;
      }
    } catch (error) {
      console.log(error);
      return utilityBillingViews;
    }
  }

  convertUtilityToFormTemplate(
    tablename: string,
    utilityRows: any[],
    tbAdmin_PrimaryDetailsCategories: any[],
    tbAdmin_PrimaryDetailFields: any[],
    fieldRules: any[],
    tbCompletions_PrimaryDetails: any[],
    locateComplete: boolean = false
  ): FormTemplateViews {
    const utilityViews: FormTemplateViews = new FormTemplateViews();
    utilityViews.views = {};

    try {
      if (utilityRows.length > 0) {
        let utilityView: FormTemplateView;
        for (let i = 0; i < utilityRows.length; i++) {
          //! create each view
          const { UtilityName, UtilityID } = utilityRows[i];
          utilityView = new FormTemplateView(UtilityName, UtilityID);
          utilityView.groups = {};

          const primaryCategoriesByUtilityType =
            tbAdmin_PrimaryDetailsCategories.filter(
              (row) => row["UtilityType"] == UtilityID
            );

          let utilityGroup: FormTemplateGroup = new FormTemplateGroup();
          for (let j = 0; j < primaryCategoriesByUtilityType.length; j++) {
            //! create each group
            const { PrimaryDetailCategoryID, Title } =
              primaryCategoriesByUtilityType[j];
            utilityGroup = new FormTemplateGroup(
              Title,
              PrimaryDetailCategoryID.toString(),
              PrimaryDetailCategoryID
            );
            utilityGroup.fields = {};

            const primaryFieldsByCategoryID = tbAdmin_PrimaryDetailFields.filter(
              (row) =>
                row["PrimaryDetailCategoryID"] ==
                PrimaryDetailCategoryID.toString()
            );
            for (let k = 0; k < primaryFieldsByCategoryID.length; k++) {
              //! create each field
              let isVisible = false;
              const isHideShow = false;
              const {
                PrimaryDetailsFieldID,
                DisplayText,
                FieldTypeID,
                FieldOrder,
              } = primaryFieldsByCategoryID[k];

              if (fieldRules && fieldRules[PrimaryDetailsFieldID.toString()]) { /* empty */ } else {
                isVisible = true;
              }

              const utilityFormField: FormTemplateField = new FormTemplateField(
                PrimaryDetailsFieldID.toString(),
                DisplayText,
                FieldTypeID,
                FieldOrder,
                locateComplete
              );
              utilityFormField.isHideShow = isHideShow;
              utilityFormField.isVisible = isVisible;
              utilityFormField.tableName = tablename;
              utilityGroup.fields[PrimaryDetailsFieldID.toString()] =
                utilityFormField; //! add field to group
            }

            if (Object.keys(utilityGroup.fields).length > 0) {
              utilityView.groups[PrimaryDetailCategoryID.toString()] =
                utilityGroup; //! add group to view
            }
          }

          if (Object.keys(utilityView.groups).length > 0) {
            utilityViews.views[UtilityID.toString()] = utilityView; //! add view to views
          }
        }
        return utilityViews;
      } else {
        utilityViews.makeDefault();
        return utilityViews;
      }
    } catch (error) {
      console.log(error);
      utilityViews.makeDefault();
      return utilityViews;
    }
  }

  createFormGroupsFromView(view: FormTemplateView) {
    let generatedGroup: FormGroup;
    const formGroup = {};
    const filteredOptionsArr = [];
    const selectOptionsArr = [];

    try {
      let tabIndex = 0;
      const viewGroupKeys = Object.keys(view.groups);
      for (let i = 0; i < viewGroupKeys.length; i++) {
        const groupKey = viewGroupKeys[i];

        const fields = view.groups[groupKey].fields;
        const fieldKeys = Object.keys(fields);
        for (let j = 0; j < fieldKeys.length; j++) {
          const fieldKey = fieldKeys[j];

          view.groups[groupKey].fields[fieldKey]["tabIndex"] = tabIndex++;
          const controlKey: string = fields[fieldKey].key.toString();
          const controlKeyToLower: string = controlKey.toLowerCase();
          const inputTypeID: number = fields[fieldKey].inputTypeID;

          if (inputTypeID == TicketDetailInputType.Autocomplete) {
            const path = {
              controlKey: controlKey,
              groupkey: groupKey,
              fieldKey: fieldKey,
            };

            filteredOptionsArr.push(path);
          }

          if (
            inputTypeID == TicketDetailInputType.Multiselect ||
            inputTypeID == TicketDetailInputType.Singleselect
          ) {
            const path = {
              controlKey: controlKey,
              groupkey: groupKey,
              fieldKey: fieldKey,
            };
            selectOptionsArr.push(path);
          }

          if (inputTypeID == TicketDetailInputType.DateTimePicker) {
            formGroup[controlKey + "Time_Ignore_"] = ["", []];
          }

          // create list of validators
          const validators = [];

          // custom based on key
          if (controlKeyToLower.indexOf("phone") > -1) {
            validators.push(Validators.minLength(10));
          } else if (controlKeyToLower.indexOf("email") > -1) {
            validators.push(Validators.email);
          }

          // generic
          if (fields[fieldKey].isRequired) {
            validators.push(Validators.required);
            validators.push(FormTemplateValidators.noWhitespaceValidator);
          }

          // regex
          if (fields[fieldKey].matches) {
            validators.push(Validators.pattern('fields[fieldKey]["matches"]'));
          }

          formGroup[controlKey] = ["", validators];
        }
      }

      generatedGroup = this.formbuilderService.group(formGroup);
      view.formGroup = generatedGroup;
      return view;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  applyDataToFormTemplate(
    formGroup: FormGroup,
    data: any[],
    key: string,
    primaryID: string,
    isBilling?: boolean,
    primaryAuxDetailIDs?: any[]
  ) {
    let dataApplied: boolean = false;
    try {
      const valuesToPatch = {};
      const primaryIDFilteredRows = data.filter(
        (row) => row["PrimaryID"] == primaryID
      );
      for (let i = 0; i < primaryIDFilteredRows.length; i++) {
        const dataRow = primaryIDFilteredRows[i];

        if (isBilling) {
          if (!primaryAuxDetailIDs.includes(dataRow["AuxiliaryDetailID"])) {
            continue;
          }
        }

        if (dataRow["FieldValue"] != null) {
          if (isNumber(dataRow["FieldValue"])) {
            valuesToPatch[dataRow[key]] = parseFloat(dataRow["FieldValue"]);
          } else {
            valuesToPatch[dataRow[key]] = dataRow["FieldValue"];
          }
        } else {
          valuesToPatch[dataRow[key]] = dataRow["FieldValue"];
        }
      }

      formGroup.patchValue(valuesToPatch);
      dataApplied = true;
      return dataApplied;
    } catch (error) {
      console.log(error);
      return dataApplied;
    }
  }

  private async getTemplateFromServer(formInputTemplateTypeID: number) {
    let templates: any = false;
    const ticketTemplateRequest: UtilocateApiRequest = {
      API_KEY: apiKeys.u2.createTicketControllerFieldSide,
      API_TYPE: "PUT",
      API_BODY: {
        action: 3,
        FormInputTemplateTypeID: formInputTemplateTypeID,
      },
    };

    const result = await this.utilocateApiService.invokeUtilocateApi(
      ticketTemplateRequest
    );
    if (
      result.body &&
      result.body.value
    ) {
      templates = result.body.value
    }
    console.log(templates);

    return templates;
  }
}
