import { effect, inject, Injectable, OnDestroy, signal } from '@angular/core';
import { ApiService, UtilocateApiRequest } from '../../../core/api/baseapi.service';
import { apiKeys } from '../../../../ENDPOINTS';
import { BehaviorSubject, firstValueFrom, from, Observable, Subject } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { TileArcGISRest } from 'ol/source';
import Layer from 'ol/layer/Layer';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { GeoJSON } from 'ol/format';
import LayerGroup from 'ol/layer/Group';
import { LayerQueryFilters } from '../../utilities/types';
import Style from 'ol/style/Style';
import Styles from '../../utilities/styles';
import { Circle, LineString, Polygon, SimpleGeometry } from 'ol/geom';
import { Feature } from 'ol';
import { fromLonLat } from 'ol/proj';
import ImageLayer from 'ol/layer/Image';
import { FeatureStyleService } from '../feature-style.service';
import { UserService } from '../../../core/services/user/user.service';
import { SettingID } from '../../../core/services/user/setting';
import Static from 'ol/source/ImageStatic';
import Icon from 'ol/style/Icon';
import { getLength } from 'ol/sphere';
import { Mutex } from '../../../../shared/classes/Mutex';
import TileSource from 'ol/source/Tile';
import { LoggerService } from '../../../core/services/logger/logger.service';
import BaseLayer from 'ol/layer/Base';
import {
  DatabaseLayer,
  DatabaseLayerGroup,
  DigSiteShape,
  MapLayerControllerResponse,
  MapLayerControllerResponseData,
  ServiceType,
} from './types/layer.types';
import { LayerBuilderFactory } from './base-layer.factory';
import { BaseLayerBuilder } from './layer-builders/base-layer.builder';
import { GeoserverService } from '../geoserver.service';
import { Extent } from 'ol/extent';

@Injectable({
  providedIn: 'root',
})
export class LayerManagerService implements OnDestroy {
  // services
  private apiService = inject(ApiService);
  private userService = inject(UserService);
  private featureStyleService = inject(FeatureStyleService);
  private logger = inject(LoggerService);
  private layerBuilderFactory = inject(LayerBuilderFactory);
  private geoserverService = inject(GeoserverService);

  // observables
  private destroy$: Subject<void> = new Subject<void>();
  private refreshLayers$: Subject<void> = new Subject();
  private databaseLayers$: BehaviorSubject<DatabaseLayer[]> = new BehaviorSubject([]);
  private _layers$ = new BehaviorSubject<BaseLayer[]>([]);
  private _selectedLayer$ = new BehaviorSubject<VectorLayer<VectorSource> | null>(null);
  private _miscLayer$ = new BehaviorSubject<VectorLayer<VectorSource<Feature<SimpleGeometry>>>>(null);
  private _locateBoundingBox$ = new BehaviorSubject<Extent>(undefined);
  private _locateAreaLayer$ = new BehaviorSubject(null);
  private _digSites$$ = signal([]);
  private _filters$$ = signal(null);

  // members
  private _geoserverCredentials: { username: string; password: string };
  private jsonFormatter: GeoJSON = new GeoJSON();

  private _canvasLayers: [ImageLayer<Static>, VectorLayer<VectorSource>] = null;

  private OSMLayer: TileLayer<TileSource>;
  private satLayer: TileLayer<TileSource>;
  private digSiteLayer: VectorLayer<VectorSource>;
  private _topLevelLayers = {
    value: [],
    mu: new Mutex(),
  };
  private _allLayers: Layer[] = [];

  constructor() {
    this.setup();
    this.init();
  }

  private init() {
    this.OSMLayer = this.getOSMBaseLayer();
    this.satLayer = this.getSatelliteLayer();

    // setup misc layer for custom icons and other features
    this._miscLayer$.next(
      new VectorLayer({
        zIndex: Number(Infinity),
        source: new VectorSource(),
        style: (feature) => {
          return new Style({
            image: new Icon({
              crossOrigin: 'anonymous',
              src: 'assets/custom-icons/pin_border.svg',
              width: 32,
              height: 32,
              opacity: 1,
              color: feature.get('colour'),
              anchor: [0.5, 0.5],
              anchorXUnits: 'fraction',
              anchorYUnits: 'fraction',
              declutterMode: 'declutter',
            }),
          });
        },
      })
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  /**
   * Compiles all layers into a single array and updates the layers$ observable
   */
  compileLayers() {
    const temp = [];
    temp.push(this.OSMLayer);
    temp.push(this.satLayer);
    for (let i = 0; i < this._topLevelLayers.value.length; i++) {
      temp.push(this._topLevelLayers.value[i]);
    }
    // dig sites may not be present
    if (this.digSiteLayer) {
      temp.push(this.digSiteLayer);
    }
    this._layers$.next(temp);
  }

  /**
   * Set up the layer manager service
   */
  setup() {
    this.setupDigSiteEffect();
    this.setupLayerEffect();
    this.setupBBoxSubscription();
    this.setupRefreshSubscription();
  }

  /**
   * set up the dig site effect
   * this effect will update the dig site layer when the dig sites change
   */
  private setupDigSiteEffect() {
    effect(() => {
      const digSites = this._digSites$$();
      if (digSites.length > 0) {
        this.digSiteLayer = this.buildDigSiteLayer(digSites);
      } else {
        this.digSiteLayer = null;
      }
      this.compileLayers();
    });
  }

  /**
   * set up the layer effect
   * this effect will update the layers when the filters change
   */
  private setupLayerEffect() {
    effect(async () => {
      const filters = this._filters$$();
      await this._topLevelLayers.mu.acquire();
      await this.destroyLayers();
      this._topLevelLayers.value = [];
      try {
        const { layers, groups } = await firstValueFrom(this.gatherLayers());

        const databaseLayerArray: DatabaseLayer[] = await this.getEsriLayerTokens(
          layers.filter(this.filterLayers(filters))
        );

        this._topLevelLayers.value = await this.setupMapLayers(databaseLayerArray, groups, filters);

        this._selectedLayer$.next(
          this._topLevelLayers.value.find((x) => (x.getProperties() as DatabaseLayer).subLayerID === 0) ?? null
        );
        this.databaseLayers$.next(databaseLayerArray);

        const locateAreaLayer = databaseLayerArray.find((x) => x.subLayerID === 1);
        if (locateAreaLayer) {
          await this.updateLocateAreaBoundingBox(locateAreaLayer, filters);
        }
        this.compileLayers();
      } finally {
        this._topLevelLayers.mu.release();
      }
    });
  }

  /**
   * set up the bounding box subscription,
   * this effect will update the bounding box of all layers when the locate area layer changes
   */
  private setupBBoxSubscription() {
    if (this.userService.isSettingActive(SettingID.LOCATE_AREA_BBOX)) {
      this._locateBoundingBox$.pipe(takeUntil(this.destroy$)).subscribe((bbox) => {
        this._topLevelLayers.value.forEach((layer) => {
          if (layer.get('subLayerID') === 1) {
            return;
          }
          layer.setExtent(bbox ?? undefined);
        });
      });
    }
  }

  /**
   * set up the refresh subscription
   * this effect will refresh all layers when the refreshLayers$ subject emits
   */
  private setupRefreshSubscription() {
    this.refreshLayers$.pipe(takeUntil(this.destroy$)).subscribe(async () => {
      if (this._locateAreaLayer$.value) {
        await this.updateLocateAreaBoundingBox(
          this._locateAreaLayer$.value.getProperties() as DatabaseLayer,
          this._filters$$()
        );
        this._allLayers.forEach((layer) => {
          if (layer instanceof VectorLayer && layer.getSource() instanceof VectorSource) {
            (layer.getSource() as VectorSource).refresh();
          }
        });
      }
    });
  }

  /**
   * sets up the 'canvas' layers for drawing
   */
  setupCanvasLayers(img: HTMLImageElement): [ImageLayer<Static>, VectorLayer<VectorSource>] {
    if (this._canvasLayers) {
      this._canvasLayers.forEach((x: Layer) => x.dispose());
    }
    this._canvasLayers = [
      new ImageLayer({
        source: new Static({
          url: img.src,
          projection: 'image',
          imageExtent: [0, 0, img.naturalWidth, img.naturalHeight],
        }),
      }),
      new VectorLayer({
        source: new VectorSource(),
        style: this.featureStyleService.renderFeature.bind(this.featureStyleService),
      }),
    ];
    return this._canvasLayers;
  }

  /**
   * creates a record of layer groups
   */
  private createLayerGroups(groupData: DatabaseLayerGroup[]): Record<string, LayerGroup> {
    const layerGroups: Record<string, LayerGroup> = {};
    // hash the groups from the table and insert grouped layers
    groupData.forEach((x) => {
      layerGroups[x.groupID] = new LayerGroup({
        zIndex: x.index,
        properties: x,
      });
    });

    return layerGroups;
  }

  /**
   * organizes layers into groups
   */
  private organizeLayersIntoGroups(layers: Layer[], layerGroups: Record<string, LayerGroup>) {
    // add each layer as a sublayer to its respective group
    layers.forEach((layer: Layer) => {
      const groupID = layer.get('parentGroupID');
      if (groupID === -1) {
        return;
      }

      layerGroups[groupID]?.getLayers().push(layer);
    });

    // add each group as a sublayer to its respective group
    Object.values(layerGroups).forEach((x) => {
      if (x.get('parentGroupID') == -1 || x.getLayersArray().length == 0) {
        return;
      }
      layerGroups[x.get('parentGroupID')].getLayers().push(x);
    });
  }

  /**
   * gets the top level layers
   */
  private getTopLevelLayers(layers: Layer[], layerGroups: Record<string, LayerGroup>): BaseLayer[] {
    const topLevelLayers: BaseLayer[] = [];
    for (let i = 0, len = layers.length; i < len; i++) {
      const layer = layers[i];
      if (layer.get('parentGroupID') === -1) {
        topLevelLayers.push(layer);
      }
    }
    Object.values(layerGroups)
      .filter((x) => x.get('parentGroupID') == -1 && x.getLayersArray().length != 0)
      .forEach((x) => topLevelLayers.push(x));

    topLevelLayers.sort((a, b) => {
      return a.get('index') - b.get('index');
    });

    return topLevelLayers;
  }

  /**
   * filters layers based on the filters provided
   */
  private filterLayers(filters: Partial<LayerQueryFilters>) {
    return (layer: DatabaseLayer) => {
      if ([null, undefined, ''].includes(layer.layerURL)) {
        return false;
      }
      if (filters?.utilityID && layer.utilityID !== -1) {
        return !(filters.utilityID.length > 0 && !filters.utilityID.includes(layer.utilityID));
      }
      return true;
    };
  }

  /**
   * sets up the map layers
   */
  public async setupMapLayers(
    layerData: DatabaseLayer[],
    groupData: DatabaseLayerGroup[],
    filters: Partial<LayerQueryFilters>
  ) {
    this._allLayers = await this.buildLayers(layerData, filters);
    const layerGroups = this.createLayerGroups(groupData);
    this.organizeLayersIntoGroups(this._allLayers, layerGroups);
    return this.getTopLevelLayers(this._allLayers, layerGroups);
  }

  /**
   * gets the esri layer tokens and adds them to the layer data object
   */
  private async getEsriLayerTokens(layers: DatabaseLayer[]) {
    return Promise.all(
      layers.map(async (layer: DatabaseLayer) => {
        try {
          if (layer.tokenURL) {
            layer.token = (await this.getEsriToken(layer)).token;
          }
        } catch (e) {
          console.warn(e);
        }
        return layer;
      })
    );
  }

  /**
   * gathers the layer and group data from the map layer controller
   */
  private gatherLayers(): Observable<MapLayerControllerResponseData> {
    const request: UtilocateApiRequest = {
      API_TYPE: 'PUT',
      API_KEY: apiKeys.u2.getMapLayers,
      API_BODY: { query: { layers: true } },
    };
    return from(this.apiService.invokeUtilocateApi(request)).pipe(
      first(),
      map((response) => {
        if (!response.ok) {
          return { layers: [], groups: [] };
        }
        const { error, data } = response.body as MapLayerControllerResponse;
        if (error) {
          this.logger.error('Error fetching layers', error);
          return { layers: [], groups: [] };
        }

        return data;
      })
    );
  }

  /**
   * builds the layers from the database layers
   */
  private async buildLayers(databaseLayers: DatabaseLayer[], filters: Partial<LayerQueryFilters>) {
    const serviceMap = new Map<number, Map<number, Layer>>();
    let builder: BaseLayerBuilder;
    const layers: Layer[] = [];

    for (const layer of databaseLayers) {
      const isSubLayer = [ServiceType.EsriMapServerSubLayer, ServiceType.WMSLayerGeoServerSubLayer].includes(
        layer.serviceType
      );

      if (!isSubLayer) {
        builder = this.layerBuilderFactory.getBuilder(layer.serviceType);
        const builtLayer = await builder.build(layer, filters);
        if (builtLayer === null) {
          continue;
        }
        if (builtLayer.get('subLayerID') === 1) {
          this.setupLocateAreaLayer(builtLayer);
        }
        layers.push(builtLayer);
        continue;
      }

      this.initLayerConfig(layer);

      if (!serviceMap.has(layer.layerServiceID)) {
        serviceMap.set(layer.layerServiceID, new Map());
      }

      const service = serviceMap.get(layer.layerServiceID)!;
      if (!service.has(layer.parentGroupID)) {
        builder = this.layerBuilderFactory.getBuilder(layer.serviceType);
        const builtLayer = await builder.build(layer, filters);
        if (builtLayer === null) {
          continue;
        }
        service.set(layer.parentGroupID, builtLayer);
      }

      const parent = service.get(layer.parentGroupID)!;
      (parent.get('subLayers') as DatabaseLayer[]).push(layer);
    }

    for (const service of serviceMap.values()) {
      layers.push(...service.values());
    }

    return layers;
  }

  /**
   * initializes the layer config and attaches it to the layer data object
   */
  private initLayerConfig(layer: DatabaseLayer) {
    layer.config = {
      showLabels: false,
      isVisible: layer.isDefaultOn === 1,
    };
  }

  private setupLocateAreaLayer(layer: Layer) {
    this._locateAreaLayer$.next(layer as VectorLayer<VectorSource>);
  }

  private async updateLocateAreaBoundingBox(layer: DatabaseLayer, filters: Partial<LayerQueryFilters>) {
    try {
      const { username, password, layerURL } = layer;
      let filterString = '';
      if (filters?.requestNumber) {
        filterString = `request_number=${filters.requestNumber}`;
      } else {
        return;
      }
      const url = new URL(layerURL);
      const params = {
        service: 'WFS',
        version: '1.0.0',
        request: 'GetFeature',
        typeName: layer.subLayerName,
        outputFormat: 'application/json',
        srsName: 'EPSG:3857',
        maxFeatures: '1',
        exceptions: 'application/json',
      };
      Object.entries(params).forEach(([key, value]) => {
        url.searchParams.set(key, value);
      });
      url.searchParams.set('CQL_FILTER', filterString);
      const response = await this.geoserverService.makeRequest(url.toString(), {
        mode: 'cors',
        headers: {
          Authorization: 'Basic ' + btoa(`${username}:${password}`),
        },
      });
      if (response.status !== 200) {
        return null;
      } else if (!response.headers.get('content-type').includes('application/json')) {
        return null;
      }

      const { bbox } = await response.json();
      this._locateBoundingBox$.next(bbox ?? undefined);
      return bbox;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  private getOSMBaseLayer(): TileLayer<TileSource> {
    const layer = new TileLayer({
      zIndex: -1000,
    });
    const source = new OSM();
    layer.setSource(source);
    layer.setProperties({
      layerName: 'Open Street Map',
      isDefaultOn: 1,
      index: 1000,
      parentGroupID: -1,
    });
    return layer;
  }

  private buildDigSiteLayer(digSites: DigSiteShape[]): VectorLayer<VectorSource> {
    const layer = new VectorLayer();
    const source = new VectorSource({
      loader: () => {
        const feats = digSites.map((site) => {
          const coords = site['Coordinates'].map(({ lat, lng }) => fromLonLat([lng, lat]));
          let poly: Polygon | Circle;
          const feat = new Feature();
          feat.setStyle(Styles.digSiteShape());
          try {
            if (site.type === 1 && coords.length == 2) {
              poly = new Circle(coords[0], getLength(new LineString(coords)));
            } else {
              poly = new Polygon([coords]);
            }
            feat.setProperties({
              Feature: 'Dig Area',
              'graphic ID': site.graphicID,
              type: site.type,
              index: 2,
            });
            if (site.type == 1) {
              feat.set(
                'area',
                Math.round(Math.pow(Math.PI * getLength(new LineString(coords)), 2) * 100) / 100 + ' m^2'
              );
            } else {
              feat.set('area', Math.round((poly as Polygon).getArea() * 100) / 100 + ' m^2');
            }
          } catch {
            console.log('Build digsite failed: ', site.coordinates);
          }
          feat.setGeometry(poly);
          return feat;
        });
        source.addFeatures(feats);
      },
    });
    layer.setSource(source);
    layer.setStyle(Styles.digSiteShape());
    layer.setProperties({
      layerName: 'Dig Sites',
      isDefaultOn: 1,
      parentGroupID: -1,
    });
    return layer;
  }

  getSatelliteLayer(): TileLayer<TileSource> {
    const layer = new TileLayer();
    const source = new TileArcGISRest({
      url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer',
      attributions: [
        'Powered by Esri',
        'Source: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community',
      ],
      crossOrigin: 'anonymous',
    });
    layer.setSource(source);
    layer.setProperties({
      layerName: 'Satellite View',
      isDefaultOn: this.userService.isSettingActive(SettingID.SATELLITE_LAYER_ON) ? 1 : -1,
      parentGroupID: -1,
      index: 1,
    });
    return layer;
  }

  private async getEsriToken(layer: DatabaseLayer): Promise<EsriTokenResponse> {
    if (layer.tokenURL === undefined) {
      throw new Error('Esri Layer Credential is undefined');
    }
    const { username, password, tokenURL } = layer;
    const formData = new URLSearchParams();

    if ([username, password, tokenURL].includes(undefined)) {
      throw new Error('Esri Token Generation URL, Username, or Password is undefined');
    }

    const formFields = {
      username,
      password,
      referer: 'www.u4ia.cloud',
      f: 'json',
    };

    for (const [key, value] of Object.entries(formFields)) {
      formData.append(key, value);
    }

    const response = await fetch(tokenURL, {
      method: 'POST',
      body: formData,
    });

    if (!response.ok || response.status !== 200) {
      throw new Error('Failed to get Esri Token');
    }

    let result: EsriTokenResponse;

    try {
      result = await response.json();
    } catch (e) {
      throw new Error('Failed to get Esri Token');
    }

    if (result['error']) {
      throw new Error('Failed to get Esri Token');
    }
    return result;
  }

  public async destroyLayers() {
    this._topLevelLayers.value.forEach((layer) => {
      if (layer instanceof VectorLayer && layer.getSource() instanceof VectorSource) {
        layer.getSource().clear();
      }
      layer.dispose();
    });
  }

  // getters & setters
  get layers$(): Observable<BaseLayer[]> {
    return this._layers$.pipe();
  }

  get databaseLayerStream(): Observable<DatabaseLayer[]> {
    return this.databaseLayers$.pipe();
  }

  get selectedLayer$(): Observable<VectorLayer<VectorSource>> {
    return this._selectedLayer$.pipe();
  }

  get selectedLayer(): Layer {
    return this._selectedLayer$.value;
  }

  set selectedLayer(layer: VectorLayer<VectorSource>) {
    this._selectedLayer$.next(layer);
  }

  get miscLayer() {
    return this._miscLayer$.value;
  }

  get miscLayer$(): Observable<VectorLayer<VectorSource>> {
    return this._miscLayer$.pipe();
  }

  set digSites(digSites: Array<unknown>) {
    this._digSites$$.set(digSites);
  }

  set filters(filters: Partial<LayerQueryFilters>) {
    this._filters$$.set(filters);
  }

  get canvasDrawingLayer() {
    if (this._canvasLayers) {
      return this._canvasLayers[1];
    } else {
      return null;
    }
  }

  get canvasImageLayer() {
    if (this._canvasLayers) {
      return this._canvasLayers[0];
    } else {
      return null;
    }
  }

  public refreshLayers() {
    this.refreshLayers$.next();
  }

  public clearLayerSelection() {
    this._selectedLayer$.next(null);
  }
}

export type EsriTokenResponse = {
  token: string;
  expires: number;
};
