import { NestedTreeControl } from "@angular/cdk/tree";
import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatTreeNestedDataSource } from "@angular/material/tree";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { LoggerService } from "../../core/services/logger/logger.service";
import { ModalService } from "../../shared/modals/modal.service";
import { ProgressBarService } from "../../shared/progress-bar/progress-bar.service";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import {
  Snackbar,
  SnackbarType,
} from "../../shared/snackbar/snackbar/snackbar";
import { RateNode, SelectedNode } from "../rate-node";
import { RatesService } from "../rates.service";

@Component({
  selector: "app-rate",
  templateUrl: "./rate.component.html",
  styleUrls: ["./rate.component.scss"],
})
export class RateComponent implements OnInit {
  utilities = [
    {
      text: "One",
      value: 1,
    },
    {
      text: "Two",
      value: 2,
    },
  ];

  myControl = new FormControl("");
  filteredOptions: Observable<any[]>;

  selectedUtilityID = null;

  rateTypeID: number;
  treeNodes$: Observable<RateNode[]>;

  criterias: any[];

  selectedNode: SelectedNode;
  copiedNode: SelectedNode;

  nestedDataSource = new MatTreeNestedDataSource<RateNode>();
  nestedTreeControl = new NestedTreeControl<RateNode>((node) => node.Children);

  expandedNodes: RateNode[] = [];

  PASTE_MODAL_ID = "paste-modal-id";
  alreadyPassedFirst: any;

  constructor(
    private loggerService: LoggerService,
    private progressBarService: ProgressBarService,
    private snackbarService: SnackbarService,
    private route: ActivatedRoute,
    private ratesService: RatesService,
    private router: Router,
    private modalService: ModalService
  ) {}

  /**
   * setup subscriptions for route and treeNodes
   */
  async ngOnInit() {
    this.alreadyPassedFirst = false;
    this.route.params.subscribe((params) => {
      let paramsRouteID: number = parseInt(params["rateTypeID"], 10); //parsing string into integer
      paramsRouteID = this.shouldRerouteToRatePage(paramsRouteID)
        ? null
        : paramsRouteID; // changes paramsRouteID to null if wrong route is mentioned
      this.rateTypeID = paramsRouteID;
      this.utilityCleared();
      this.cancelEdit();
    });
    await this.setupUtilities();
  }

  /**
   * checks param(rateTypeID) and decides if page should be rerouted to /app/rates
   * @param rateTypeID
   * @returns boolean value if rate page should be rerouted
   */
  shouldRerouteToRatePage(rateTypeID: number): boolean {
    let shouldReroute: boolean;
    let availableRateRoutes: number[] = this.ratesService.getRateTypeIDs(); //available Routes for rate feature,i.e. [1, 2]
    try {
      if (!availableRateRoutes.includes(rateTypeID)) {
        this.rerouteToRatePage();
        shouldReroute = true;
      } else {
        shouldReroute = false;
      }
    } catch (error) {
      console.error(`Error occured rerouting to rates page ${error}`);
    }
    return shouldReroute;
  }

  /**
   * reroutes URL to /app/rates page
   * and pops a snackbar error message
   */
  rerouteToRatePage(): void {
    this.snackbarService.openSnackbar(
      `Invalid Page Request: Route Does Not Exist`,
      SnackbarType.error
    );
    this.router.navigate(["/app/rates"]);
  }

  async setupUtilities() {
    try {
      this.myControl.disable();
      this.utilities = await this.ratesService.getUtilities();
      this.myControl.enable();
      this.setupFilteredOptions();
    } catch (error) {
      this.loggerService.error(error);
    }
  }

  setupFilteredOptions() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value || ""))
    );
  }

  getOptionText(option) {
    if (option && option.text) {
      return option.text;
    } else {
      return option;
    }
  }

  private _filter(value) {
    try {
      let filterValue = "";
      if (value && value.text) {
        filterValue = value.text.toLowerCase();
      } else {
        filterValue = value.toLowerCase();
      }
      return this.utilities.filter((option) =>
        option.text.toLowerCase().includes(filterValue)
      );
    } catch (error) {
      this.loggerService.error(error);
      return this.utilities;
    }
  }

  utilitySelected(event: MatAutocompleteSelectedEvent) {
    try {
      let option = event.option.value;
      if (option.value) {
        let utilityID = option.value;
        this.selectedUtilityID = utilityID;
        this.loadRatesForSelectedUtility(utilityID);
      }
    } catch (error) {
      this.loggerService.error(error);
    }
  }

  async loadRatesForSelectedUtility(utilityID) {
    try {
      let utilityIDs = [utilityID];

      this.progressBarService.start();
      await this.setupRateWithUtilities(utilityIDs);
      this.progressBarService.stop();
      this.treeNodes$ = this.ratesService.treeNodes$;
      this.treeNodes$.subscribe((nodes) => {
        this.nestedDataSource.data = nodes;
        this.nestedTreeControl.collapseAll();
        this.expandUtility(nodes);
        if (this.selectedNode) {
          this.reselectSelectedNode();
        }
        this.progressBarService.stop();
      });
    } catch (error) {
      this.loggerService.error(error);
      this.progressBarService.stop();
    }
  }

  utilityCleared() {
    try {
      this.selectedUtilityID = null;
      this.selectedNode = null;
      this.ratesService.clearTree();
      this.myControl.reset("");
      //
    } catch (error) {
      this.loggerService.error(error);
    }
  }

  /**
   * call the RatesService function to load a specific rate
   */
  async setupRate() {
    this.criterias = await this.ratesService.getRatesNodesAndCriterias(
      this.rateTypeID
    );
  }

  async setupRateWithUtilities(utilityIDs = []) {
    this.criterias = await this.ratesService.getRatesNodesAndCriterias(
      this.rateTypeID,
      utilityIDs
    );
  }

  /**
   * check if a node has nested children
   * @param index index of node
   * @param node node to check for children
   * @returns boolean whether or not node has children
   */
  hasNestedChild(index: number, node: RateNode) {
    return node?.Children?.length > 0;
  }

  /**
   * save expanded nodes, update selectedNode to load in editor
   * @param obj the node selected from the tree
   */
  nodeSelected(obj: SelectedNode) {
    this.saveExpandedNodes();
    this.selectedNode = obj;
    this.selectedNode.siblingValues = this.ratesService.getNodeSiblingValues(
      this.selectedNode
    );
  }

  /**
   * find the previously selected node within treeNodes array, and reselect it. If it doesn't exist, set to null
   * used to maintain selected node when the tree refreshes
   */
  reselectSelectedNode() {
    let foundNode = this.ratesService.findNestedNode(this.selectedNode.NodeID);
    if (foundNode) {
      this.nodeSelected({
        NodeID: foundNode.NodeID,
        ParentNodeID: foundNode.ParentNodeID,
        CriteriaTypeID: foundNode.CriteriaTypeID,
        ChildCriteriaTypeID: foundNode.ChildCriteriaTypeID,
        Rate: foundNode.Rate,
        RateName: foundNode.RateName,
        Values: foundNode.Values,
        Children: foundNode.Children,
        nodeTrace: foundNode.nodeTrace,
      });
    } else {
      this.selectedNode = null;
    }
  }

  /**
   * save expanded nodes, delete selectedNode via rates service
   * @param obj node to be deleted
   */
  async nodeDeleted(obj: SelectedNode) {
    this.progressBarService.start();
    this.saveExpandedNodes();
    let deletedNodes = await this.ratesService.deleteNode(
      obj,
      this.rateTypeID,
      [this.selectedUtilityID]
    );
    let parentNode = this.ratesService.findNestedNode(obj.ParentNodeID);
    this.selectedNode = parentNode;
    this.selectedNode.siblingValues = this.ratesService.getNodeSiblingValues(
      this.selectedNode
    );
    this.snackbarService.openSnackbar("Node Deleted", SnackbarType.success);
  }

  /**
   * save a node to this.copiedNode and inform the user it was successful
   * @param obj the node that was copied
   */
  nodeCopied(obj: SelectedNode) {
    try {
      this.copiedNode = obj;
      let utilityName = obj.readableValues[0];
      this.snackbarService.openSnackbar(
        `Node Copied: ${utilityName}`,
        SnackbarType.success
      );
    } catch (error) {
      this.snackbarService.openSnackbar(`Node Copy Failed`, SnackbarType.error);
      console.error("nodeCopied: ", error);
    }
  }

  /**
   * if a node has been copied, and the parentNode has no children,
   * open the paste confirmation modal
   * @param parentNode the node that will receive the previously copied node's children
   */
  async nodePasted(parentNode: SelectedNode) {
    try {
      if (this.copiedNode) {
        if (parentNode.Children.length == 0) {
          this.selectedNode = parentNode;
          this.selectedNode.siblingValues =
            this.ratesService.getNodeSiblingValues(this.selectedNode);
          this.modalService.open(this.PASTE_MODAL_ID);
        } else {
          this.snackbarService.openSnackbar(
            "Can't Paste to Populated Utility",
            SnackbarType.warning,
            "close"
          );
        }
      } else {
        this.snackbarService.openSnackbar(
          "Nothing to Paste",
          SnackbarType.default,
          "close"
        );
      }
    } catch (error) {
      console.error("nodePasted: ", error);
    }
  }

  /**
   * close the modal, call rates service to save copies of the copied node's children
   */
  async onPasteConfirmClick() {
    try {
      this.progressBarService.start();
      this.modalService.close(this.PASTE_MODAL_ID);
      let node = await this.ratesService.pasteCopiedNode(
        this.selectedNode,
        this.copiedNode,
        this.rateTypeID,
        [this.selectedUtilityID]
      );
      this.nestedTreeControl.expandDescendants(
        this.ratesService.findNestedNode(this.selectedNode.NodeID)
      );
      this.snackbarService.openSnackbar(
        `Node Copied Successfully`,
        SnackbarType.success
      );
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * close the modal and don't paste anything
   */
  onPasteCancelClick() {
    this.modalService.close(this.PASTE_MODAL_ID);
  }

  /**
   * save expanded nodes, add new node via rates service, then select that node
   * @param parentNode parentNode of newly added node
   */
  async nodeAdded(parentNode: SelectedNode) {
    this.progressBarService.start();
    try {
      this.nestedTreeControl.expand(
        this.ratesService.findNestedNode(parentNode.NodeID)
      );
      this.saveExpandedNodes();
      let addedNodes = await this.ratesService.insertNewNode(
        parentNode,
        this.rateTypeID,
        [this.selectedUtilityID]
      );
      if (addedNodes && addedNodes[0]) {
        let addedNode = addedNodes[0];
        let foundNode = this.ratesService.findNestedNode(addedNode.NodeID);
        this.selectedNode = {
          NodeID: foundNode.NodeID,
          ParentNodeID: foundNode.ParentNodeID,
          CriteriaTypeID: parentNode.ChildCriteriaTypeID,
          ChildCriteriaTypeID: foundNode.ChildCriteriaTypeID,
          Rate: foundNode.Rate,
          RateName: foundNode.RateName,
          Values: foundNode.Values,
          Children: foundNode.Children,
          nodeTrace: foundNode.nodeTrace,
        };
        this.selectedNode.siblingValues =
          this.ratesService.getNodeSiblingValues(this.selectedNode);
      }
      this.snackbarService.openSnackbar("Node Created", SnackbarType.success);
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Given a node, set the icon for it. Defaulting to the nodes given icon. 
   * This is used to set the icon for dynamic nodes, such as 'utility' or 'urban or rural' 
   *
   * @param {RateNode} node
   * @return {string} the icon 
   * @memberof RateComponent
   */
  setIconForNode(node: RateNode) {
    // console.log(node);
    let icon = node.icon ?? 'location';
    if (node.CriteriaTypeID === 1) {
      icon = this.ratesService.getIconForUtility(node);
    }
    if (node.criteriaName?.toLowerCase().includes("urban or rural")) {
      switch (node.readableValues[0]?.toLowerCase()) {
        case "urban":
          node.icon = 'urban';
          break;
        case "rural":
          node.icon = 'rural';
          break;
      }
    }
    return icon;
  }

  /**
   * save expanded nodes and save updated node via rates service
   * @param nodeToSave node that has been edited
   */
  async nodeSaved(nodeToSave) {
    this.progressBarService.start();
    this.saveExpandedNodes();
    try {
      await this.ratesService.saveNode(nodeToSave, this.rateTypeID, [
        this.selectedUtilityID,
      ]);
      this.snackbarService.openSnackbar("Node Saved", SnackbarType.success);
    } catch (error) {
      throw new Error(error);
    }
  }

  /**
   * remove selected node to discard changes and hide editor
   */
  cancelEdit() {
    this.selectedNode = null;
  }

  /**
   * save expanded nodes in an array to re expand after refresh
   */
  saveExpandedNodes() {
    this.expandedNodes = [];
    this.expandedNodes = this.nestedTreeControl.expansionModel.selected;
  }

  /**
   * search through nodes and their children and expand ones that exist in the expanded array
   * @param nodes array of nodes to search through
   */
  restoreExpandedNodes(nodes: RateNode[]) {
    try {
      for (let expandedNode of this.expandedNodes) {
        let foundNode = this.ratesService.findNestedNode(
          expandedNode.NodeID,
          nodes
        );
        if (foundNode) {
          this.nestedTreeControl.expand(foundNode);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Expands all nodes for the selected utility (i.e., start node)
   */
  expandUtility(nodes: RateNode[]) {
    this.expandAllChildNodes(nodes);
  }

  /**
   * Expands self and all children of a given node 
   */
  expandAllChildNodes(nodes: RateNode[]) {
    try {
      if (Array.isArray(nodes)) {
        for (let node of nodes) {
          this.nestedTreeControl.expand(node);
          if (node.Children && node.Children.length > 0) {
            this.expandAllChildNodes(node.Children);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
}
