import Map from 'ol/Map';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Collection, Feature, MapBrowserEvent } from 'ol';
import { Point, Polygon } from 'ol/geom';
import Style from 'ol/style/Style';
import { BehaviorSubject, from, fromEvent, merge, Observable, Subject } from 'rxjs';
import { first, map, switchMap, takeUntil } from 'rxjs/operators';
import { boundingExtent, createEmpty, extend, Extent } from 'ol/extent';
import { fromExtent } from 'ol/geom/Polygon';
import Styles from '../styles';
import { Icon } from 'ol/style';
import PointerInteraction from 'ol/interaction/Pointer';
import { Coordinate } from 'ol/coordinate';
import { CollectionFlattener, FeatureOrCollection } from '../../classes/collection-flattener';
import { FeatureChangeType, MapFeatureChange } from '../../classes/map-feature-change';
import { OpenLayersUtilities } from '../../classes/open-layers-utilities';

export type Modes = 'rotate' | 'scale';

export class Manipulate extends PointerInteraction {
  private readonly mapRef: Map;
  private resolution: number;
  private selectedLayer: VectorLayer<VectorSource>;
  private collection: Collection<Feature>;
  private manipulationMode: Modes = 'rotate';
  private modeChanged$: Subject<Modes> = new Subject<Modes>();
  private modeChangeEmitter$: Subject<Modes> = new Subject<Modes>();
  private destroy$ = new Subject<void>();
  private endInteraction$: Subject<void>;
  private featureChangeSubject$: Subject<MapFeatureChange<FeatureOrCollection>>;
  private collectionSubject$: Subject<Collection<Feature>>;
  private newSelection$: Subject<void>;
  private olUtilities: OpenLayersUtilities;

  // highlight vars
  private handle: Feature<Point>;
  private readonly handleStyle: Style;
  private highlight: Feature<Polygon>;
  private readonly highlightLayer: VectorLayer<VectorSource>;
  private readonly highlightSource: VectorSource;

  private clicked: 'highlight' | 'handle' | 'off' | null = null;
  private lastCoordinate: Coordinate;

  private originalFeatures: Feature[] = [];
  private featuresChanged: boolean = false;
  private collectionFlattener: CollectionFlattener;

  cursor = 'pointer';
  previousCursor = undefined;

  interactionStart: () => void;
  interactionEnd: (p: { originalFeatures: any; features: any }) => void;

  constructor(
    mapRef: Map,
    selectedLayer: VectorLayer<VectorSource>,
    collection: Collection<Feature>,
    collectionSubject: BehaviorSubject<Collection<Feature>>,
    newSelection: Subject<void>,
    endInteraction: Subject<void>,
    featureChangeSubject: Subject<MapFeatureChange<FeatureOrCollection>>
  ) {
    super({
      handleDownEvent,
      handleDragEvent,
      handleMoveEvent,
      handleUpEvent,
    });

    this.collectionSubject$ = collectionSubject;
    this.newSelection$ = newSelection;
    this.featureChangeSubject$ = featureChangeSubject;
    this.mapRef = mapRef;
    this.selectedLayer = selectedLayer;
    this.collection = collection;
    this.highlightSource = new VectorSource();
    this.highlightLayer = new VectorLayer<VectorSource>({
      source: this.highlightSource,
      properties: {
        isHighlightLayer: true,
      },
    });

    this.handleStyle = Styles.default() as Style;
    this.olUtilities = new OpenLayersUtilities();
    this.collectionFlattener = new CollectionFlattener();
    this.resolution = this.mapRef.getView().getResolution() || 1;

    this.manipulationMode = 'rotate';
    this.endInteraction$ = endInteraction;
    this.init();
    this.highlightFeatureCollection();
  }

  private updateHighlightLayerIndex(map: Map, layer: VectorLayer<VectorSource>) {
    layer.setZIndex(this.olUtilities.getMaxLayerZIndex(map) + 1);
  }

  setOnInteractionStart(interactionStart: () => void) {
    this.interactionStart = interactionStart;
  }

  setOnInteractionEnd(interactionEnd: () => void) {
    this.interactionEnd = interactionEnd;
  }

  get modeChange(): Observable<Modes> {
    return this.modeChangeEmitter$.pipe(takeUntil(this.destroy$));
  }

  set mode(mode: Modes) {
    this.manipulationMode = mode;
    this.modeChanged$.next(mode);
  }

  private highlightFeatureCollection() {
    if (this.collection.getLength() == 0) {
      return;
    }
    try {
      this.highlightSource.clear(true);
      const extent: Extent = createEmpty();
      this.collection.forEach((feature) => {
        extend(extent, feature.getGeometry().getExtent());
      });

      const bufferPixels = 15;
      const [bottomLeft, topLeft, bottomRight, topRight] = [
        [extent[0], extent[1]],
        [extent[0], extent[3]],
        [extent[2], extent[1]],
        [extent[2], extent[3]],
      ].map((coord) => this.mapRef.getPixelFromCoordinate(coord));
      const bufferedPixels = [
        [bottomLeft[0] - bufferPixels, bottomLeft[1] + bufferPixels],
        [topLeft[0] - bufferPixels, topLeft[1] - bufferPixels],
        [bottomRight[0] + bufferPixels, bottomRight[1] + bufferPixels],
        [topRight[0] + bufferPixels, topRight[1] - bufferPixels],
      ];

      const topRightBuffered = bufferedPixels[3];
      const bufferExtent = boundingExtent(bufferedPixels.map((coord) => this.mapRef.getCoordinateFromPixel(coord)));
      const polygon = fromExtent(bufferExtent);

      this.handle = new Feature(
        new Point(this.mapRef.getCoordinateFromPixel([topRightBuffered[0] + 20, topRightBuffered[1] - 20]))
      );
      this.handle.setStyle(this.handleStyle);
      this.highlight = new Feature({
        geometry: polygon,
      });
      this.highlight.setStyle(Styles.selectedStyle());
      this.highlightSource.addFeatures([this.highlight, this.handle]);
    } catch (e) {
      console.log(e);
    }
  }

  private init() {
    this.mapRef.getView().on('change:resolution', () => {
      this.highlightFeatureCollection();
    });

    this.collectionSubject$.subscribe((next) => {
      this.collection = next;
    });

    this.mapRef.addLayer(this.highlightLayer);

    this.modeChanged$.pipe(takeUntil(merge(this.destroy$, this.endInteraction$))).subscribe((mode) => {
      this.manipulationMode = mode;
      if (mode === 'rotate') {
        this.handleStyle.setImage(
          new Icon({
            src: '/assets/icons/drawing toolbar icons/ROTATE.png',
            scale: 1.5,
            rotation: Math.PI / 2,
            anchor: [0.5, 0.5],
          })
        );
      } else if (mode === 'scale') {
        this.handleStyle.setImage(
          new Icon({
            src: '/assets/icons/drawing toolbar icons/SCALE-01.png',
            scale: 1.5,
            anchor: [0.5, 0.5],
            rotation: Math.PI / 4,
          })
        );
      }
      this.highlightFeatureCollection();
    });

    fromEvent(this.mapRef, 'singleclick')
      .pipe(
        map((e: MapBrowserEvent<UIEvent>) => e.pixel),
        switchMap((pixel) => from(this.highlightLayer.getFeatures(pixel))),
        takeUntil(merge(this.destroy$, this.endInteraction$))
      )
      .subscribe((features) => {
        const res = features.reduce((previousValue, currentValue) => {
          return !!(previousValue || currentValue === this.highlight);
        }, false);
        if (!res && (this.clicked === null || this.clicked === 'off')) {
          this.endInteraction$.next();
        }
      });

    this.endInteraction$.pipe(first()).subscribe(() => {
      if (this.highlightLayer) {
        this.highlightLayer.getSource()?.clear(true);
        this.mapRef.removeLayer(this.highlightLayer);
        this.highlightLayer.dispose();
      }
      this.destroy$.next();
    });

    this.updateHighlightLayerIndex(this.mapRef, this.highlightLayer);
  }

  private toggleMode() {
    if (this.manipulationMode === 'rotate') {
      this.manipulationMode = 'scale';
    } else {
      this.manipulationMode = 'rotate';
    }
    this.modeChanged$.next(this.manipulationMode);
    this.modeChangeEmitter$.next(this.manipulationMode);
  }

  private handleDraggedFeature(evt: MapBrowserEvent<UIEvent>) {
    this.featuresChanged = true;
    const deltaX = evt.coordinate[0] - this.lastCoordinate[0];
    const deltaY = evt.coordinate[1] - this.lastCoordinate[1];
    const features = this.collection.getArray();
    for (let i = 0; i < features.length; i++) {
      const feature = features[i];
      const geometry = feature.getGeometry();
      geometry.translate(deltaX, deltaY);
    }
  }

  private handleRotate(evt: MapBrowserEvent<UIEvent>) {
    this.featuresChanged = true;
    const features = this.collection.getArray();
    for (let i = 0; i < features.length; i++) {
      const feature = features[i];
      const center = this.calculateCenter();
      const props = feature.getProperties();

      let dx: number;
      let dy: number;
      dx = this.lastCoordinate[0] - center[0];
      dy = this.lastCoordinate[1] - center[1];
      const initialAngle = Math.atan2(dy, dx);
      dx = evt.coordinate[0] - center[0];
      dy = evt.coordinate[1] - center[1];
      const currentRadius = Math.sqrt(dx * dx + dy * dy);
      if (currentRadius > 0) {
        const currentAngle = Math.atan2(dy, dx);
        const geometry = feature.getGeometry();
        const featStyle = feature.getStyle();
        if (featStyle instanceof Style) {
          // if (props["shape_type_id"] === 6) {
          //   const featText = featStyle.getText();
          //   featText.setRotation(
          //     featText.getRotation() - (currentAngle - initialAngle)
          //   );
          // }
          if (geometry instanceof Point) {
            if (props['shape_type_id']) {
              if (props['shape_type_id'] === 7) {
                const featImage = featStyle.getImage();
                featImage.setRotation(featImage.getRotation() - (currentAngle - initialAngle));
              }
            }
          }
        }
        geometry.rotate(currentAngle - initialAngle, center);
      }
    }
  }

  private handleScale(evt: MapBrowserEvent<UIEvent>): void {
    this.featuresChanged = true;
    const features = this.collection.getArray();
    for (let i = 0; i < features.length; i++) {
      const feature = features[i];
      const center = this.calculateCenter();
      const props = feature.getProperties();

      let dx: number;
      let dy: number;
      dx = this.lastCoordinate[0] - center[0];
      dy = this.lastCoordinate[1] - center[1];
      const initialRadius = Math.sqrt(dx * dx + dy * dy);
      dx = evt.coordinate[0] - center[0];
      dy = evt.coordinate[1] - center[1];
      const currentRadius = Math.sqrt(dx * dx + dy * dy);
      if (currentRadius > 0) {
        const geometry = feature.getGeometry();
        const featStyle = feature.getStyle();
        if (featStyle instanceof Style) {
          if (props['shape_type_id']) {
            if (props['shape_type_id'] === 7) {
              const featImage = featStyle.getImage();
              const currentScale = (featImage.getScale() as number) || 1;
              featImage.setScale(currentScale * (currentRadius / initialRadius));
            }
            // else if (props["shape_type_id"] === 6) {
            // const featText = featStyle.getText();
            // const currentScale = (featText.getScale() as number) || 1;
            // featText.setScale(
            //   currentScale * (currentRadius / initialRadius)
            // );
          }
        }
        geometry.scale(currentRadius / initialRadius, undefined, center);
      }
    }
  }

  private calculateCenter() {
    let x = 0;
    let y = 0;
    let i = 0;
    const coordinates = this.highlight.getGeometry().getCoordinates()[0].slice(1);
    coordinates.forEach((coordinate) => {
      x += coordinate[0];
      y += coordinate[1];
      i++;
    });
    const center = [x / i, y / i];
    return center;
  }

  private broadcastChange() {
    this.featureChangeSubject$.next(
      new MapFeatureChange(
        FeatureChangeType.updated,
        this.collection,
        new Collection(this.originalFeatures),
        this.selectedLayer.getSource() as VectorSource
      )
    );
  }

  private removeFeatures(features: Feature[]) {
    features.forEach((feature) => {
      this.selectedLayer.getSource().removeFeature(feature);
    });
  }

  private restoreFeatures(features: Feature[]) {
    features.forEach((feature) => {
      this.selectedLayer.getSource().addFeature(feature);
    });
  }
}

function handleDownEvent(evt: MapBrowserEvent<UIEvent>): boolean {
  this.clicked = null;
  const map = evt.map;
  this.initialCoordinate = evt.coordinate;
  this.lastCoordinate = evt.coordinate;
  let canInteract = false;
  let set = false;
  map.forEachFeatureAtPixel(evt.pixel, (feature) => {
    if (!set) {
      if (feature === this.handle) {
        set = true;
        canInteract = true;
        this.clicked = 'handle';
      } else if (feature === this.highlight) {
        set = true;
        canInteract = true;
        this.clicked = 'highlight';
      } else {
        this.clicked = 'off';
      }
    }
  });
  if (canInteract) {
    this.originalFeatures = [...this.collection.getArray()];
    this.removeFeatures(this.originalFeatures);
    this.collection = this.collectionFlattener.deepClone(this.collection);
    this.restoreFeatures(this.collection.getArray());
  }
  return canInteract;
}

function handleDragEvent(evt: MapBrowserEvent<UIEvent>): void {
  this.wasDragged = true;

  switch (this.clicked) {
    case 'highlight':
      this.handleDraggedFeature(evt);
      break;
    case 'handle':
      if (this.manipulationMode === 'rotate') {
        this.handleRotate(evt);
      } else if (this.manipulationMode === 'scale') {
        this.handleScale(evt);
      }
      break;
    case 'off':
      break;
    default:
      break;
  }
  this.lastCoordinate = evt.coordinate;
  this.highlightFeatureCollection();
}

function handleMoveEvent(evt: MapBrowserEvent<UIEvent>): void {
  try {
    if (this.cursor) {
      const map = evt.map;
      const feature = map.forEachFeatureAtPixel(evt.pixel, (feat) => feat);
      const element = evt.map.getTargetElement();
      if (feature) {
        if (element.style.cursor != this.cursor) {
          this.previousCursor = element.style.cursor;
          element.style.cursor = this.cursor;
        }
      } else if (this.previousCursor !== undefined) {
        element.style.cursor = this.previousCursor;
        this.previousCursor = undefined;
      }
    }
  } catch {
    // do nothing
    // console.log('move event error');
  }
}

function handleUpEvent(): false {
  if (
    this.moveEnd &&
    this.originalFeatures[0].getGeometry().getCoordinates().toString() !=
      this.features[0].getGeometry().getCoordinates().toString()
  ) {
    this.moveEnd({
      originalFeatures: this.originalFeatures,
      features: this.features,
    });
  }
  if (this.wasDragged) {
    if (this.featuresChanged) {
      this.broadcastChange();
    } else {
      this.removeFeatures(this.collection.getArray());
      this.collection = new Collection(this.originalFeatures);
      this.restoreFeatures(this.collection.getArray());
      this.originalFeatures = [];
      this.highlightFeatureCollection();
    }
  } else if (this.clicked === 'highlight') {
    this.toggleMode();
  }
  this.wasDragged = false;
  this.featuresChanged = false;
  return false;
}
