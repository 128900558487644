<mat-tab-group
  #matTabGroup
  fitInkBarToContent="true"
  mat-stretch-tabs="false"
  mat-align-tabs="start"
  animationDuration="250ms"
  class="h-full font-rajdhani text-lg bg-white"
  [(selectedIndex)]="selected">


  <mat-tab class="border border-solid">
    <ng-template mat-tab-label>
      <div
        class="w-11 h-8 text-blue flex justify-center items-center"
        [ngClass]="{ 'bg-primary rounded-md text-white': selected === 0, 'text-[#414042]': selected !== 0 }">
        <mat-icon svgIcon="stopwatch" />
      </div>
    </ng-template>
    <ng-template matTabContent>
      <app-punch-clock-timer />
    </ng-template>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <div
        class="w-11 h-8 text-blue flex justify-center items-center"
        [ngClass]="{ 'bg-primary rounded-md text-white': selected === 1, 'text-[#414042]': selected !== 0 }">
        <mat-icon svgIcon="calendar" />
      </div>
    </ng-template>
    <ng-template matTabContent>
      <app-punch-clock-timesheet />
    </ng-template>
  </mat-tab>
</mat-tab-group>
