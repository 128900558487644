import { inject, Injectable } from '@angular/core';
import { BaseLayerBuilder } from './base-layer.builder';
import { createStyleFunction } from 'ol-esri-style';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { TileArcGISRest } from 'ol/source';
import { EsriJSON } from 'ol/format';
import { createXYZ } from 'ol/tilegrid';
import { tile } from 'ol/loadingstrategy';
import { LayerQueryFilters } from '../../../utilities/types';
import { Extent } from 'ol/extent';
import Layer from 'ol/layer/Layer';
import TileSource from 'ol/source/Tile';
import { DatabaseLayer, ServiceType } from '../types/layer.types';
import { EsriTileLoaderService } from '../tile-loaders/esri-tile-loader.service';

@Injectable({
  providedIn: 'root',
})
export class EsriLayerBuilder extends BaseLayerBuilder {
  private esriTileLoaderService = inject(EsriTileLoaderService);

  constructor() {
    super();
  }

  // (bbox && this.userService.isSettingActive(SettingID.LOCATE_AREA_BBOX)

  async build(layer: DatabaseLayer, filters: Partial<LayerQueryFilters>, bbox?: Extent): Promise<Layer> {
    switch (layer.serviceType) {
      case ServiceType.EsriMapServer:
        return this.buildMapService(layer, false);
      case ServiceType.EsriMapServerSubLayer:
        return this.buildMapService(layer, true);
      case ServiceType.EsriFeatureServer:
      case ServiceType.EsriFeatureServerSubLayer:
        return this.buildFeatureServer(layer, bbox);
      default:
        return null;
    }
  }

  private buildMapService(layer: DatabaseLayer, isSubLayer: boolean): TileLayer<TileSource> {
    const tileLayer = new TileLayer({ properties: { ...layer }, zIndex: layer.index });
    const loadFunction = isSubLayer
      ? this.esriTileLoaderService.createDynamicLoader
      : this.esriTileLoaderService.createBasicLoader;

    tileLayer.setSource(
      new TileArcGISRest({
        url: layer.layerURL,
        tileLoadFunction: loadFunction.bind(this.esriTileLoaderService)(tileLayer),
      })
    );

    if (isSubLayer) {
      tileLayer.set('subLayers', []);
    }

    return tileLayer;
  }

  private buildFeatureServer(layer: DatabaseLayer, bbox: Extent): VectorLayer<VectorSource> {
    const vectorLayer = new VectorLayer({
      properties: { ...layer },
      source: new VectorSource({
        format: new EsriJSON(),
        url: this.createFeatureServerUrl(layer, bbox),
        strategy: tile(createXYZ({ tileSize: 512 })),
      }),
      zIndex: layer.index,
    });
    this.addEsriStyle(vectorLayer); // ignore promise

    return vectorLayer;
  }

  private async addEsriStyle(layer: VectorLayer<VectorSource>) {
    try {
      const response = await fetch(
        `${layer.get('layerURL')}?f=json${layer.get('token') ? `&token=${layer.get('token')}` : ''}`
      );
      if (!response.ok) return;

      const json = await response.json();
      const func = await createStyleFunction(json, layer.getSource().getProjection());
      layer.setStyle(func);
    } catch (e) {
      console.warn('Failed to add style to layer', e);
    }
  }

  private createFeatureServerUrl(layer: DatabaseLayer, bbox: Extent) {
    return (extent, resolution, projection) => {
      const srid = projection
        .getCode()
        .split(/:(?=\d+$)/)
        .pop();
      return this.buildFeatureServerUrlString(layer, bbox || extent, srid);
    };
  }

  buildFeatureServerUrlString(layer: DatabaseLayer, bbox?: Extent, srid?: string) {
    let urlString = layer.layerURL;
    if (layer.serviceType === ServiceType.EsriFeatureServerSubLayer && layer.serviceSubLayerID) {
      urlString += `/${layer.serviceSubLayerID}`;
    }
    const url = new URL(urlString + '/query');
    const params: Record<string, string> = {
      f: 'json',
      returnGeometry: 'true',
      spatialRel: 'esriSpatialRelIntersects',
      geometry:
        `{"xmin":${bbox[0]},"ymin":${bbox[1]},"xmax":${bbox[2]},"ymax":${bbox[3]}` +
        `,"spatialReference":{"wkid":${srid}}}`,
      geometryType: 'esriGeometryEnvelope',
      inSR: srid,
      outFields: '*',
      outSR: srid,
    };
    if (layer.token) {
      params.token = layer.token;
    }
    Object.entries(params).forEach(([key, value]) =>
      url.searchParams.append(key, value)
    );
    return url.toString();
  }
}
