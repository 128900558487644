import { CommonModule } from "@angular/common";
import { Component, NgModule, OnInit } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { RouterModule } from "@angular/router";
import { SettingID } from "../core/services/user/setting";
import { UserService } from "../core/services/user/user.service";
import { MaterialModule } from "../shared/material.module";
import {
  HeaderOptions,
  RouteOptions,
} from "../shared/page-navigation/page-nav-options";
import { PageNavigationModule } from "../shared/page-navigation/page-navigation.module";
import { NavSettings } from "./NavigationModels";
import { SnackbarType } from "../shared/snackbar/snackbar/snackbar";
import { SnackbarService } from "../shared/snackbar/snackbar.service";

@Component({
  selector: "app-system-maintenance-page",
  template: ` <div fxLayout="row" style="height:100%; width:100%;">
    <app-page-navigation
      [routes]="routes"
      [header]="header"
    ></app-page-navigation>
    <div style="height:100%; width: calc(100% - 240px)">
      <router-outlet></router-outlet>
    </div>
  </div>`,
})
export class SystemMaintenanceComponent implements OnInit {
  routes: RouteOptions[] = [];
  header: HeaderOptions;

  subSettingToPage = {
    "1": {
      path: "emails",
      label: "Emails",
    },
    "2": {
      path: "third-party-jobs",
      label: "Automated Actions",
    },
    "3": {
      path: "360",
      label: "360 Positive Response",
    },
  };

  constructor(private user: UserService, private snackBarService: SnackbarService) {}

  async ngOnInit() {
    this.header = null;

    let routes = [];
    // let setting: any = await this.user.getSettingValueForUser(NavSettings.systemMaintenance, this.user.getUserID());

    if (this.user.isSettingActive(SettingID.SYSTEM_MAINTENANCE)) {
      if (this.user.getSettingValue(SettingID.SYSTEM_MAINTENANCE)) {
        let subSettingIDs = this.user
        .getSettingValue(SettingID.SYSTEM_MAINTENANCE)
        .split(",");
      for (let subSetting of subSettingIDs) {
        if (this.subSettingToPage[subSetting]) {
          routes.push(this.subSettingToPage[subSetting]);
        }
      }
      if (!routes) {
        routes.push(this.subSettingToPage[1]);
      }
      this.routes = routes;
      } else {
        routes.push(this.subSettingToPage[1]);
        routes.push(this.subSettingToPage[3]);
        this.routes = routes;
      }
    } else {
      this.snackBarService.openSnackbar(
        "Invalid Permissions",
        SnackbarType.warning
      );
    }
  }
}

@NgModule({
  declarations: [SystemMaintenanceComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    RouterModule,
    PageNavigationModule,
  ],
  exports: [SystemMaintenanceComponent],
})
export class RoutingPresetModule {}
