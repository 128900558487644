import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderOptions, RouteOptions } from "src/app/modules/shared/page-navigation/page-nav-options";
import { AutomatedEngineService } from '../automated-engine.service';

@Component({
  selector: 'app-rule-builder-types',
  templateUrl: './rule-builder-types.component.html',
  styleUrls: ['./rule-builder-types.component.scss']
})
export class RuleBuilderTypesComponent implements OnInit {
  @Input() Rules: any;
  routes: RouteOptions[] = [];
  header: HeaderOptions = {
    title: "Rules",
  };

  constructor(private router: Router, private automatedEngineService: AutomatedEngineService,private route: ActivatedRoute, 
    ) { }

  ngOnInit(): void {
    this.router.navigate([], {
      queryParamsHandling: "merge",
      replaceUrl: true,
    });
    this.setupSideNav();

    this.router.navigate(["0"], { relativeTo: this.route });
  }

  /**
   * Sets up the side navigation component with the buttons for rule types 
   */
  private async setupSideNav() {
    let AdminTables = await this.automatedEngineService.getAdminTables();
    this.Rules = AdminTables.AdminRuleTypes;
    this.routes = this.Rules.map((ruleType) => ({
      path: ruleType.AdminRuleTypeID,
      label: ruleType.RuleTypeName,
    }));

    //sort this.routes alphabeitcally 
    this.routes.sort((a, b) => a.label.localeCompare(b.label));

    this.routes.unshift({
      path: '0',
      label: "All rules",
    });
  }
}
