import { Component, OnDestroy, OnInit } from '@angular/core';
import { UtilocateApiService } from 'src/app/modules/core/api/utilocateapi.service';
import { CacheService, StoreType } from 'src/app/modules/core/cache/cache.service';
import { DatetimeService } from 'src/app/modules/core/services/datetime/datetime.service';
import { UserService } from 'src/app/modules/core/services/user/user.service';
import { ConfirmationDialogComponent } from '../../../confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PunchClockService } from '../punch-clock.service';

@Component({
  selector: "app-punch-clock-timer",
  templateUrl: "./punch-clock-timer.component.html",
  styleUrls: ["./punch-clock-timer.component.scss"],
})
export class PunchClockTimerComponent implements OnInit, OnDestroy {
  time: any;
  timer: any;
  interval: any = 1000;
  currentLocale: any;
  buttonColor: string = "primary";
  buttonText: string = "START";
  isTimeRunning: boolean = false;
  tasks: any = [];
  selectedOption: string;
  intervalId: any;
  intervalIdUi: any;
  startTime: any;
  endTime: any;
  taskId: any;
  value: any;
  firstLoad: boolean = true;
  entryId: any = 1;
  showDrop: boolean = true;
  currentDate: any;
  currentDateUi: any;
  confirmed: any;

  constructor(
    private utilocateApiService: UtilocateApiService,
    private idb: CacheService,
    private datetimeService: DatetimeService,
    private userService: UserService,
    private dialog: MatDialog,
    private punchClockService: PunchClockService
    ) { }

  async ngOnInit() {
    let checkTimer = await this.idb.query(
      StoreType.PUNCH_CLOCK,
      "RunningClock"
    );
    clearInterval(this.intervalId);

    if(checkTimer){
      this.punchClockRunning(checkTimer)
    }
    else{
      this.startTimerUi()
    }
    this.currentDate = new Date();
    this.time = this.currentDate.toLocaleTimeString();
  
    let response = await this.punchClockService.gatherTasks()
    this.value = response[1]
    this.tasks = response[0]
    console.log(this.currentDate);
    if(this.userService.firstLogin()){
      this.punchClockService.clearPunchTimes()
    }
  }

  //This is for the internal clock
  async startTimer(): Promise<void> {
    return await new Promise<void>((resolve) => {
      this.intervalId = setInterval(() => {
        this.currentDate = new Date();
        this.time = this.currentDate.toLocaleTimeString();
        resolve(); // Resolve the Promise when the timer callback is done
      }, this.interval);
    });
  }

  //This is for the ui clock
  startTimerUi() {
    this.intervalIdUi = setInterval(() => {
      this.currentDateUi = new Date();
      this.timer = this.currentDateUi.toLocaleTimeString();
    }, 1000);
  }

  async toggleTime() {
    this.isTimeRunning = !this.isTimeRunning;
    if (this.isTimeRunning) {
      this.onPunchIn();
    } else {
     const confirmed = await this.onPunchOut();
     if (confirmed) {
       this.punchClockService.clearRunningClock();
     }
    }
  }

  async onPunchIn() {
    this.confirmed = false;
    this.showDrop = false;
    this.firstLoad = false;
    clearInterval(this.intervalIdUi);
    await this.startTimer();    
    this.startTime = this.currentDate;
    this.buttonColor = "warn";
    this.buttonText = "PUNCH OUT";
    let runningTimer = {
      entryID: this.entryId,
      startTime: this.startTime,
      taskDesc: this.selectedOption,
      taskID: this.taskId
    }
    this.punchClockService.insertRunningClockToIDB(runningTimer)
  }

 punchClockRunning(idbObj){
    this.firstLoad = false
    this.isTimeRunning = true
    this.showDrop = false
    this.startTime = idbObj.startTime
    this.timer = idbObj.startTime.toLocaleTimeString()
    this.selectedOption = idbObj.taskDesc
    this.taskId = idbObj.taskID
    this.buttonColor = 'warn';
    this.buttonText = 'PUNCH OUT';
      
  }

  async onPunchOut() {
    const dialogRef = await this.dialog.open(ConfirmationDialogComponent, {
      width: "300px",
      data: {
        title: "PUNCH OUT",
        message: "Are you sure you want to punch out and end your work day",
        confirmColor: "primary",
        confirmText: "Confirm",
      },
    });

    return new Promise<boolean>((resolve) => {
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.endTime = this.currentDate
          clearInterval(this.intervalId);
          this.buttonColor = 'primary'; // Change button color back to primary
          this.buttonText = 'PUNCH IN';
          this.taskCompleted()
          this.showDrop = true
          this.firstLoad = true
          this.startTimerUi()
          this.selectedOption = ''
          resolve(true); // Resolves with true if confirmed
        } else {
          this.isTimeRunning = true;
          resolve(false); // Resolves with false if not confirmed
        }
      });
    });
  }

  taskCompleted() {
    if (this.firstLoad) {
      for (let i of this.value.tbTimesheet_Tasks.Data) {
        if (i.TimesheetTaskName == this.selectedOption) {
          this.taskId = i.TimesheetTaskID;
        }
      }
      return "";
    }

    this.currentDateUi = new Date();
    this.timer = this.currentDateUi.toLocaleTimeString();

    let formattedStartTime = this.datetimeService.localDateToDBDateStr(
      this.startTime
    );
    let formattedEndTime = this.datetimeService.localDateToDBDateStr(
      this.currentDate
    );
    let completedTask = {
      entryID: this.entryId,
      startTime: formattedStartTime,
      endTime: formattedEndTime,
      taskID: this.taskId
    }
  

    this.punchClockService.insertPunchTimesToIDB(completedTask)
    this.punchClockService.uploadTask(completedTask)

    for(let i of this.value.tbTimesheet_Tasks.Data){
      if(i.TimesheetTaskName == this.selectedOption){
        this.taskId = i.TimesheetTaskID 
      }
    }

    let runningTimer = {
      entryID: this.entryId,
      startTime: this.currentDate,
      taskDesc: this.selectedOption,
      taskID: this.taskId
    }
    this.punchClockService.insertRunningClockToIDB(runningTimer)


    this.startTime = this.currentDate
    this.entryId++
    this.showDrop = false
  }



  openDialog() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "300px",
      data: {
        title: "PUNCH OUT",
        message: "Are you sure you want to punch out and end your work day",
        confirmColor: "Warn",
        confirmText: "Confirm",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        return true;
      }
      return false;
    });
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
    clearInterval(this.intervalIdUi);
  }

}
