import { inject, Injectable } from '@angular/core';
import { BaseLayerBuilder } from './base-layer.builder';
import { LayerQueryFilters } from '../../../utilities/types';
import Layer from 'ol/layer/Layer';
import { DatabaseLayer, ServiceType } from '../types/layer.types';
import { Feature } from 'ol';
import { CollectionFlattener, FeatureOrCollection } from '../../../classes/collection-flattener';
import { Extent } from 'ol/extent';
import { Geometry } from 'ol/geom';
import { FeatureChangeType, MapFeatureChange } from '../../../classes/map-feature-change';
import VectorSource from 'ol/source/Vector';
import { GeoJSON } from 'ol/format';
import { LoadFunction as TileLoadFunction } from 'ol/Tile';
import TileLayer from 'ol/layer/Tile';
import { TileWMS } from 'ol/source';
import { bbox as bbox_strategy } from 'ol/loadingstrategy';
import VectorLayer from 'ol/layer/Vector';
import { FeatureStyleService } from '../../feature-style.service';
import { GeoServerTileLoaderService } from '../tile-loaders/geoserver-tile.loader';
import { GeoserverService } from '../../geoserver.service';

@Injectable({
  providedIn: 'root',
})
export class GeoServerLayerBuilder extends BaseLayerBuilder {
  private featureStyleService = inject(FeatureStyleService);
  private tileLoaderService = inject(GeoServerTileLoaderService);
  private geoserverService = inject(GeoserverService);

  // members

  private _checkChanges: () => MapFeatureChange<FeatureOrCollection>[];

  constructor() {
    super();
  }

  async build(layer: DatabaseLayer, filters: Partial<LayerQueryFilters> = undefined): Promise<Layer> {
    switch (layer.serviceType) {
      case ServiceType.WMSLayerGeoServer:
        return this.buildWMSLayer(layer, this.tileLoaderService.createBasicLoader);
      case ServiceType.WMSLayerGeoServerSubLayer:
        return this.buildWMSLayer(layer, this.tileLoaderService.createSubLayerLoader);
      case ServiceType.GeoserverWFSLayer:
        return this.buildWFSLayer(layer, filters);
      default:
        return null;
    }
  }

  private buildWMSLayer(
    layer: DatabaseLayer,
    loadFunctionFactory: (layer: Layer) => TileLoadFunction,
    extent?: Extent
  ): TileLayer<TileWMS> {
    const tileLayer = new TileLayer<TileWMS>({
      properties: {
        ...layer,
        subLayers: [],
        config: {
          showLabels: false,
          isVisible: layer.isDefaultOn === 1,
        },
      },
      extent,
      zIndex: layer.index,
    });
    tileLayer.setSource(
      new TileWMS({
        params: {
          tiled: true,
        },
        url: layer.layerURL,
        tileLoadFunction: loadFunctionFactory.bind(this.tileLoaderService)(tileLayer),
      })
    );
    return tileLayer;
  }

  public async buildWFSLayer(
    layer: DatabaseLayer,
    filters: Partial<LayerQueryFilters> = {}
  ): Promise<VectorLayer<VectorSource>> {
    const { username, password, subLayerID, utilityID } = layer;
    const isLocateAreaLayer = Number(subLayerID) === 1;
    const isUtilityLayer: boolean = utilityID !== undefined && utilityID !== null && Number(utilityID) !== -1;
    const flatten: (prop: FeatureOrCollection) => Feature<Geometry>[] = new CollectionFlattener().flattenFeature;
    const checkChanges: () => MapFeatureChange<FeatureOrCollection>[] = this._checkChanges.bind(this);
    const vectorSource = new VectorSource({
      loader: async (extent, resolution, projection, success, failure) => {
        const proj = projection.getCode();
        const urlObj = new URL(layer.layerURL);
        [
          ['service', 'WFS'],
          ['version', '2.0.0'],
          ['request', 'GetFeature'],
          ['typeNames', layer.subLayerName],
          ['outputFormat', 'application/json'],
          ['srsname', proj],
        ].forEach(([key, val]) => urlObj.searchParams.set(key, val));
        if (isLocateAreaLayer && filters.requestNumber) {
          urlObj.searchParams.set('cql_filter', `request_number='${filters.requestNumber}'`);
        }
        if (isUtilityLayer) {
          urlObj.searchParams.set('cql_filter', `utility_id=${utilityID}`);
        }
        const onError = () => {
          vectorSource.removeLoadedExtent(extent);
          failure();
        };
        try {
          const response = await this.geoserverService.makeRequest(urlObj.toString(), {
            method: 'GET',
            headers: {
              Authorization: 'Basic ' + btoa(`${username}:${password}`),
            },
          });

          const JSONFormat = new GeoJSON();
          if (response.status == 200) {
            const features = [];
            try {
              JSONFormat.readFeatures(await response.json()).forEach((feature) => features.push(feature));
            } catch (error) {
              console.error('Failed to parse JSON');
              onError();
            }
            if (features.length > 0 && checkChanges !== undefined) {
              const changes: MapFeatureChange<FeatureOrCollection>[] = checkChanges();
              const rawFeatures = changes
                .map((x) => {
                  const feats = flatten(x.feature);
                  feats.map((y) => y.set('changeType', x.changeType));
                  return feats;
                })
                .flat();
              vectorSource.addFeatures(features);
              rawFeatures.forEach((feature) => {
                if (feature.get('changeType') === FeatureChangeType.deleted) {
                  const tf = vectorSource.getFeatureById(feature.getId());
                  if (tf instanceof Feature) {
                    vectorSource.removeFeature(tf);
                  }
                }
              });
            }
            success(features);
          } else {
            onError();
          }
        } catch (e) {
          console.error(e);
          console.error('Get features failed for:' + urlObj.hostname + urlObj.pathname);
          onError();
        }
      },
      format: new GeoJSON(),
      strategy: bbox_strategy,
    });
    const vectorLayer = new VectorLayer({
      source: vectorSource,
      style: this.featureStyleService.renderFeature.bind(this.featureStyleService),
      zIndex: layer.index,
    });
    vectorLayer.setProperties({ ...layer } as Record<string, any>);
    return vectorLayer;
  }

  set checkChanges(func: () => any) {
    this._checkChanges = func;
  }
}
