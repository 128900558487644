import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';
import { LayerManagerService } from '../services/layer-manager/layer-manager.service';
import { takeUntil, tap } from 'rxjs/operators';
import { PillIndicatorComponent } from '../../../shared/components/indicators/pill-indicator/pill-indicator.component';
import { FabOverlayComponent } from '../../../shared/components/containers/fab-overlay/fab-overlay.component';
import { MatIconModule } from '@angular/material/icon';
import { TabbedContainerComponent } from '../../../shared/components/containers/tabbed-container/tabbed-container.component';
import { ContainerTabComponent } from '../../../shared/components/containers/tabbed-container/container-tab/container-tab.component';
import { LayerListComponent } from './layer-list/layer-list.component';
import { LegendComponent } from './legend/legend.component';
import { DrawingService, DrawingTypes } from '../drawing.service';
import { AsyncPipe } from '@angular/common';
import { Drawing } from '../services/drawing-manager.service';
import { DrawingListComponent } from '../drawing-canvas/drawing-switcher/drawing-list/drawing-list.component';
import Layer from 'ol/layer/Layer';
import { DatabaseLayer } from '../services/layer-manager/types/layer.types';

@Component({
  selector: 'app-layer-switcher',
  standalone: true,
  imports: [
    PillIndicatorComponent,
    FabOverlayComponent,
    MatIconModule,
    TabbedContainerComponent,
    ContainerTabComponent,
    LayerListComponent,
    LegendComponent,
    AsyncPipe,
    DrawingListComponent,
  ],
  template: `
    <div class="flex flex-row justify-end items-center gap-3">
      <app-pill-indicator
        [content]="selectedLayerName"></app-pill-indicator>
      <app-fab-overlay
        [hasBackdrop]="false"
        [positionPairs]="positionPairs"
        [realign$]="positionChange$">
        <mat-icon icon svgIcon="layers" />
        <app-tabbed-container class="tabbed-container" menu [tabs]="tabs">
          <app-container-tab [tab]="tabs[0]">
            <app-layer-list
              (layerSelectionChanged)="layerSelectionChanged.emit($event)"
              [selectedLayer]="selectedLayer"
              class="layers"
              [layers]="this.layerManager.layers$"></app-layer-list>
          </app-container-tab>
          <app-container-tab [tab]="tabs[1]">
            <app-legend class="legend"></app-legend>
          </app-container-tab>
          <app-container-tab [tab]="tabs[2]">
            <app-drawing-list
              [drawings]="drawings"
              [activeDrawing]="activeDrawing"
              (deleteClick)="deleteClick.emit($event)"
              (drawingSelectionChanged)="activeDrawingChanged.emit($event)"></app-drawing-list>
          </app-container-tab>
        </app-tabbed-container>
      </app-fab-overlay>
    </div>
  `,
})
export class LayerSwitcherComponent implements OnInit, OnDestroy {
  // IO
  @ViewChild('tabby') tabbedContainer: ElementRef;
  @Input() classOverride: string;
  @Input() drawings: Drawing[];
  @Input() activeDrawing: string;
  @Input() selectedLayer: Layer;
  @Output() layerSelectionChanged = new EventEmitter<Layer>();
  @Output() activeDrawingChanged = new EventEmitter<string>();
  @Output() deleteClick = new EventEmitter<string>();

  // services
  protected mapDrawingService = inject(DrawingService);
  protected layerManager = inject(LayerManagerService);

  // observables
  private destroy$: Subject<void> = new Subject<void>();
  protected positionChange$: Subject<void> = new Subject<void>();

  // Properties
  selectedLayerName = '';
  // layout options
  tabs = [
    { title: 'layers', index: 0 },
    { title: 'legend', index: 1 },
    { title: 'sketches', index: 2 },
  ];
  positionPairs: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
      offsetX: 0,
      offsetY: 24,
    },
  ];

  constructor() {}

  ngOnInit(): void {
    this.mapDrawingService.drawingModeEnabled$
      .pipe(
        takeUntil(this.destroy$),
        tap(() => this.positionChange$.next())
      )
      .subscribe(() => {});
    this.layerManager.selectedLayer$.pipe(takeUntil(this.destroy$)).subscribe((next: Layer | null) => {
      const { displayName, layerServiceName, utilityName } = (next?.getProperties() as DatabaseLayer) ?? {};
      if (!next) {
        this.selectedLayerName = '';
        return;
      }
      let str = '';
      if (utilityName) {
        str += utilityName;
      }
      if (displayName || layerServiceName) {
        str += str ? ` > ${displayName || layerServiceName}` : displayName || layerServiceName;
      }
      this.selectedLayerName = str;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  protected readonly DrawingService = DrawingService;
  protected readonly DrawingTypes = DrawingTypes;
}
