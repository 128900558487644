import { inject, Injectable, OnDestroy } from '@angular/core';
import { LayerManagerService } from '../../services/layer-manager/layer-manager.service';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { concatMap, filter, finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import axios from 'axios';
import { ServiceType } from '../../services/layer-manager/types/layer.types';
import Layer from 'ol/layer/Layer';
import { Extent } from 'ol/extent';
import { GeoserverService } from '../../services/geoserver.service';

@Injectable({
  providedIn: 'root',
})
export class MapLegendService implements OnDestroy {
  // services
  private layerService = inject(LayerManagerService);
  private geoServerService = inject(GeoserverService);

  featureJsonStore = [];
  private featureJson$ = new BehaviorSubject<(LegendSymbol | LegendSymbol[])[]>([]);
  destroy$ = new Subject<void>();

  constructor() {
    this.layerService.databaseLayerStream
      .pipe(
        tap(() => {
          this.featureJsonStore = [];
        }),
        switchMap((layers) =>
          from(layers).pipe(
            map((layer) => {
              switch (layer.serviceType) {
                case ServiceType.EsriMapServer:
                  return layer.layerURL + '/legend?f=json' + `${layer.token ? '&TOKEN=' + layer.token : ''}`;
                case ServiceType.EsriFeatureServer:
                  return `${layer.layerURL}?f=json${layer.token ? '&TOKEN=' + layer.token : ''}`;
                default:
                  return '';
              }
            }),
            filter((x) => x !== ''),
            concatMap((x) => from(axios.get(x))),
            map(({ data }) => data),
            filter((x) => !x.hasOwnProperty('error')),
            map((data) => {
              const objects: (LegendSymbol | LegendSymbol[])[] = [];
              let obj = {
                name: data['name'],
              } as LegendSymbol;
              if (data['drawingInfo']) {
                const { symbol, renderer } = data['drawingInfo'];
                if (renderer) {
                  const { type, symbol: rendererSymbol, uniqueValueInfos } = renderer;
                  switch (type) {
                    case 'simple':
                      obj = rendererSymbol['imageData']
                        ? ({
                            ...obj,
                            imageData: rendererSymbol['imageData'],
                            width: rendererSymbol['width'],
                            height: rendererSymbol['height'],
                            color: '',
                            type: 'icon',
                          } as LegendSymbol)
                        : ({
                            ...obj,
                            imageData: '',
                            width: rendererSymbol['width'],
                            height: rendererSymbol['height'],
                            color: rendererSymbol['color'].join(', '),
                            type: 'line',
                          } as LegendSymbol);
                      objects.push(obj);
                      break;
                    case 'uniqueValue':
                      objects.push(
                        uniqueValueInfos.map((val) => {
                          const { label, symbol: uniqeSymbol, value } = val;
                          if (uniqeSymbol.hasOwnProperty('type') && uniqeSymbol['type'] === 'esriPMS') {
                            return {
                              name: label,
                              imageData: uniqeSymbol['imageData'],
                              width: uniqeSymbol['width'],
                              height: uniqeSymbol['width'],
                              color: '',
                              type: 'icon',
                              groupName: data['name'],
                            } as LegendSymbol;
                          } else {
                            return {
                              name: label,
                              imageData: '',
                              width: uniqeSymbol['width'],
                              height: uniqeSymbol['width'],
                              color: uniqeSymbol['color'].join(', '),
                              type: 'line',
                              groupName: data['name'],
                            } as LegendSymbol;
                          }
                        })
                      );
                      break;
                  }
                } else if (symbol['type'] === 'esriPMS') {
                  obj = {
                    ...obj,
                    imageData: symbol['imageData'],
                    width: symbol['width'],
                    height: symbol['height'],
                    color: '',
                    type: 'icon',
                  } as LegendSymbol;
                }
              } else if (data['layers']) {
                data['layers'].forEach((layer) => {
                  const { layerName, legend } = layer;
                  objects.push(
                    legend.map((item) => {
                      return {
                        name: item.label,
                        color: '',
                        height: item.height,
                        width: item.width,
                        imageData: item.imageData,
                        type: item.imageData ? 'icon' : 'line',
                        groupName: layerName,
                      } as LegendSymbol;
                    })
                  );
                });
              }
              return objects;
            }),
            map((arr) => (this.featureJsonStore = [...this.featureJsonStore, ...arr])),
            finalize(() => {
              this.featureJson$.next(this.featureJsonStore);
            })
          )
        ),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get featureJson(): Observable<(LegendSymbol | LegendSymbol[])[]> {
    return this.featureJson$.pipe();
  }

  private fetchGeoserverWMSGraphic(layer: Layer, bbox: Extent) {
    const url = new URL(layer.get('layerURL'));
    const params: Record<string, string> = {
      REQUEST: 'GetLegendGraphic',
      SERVICE: 'WMS',
      VERSION: '1.3.0',
      FORMAT: 'image/png',
      TRANSPARENT: 'true',
      WIDTH: '24',
      HEIGHT: '24',
      LAYER: this.getLayerName(layer),
    };

    if (bbox) {
      params.BBOX = bbox.join(',');
    }

    Object.entries(params).forEach(([key, value]) => url.searchParams.append(key, value));

    const options: RequestInit = {};

    const cred = this.geoServerService.getBasicAuthHeaderFromLayer(layer);
    if (cred) {
      options.headers = {
        Authorization: cred,
      };
    }

    return this.geoServerService.makeRequest(url.toString(), options);
  }

  private getLayerName(layer: Layer): LayerName {
    return layer.get('name');
  }
}

type LayerName = string;

export type LegendSymbol = {
  name: string;
  imageData: string;
  width: number;
  height: number;
  color: string;
  type: string;
  groupName?: string;
};
