@if (showLabel) {
  <div
    class="flex flex-row gap-[1px] justify-center items-center rounded-md drop-shadow-xl bg-white overflow-hidden divide-x-1 divide-y-0 divide-solid divide-white divide-opacity-50">
    @for (tab of tabs; track tab.index) {
      <button
        (click)="handleTabClick(tab.index)"
        class="pointer-events-auto flex justify-center items-center gap-1 px-6 py-1.5 appearance-none border-none {{
          isSelected(tab.index) ? 'bg-primary' : 'bg-white'
        }} hover: cursor-pointer">
        <mat-icon
          style="width: 24px; height: 24px; font-size: 24px"
          class="{{ isSelected(tab.index) ? 'text-white' : 'text-gray-500' }}"
          svgIcon="{{ tab.icon }}"></mat-icon>
        <span
          class="font-rajdhani font-semibold uppercase text-l text-center {{
            isSelected(tab.index) ? 'text-white' : 'text-gray-500'
          }}">
          {{ tab.title }}
        </span>
      </button>
    }
  </div>
} @else {
  <div
    class="flex flex-row justify-center items-center rounded-md drop-shadow-xl bg-white overflow-hidden divide-x-1 divide-y-0 divide-solid divide-white divide-opacity-50">
    @for (tab of tabs; track tab.index) {
      <button
        (click)="handleTabClick(tab.index)"
        class="pointer-events-auto flex justify-center items-center px-3 py-1.5 appearance-none border-none {{
          isSelected(tab.index) ? 'bg-primary shadow-inner' : 'bg-white'
        }} hover: cursor-pointer">
        <mat-icon
          style="width: 24px; height: 24px; font-size: 24px"
          class="{{ isSelected(tab.index) ? 'text-white' : 'text-primary' }}"
          svgIcon="{{ tab.icon }}"></mat-icon>
      </button>
    }
  </div>
}
