// @ts-nocheck
import { Component, ElementRef, Input, NgModule, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { loadModules } from 'esri-loader';
import { LoggerService } from 'src/app/modules/core/services/logger/logger.service';
import { MaterialModule } from '../../../material.module';
import { ProgressBarService } from '../../../progress-bar/progress-bar.service';
import { SnackbarService } from '../../../snackbar/snackbar.service';
import { SnackbarType } from '../../../snackbar/snackbar/snackbar';

import { BaseEsriMapModule } from '../base-esri-map/base-esri-map.component';
import { EsriMapBasemapsComponent } from '../esri-map-basemaps/esri-map-basemaps.component';
import { EsriMapLayersComponent } from '../esri-map-layers/esri-map-layers.component';
import { EsriMapLegendComponent } from '../esri-map-legend/esri-map-legend.component';
import { Layer, LayerInfo, LayerOptions, LayerType, MapOptions } from '../EsriMapModels';
import { EsriMapLayerService } from './services/esri-map-layer.service';
import { EsriMapMeasurementComponent } from '../esri-map-measurement/esri-map-measurement.component';
import { ApiService, UtilocateApiRequest } from '../../../../core/api/baseapi.service';
import { apiKeys } from '../../../../../ENDPOINTS';
import { environment } from '../../../../../../environments/environment';
import FeatureSet = __esri.FeatureSet;

@Component({
  selector: 'app-esri-map',
  templateUrl: 'esri-map.component.html',
  styleUrls: ['esri-map.component.css'],
})
export class EsriMapComponent implements OnChanges {
  className = 'EsriMapComponent';

  @Input() mapOptions: MapOptions;
  @Input() layerOptions: LayerOptions;
  @Input() disableMapControls: boolean;
  @ViewChild('content') elementView: ElementRef;

  // map variables set once map loaded
  map: any;
  mapView: any;

  // layer variables
  private numLayers = 0;
  private layerCount = 0;
  private loadedLayers = [];
  layerGroups: Layer[] = [];

  // identify variables
  protected _identifyEnabled = false;

  layersOpen: boolean = false;
  legendOpen: boolean = false;
  basemapOpen: boolean = false;
  measurementOpen: boolean = false;

  dragEvent: any = null;

  constructor(
    private logger$: LoggerService,
    private layers$: EsriMapLayerService,
    private progressBarService: ProgressBarService,
    private snackBarService: SnackbarService,
    private apiService: ApiService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.layerOptions && !changes.layerOptions.firstChange) {
      if (this.map) {
        this.initLayers();
      }
    }
  }

  // toggles

  toggleLayers() {
    if (!this.layersOpen) {
      this.legendOpen = false;
      this.basemapOpen = false;
    }
    this.layersOpen = !this.layersOpen;
  }

  toggleLegend() {
    if (!this.legendOpen) {
      this.layersOpen = false;
      this.basemapOpen = false;
    }
    this.legendOpen = !this.legendOpen;
  }

  toggleBasemap() {
    if (!this.basemapOpen) {
      this.layersOpen = false;
      this.legendOpen = false;
    }
    this.basemapOpen = !this.basemapOpen;
  }

  toggleMeasurement() {
    if (!this.measurementOpen) {
      this.layersOpen = false;
      this.basemapOpen = false;
    }
    this.measurementOpen = !this.measurementOpen;
  }

  toggleIdentify(): boolean {
    this._identifyEnabled = !this._identifyEnabled;
    if (this._identifyEnabled) {
      document.getElementById('mapViewContainer').style.cursor = 'help';
    } else {
      document.getElementById('mapViewContainer').style.cursor = 'auto';
    }
    return this._identifyEnabled;
  }

  // actions

  async takeScreenshot() {
    const result = await this.mapView.takeScreenshot({
      format: 'jpg',
    });
    return result;
  }

  async goTo(lat: number, long: number) {
    if (this.mapView) {
      await this.mapView.goTo({ center: [long, lat] });
    }
  }

  getDirections() {
    const dirLat = this.mapOptions.center[1];
    const dirLng = this.mapOptions.center[0];
    let srcLat: number | string;
    let srcLng: number | string;
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        srcLat = position.coords.latitude;
        srcLng = position.coords.longitude;

        const url =
          'https://www.google.com/maps/dir/?api=1&origin=' +
          srcLat +
          ',' +
          srcLng +
          '&destination=' +
          dirLat +
          ',' +
          dirLng +
          '&travelmode=driving';

        this.goToLink(url);
      });
    }
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }

  // map

  mapLoaded(mapObj) {
    this.map = mapObj.map;
    this.mapView = mapObj.mapView;
    if (this.disableMapControls) {
      this.enableMapControls(false);
    }

    if (this.layerOptions) {
      this.initLayers();
    }
  }

  async initLayers() {
    this.layers$.resetService();
    this.progressBarService.start();

    // authenticate with service urls using tokens
    await this.layers$.generateEsriTokens(this.layerOptions.credentials).toPromise();

    // set the number of layers to we can track when all of them are loaded
    this.numLayers = this.layerOptions.layers.length;

    // set up the layer service
    this.layers$.createDefaultOnLayersObj(this.layerOptions.defaultSubLayers);
    this.layers$.createLayerGroups(this.layerOptions.groups);

    // make the layers
    await this.initializeEsriLayersArray(this.layerOptions.layers);

    // add the layers to the map
    this.map = this.layers$.applyLayersToMap(this.map, this.loadedLayers);

    // set up the on map click listener
    this.onEsriViewEventHandler();

    this.afterLayersLoad();

    this.progressBarService.stop();
  }

  async afterLayersLoad() {}

  async initializeEsriLayersArray(listOfLayers: LayerInfo[]) {
    try {
      if (listOfLayers) {
        const [FeatureLayer, MapImageLayer, VectorTileLayer, TileLayer, WMSLayer, esriConfig] = await loadModules([
          'esri/layers/FeatureLayer',
          'esri/layers/MapImageLayer',
          'esri/layers/VectorTileLayer',
          'esri/layers/TileLayer',
          'esri/layers/WMSLayer',
          'esri/config',
        ]);

        for (let i = 0; i < listOfLayers.length; i++) {
          const layerInfo: LayerInfo = listOfLayers[i];

          const layerUrl = layerInfo.url;
          const layerType = layerInfo.layerTypeID;
          const layerName = layerInfo.name;
          const layerID = layerInfo.layerID;
          const groupID = layerInfo.groupID;
          const isDefaultOn = layerInfo.isVisible;

          try {
            var layer = null;

            if (layerType == LayerType.FeatureLayer) {
              // need to test feature layers before using them, because they haven't been tested
              layer = new FeatureLayer({ url: layerUrl });
            } else if (layerType == LayerType.WMSLayer) {
              if (layerUrl.includes('geoserver')) {
                const request: UtilocateApiRequest = {
                  API_TYPE: 'PUT',
                  API_KEY: apiKeys.u2.getMapLayers,
                  API_BODY: { query: { auth: { geoserver: { group: 0 } } } },
                };

                let username = "";
                let password = "";

                let getMapLayerResult = await this.apiService.invokeUtilocateApi(request);
                if (getMapLayerResult && getMapLayerResult.body) {
                  if (getMapLayerResult.body.result) {
                    username = getMapLayerResult.body.result.username;
                    password = getMapLayerResult.body.result.password;
                  } else {
                    username = getMapLayerResult.body.username;
                    password = getMapLayerResult.body.password;
                  }
                }
                // const { username, password } = (await this.apiService.invokeUtilocateApi(request))?.body?.result;

                esriConfig.request.interceptors.push({
                  urls: /.*u4ia.*geoserver.*|.*geoserver.*u4ia.*/gi,
                  before: function (params) {
                    params.url = (() => {
                      const isLocalHost = environment.localhost;
                      if (!isLocalHost) {
                        return params.url;
                      } else {
                        return 'http://localhost:3000/geoserver' + params.url.split('geoserver')[1];
                      }
                    })();
                  },
                  headers: {
                    Authorization: 'Basic ' + btoa(username + ':' + password),
                  },
                });
              }
              layer = new WMSLayer({
                url: layerUrl,
              });
            } else if (layerType == LayerType.MapServer) {
              layer = new MapImageLayer({ url: layerUrl });
            } else if (layerType == LayerType.Basemap) {
              const basemapLayer = new VectorTileLayer({
                url: layerUrl,
              });
              this.map.add(basemapLayer);
            } else if (layerType == LayerType.TileLayer) {
              layer = new TileLayer({ url: layerUrl });
            }

            if (layer != null) {
              layer.when(() => {
                this.allLayersLoad();
              });
              layer.on('layerview-create-error', (event) => {
                console.error(
                  'LayerView failed to create for layer with the id: ',
                  layer.id,
                  ' in this view: ',
                  event.view
                );
                this.allLayersLoad();
              });

              // add some data to the layer object
              layer['dbLayerName'] = layerName;
              layer['dbLayerID'] = layerID;
              layer['groupID'] = groupID;
              if (!isDefaultOn) {
                layer.visible = false;
              }

              // add the layer to the list
              this.loadedLayers.push(layer);
            } else {
              this.layerCount++;
            }
          } catch (error) {
            this.logger$.error('Error initializing layer: ', layerName, ' Error Message: ', error);
          }
        }
      }
    } catch (error) {
      this.logger$.error('initializeEsriLayersArray: ', error);
    }
  }

  allLayersLoad() {
    if (++this.layerCount == this.numLayers) {
      let failedLayers = '';

      // sremoved failed layers from list
      for (let i = this.loadedLayers.length - 1; i >= 0; i--) {
        if (this.loadedLayers[i].loadStatus == 'failed') {
          if (failedLayers.length > 0) {
            failedLayers += ', ';
          }
          failedLayers += this.loadedLayers[i].dbLayerName;
          this.loadedLayers.splice(i, 1);
        }
      }

      this.layers$.toggleDefaultOnLayers(this.loadedLayers);
      this.layers$.createLayerTree(this.loadedLayers);
      this.layerGroups = this.layers$.addLayersToGroup();

      if (failedLayers.length > 0) {
        this.snackBarService.openSnackbar(failedLayers + ': Failed to load Layer(s)', SnackbarType.error);
      }
    }
  }

  onEsriViewEventHandler() {
    try {
      if (this.mapView) {
        this.mapView.on('click', (event) => {
          this.queryLayers(event);
        });
      }
    } catch (error) {
      this.logger$.error('Error onEsriViewEventHandler: ', error);
    } finally {
      document.getElementById('mapViewContainer').style.cursor = 'auto';
    }
  }

  // Shows the results of the Identify in a popup once the promise is resolved
  showPopup(event, response) {
    if (response?.length > 0) {
      this.mapView.popup.open({
        features: response,
        location: event.mapPoint,
      });
    }
    this.progressBarService.stop();
  }

  async queryLayers(e) {
    const prevMouseState = document.getElementById('mapViewContainer').style.cursor;
    this.progressBarService.start();
    document.getElementById('mapViewContainer').style.cursor = 'wait'; // make pointer loading
    let templates = [];
    const featureLayers = this.loadedLayers.filter((layer) => layer.type === 'feature' || layer.type === 'wfs');
    const geoserverWMSService = this.loadedLayers.filter((layer) => layer.type === 'wms');
    const restOfLayers = this.loadedLayers.filter(
      (layer) => layer.type !== 'feature' && layer.type !== 'wfs' && layer.type !== 'wms'
    );
    if (featureLayers.length > 0) {
      const queryResult = await this.executeSpatialQuery(e, featureLayers);
      templates = templates.concat(queryResult);
    }

    if (geoserverWMSService.length > 0) {
      const queryResult = await this.getFeatureInfo(e, geoserverWMSService);
      queryResult.forEach((result) => {
        templates = templates.concat(result);
      });
    }

    if (restOfLayers.length > 0) {
      templates = templates.concat(await this.executeIdentifyTask(e, restOfLayers));
    }

    this.showPopup(e, templates);
    document.getElementById('mapViewContainer').style.cursor = prevMouseState;
    this.progressBarService.stop();
  }

  async getFeatureInfo(e, layers) {
    const [esriRequest, webMercatorUtils] = await loadModules([
      'esri/request',
      'esri/geometry/support/webMercatorUtils',
    ]);
    const promiseArr = layers.map(async (layer) => {
      const url = layer.url;

      const extent = this.mapView.extent;
      const mapPointWGS84 = webMercatorUtils.webMercatorToGeographic(extent);

      const bbox = [mapPointWGS84.xmin, mapPointWGS84.ymin, mapPointWGS84.xmax, mapPointWGS84.ymax].join(',');

      const params = {
        f: 'json',
        SERVICE: 'WMS',
        VERSION: '1.1.1',
        REQUEST: 'GetFeatureInfo',
        FORMAT: 'image/png',
        TRANSPARENT: true,
        layers: layer.allSublayers.map((sub) => sub.name).join(','),
        query_layers: layer.allSublayers
          .filter((sub) => sub.visible)
          .map((sub) => sub.name)
          .join(','),
        STYLES: '',
        exceptions: 'application/json',
        info_format: 'application/json',
        FEATURE_COUNT: 50,
        X: Math.round(e.x),
        Y: Math.round(e.y),
        SRS: 'EPSG:4326',
        WIDTH: this.mapView.width,
        HEIGHT: this.mapView.height,
        BBOX: bbox,
      };

      const response = await esriRequest(url, {
        responseType: 'json',
        query: params,
      });

      if (response.data.features && response.data.features.length === 0) {
        return [];
      } else {
        return this.convertToEsriPopupFormat(response.data);
      }
    });

    return await Promise.all(promiseArr);
  }

  convertToEsriPopupFormat(geoServerResponse: any) {
    const esriFeatures = geoServerResponse.features?.map((feature: any) => {
      const attributes = {
        ...feature.properties,
        layerName: feature.id.split('.')[0], // extracting layer name from feature id
      };

      const content = Object.entries(attributes)
        .map(([key, value]) => `<b>${key}</b>: ${value}<br>`)
        .join('');

      return {
        attributes,
        popupTemplate: {
          title: attributes.layerName,
          content,
        },
      };
    });

    return esriFeatures || [];
  }

  // execute spatial query
  async executeSpatialQuery(event, layers) {
    let templates = [];
    const layerNames = [];

    if (layers.length === 0) {
      return templates;
    } else {
      const queryResults: Promise<FeatureSet>[] = layers.map(async (layer) => {
        const query = layer.createQuery();
        query.geometry = this.mapView.toMap(event); // the point location of the pointer
        query.distance = 5;
        query.units = 'meters';
        query.spatialRelationship = 'intersects'; // this is the default
        query.returnGeometry = true;
        layerNames.push(layer['dbLayerName']);

        return layer.queryFeatures(query);
      });

      await Promise.all(queryResults)
        .then((results: FeatureSet[]) => {
          // iterate through results and create popup template
          results.forEach((result: FeatureSet, index: number) => {
            // if the feature set is empty, then don't add it to the list
            if (result.features === null || result.features === undefined || result.features.length == 0) {
              return;
            } else {
              templates = [
                ...templates,
                ...result.features.map((feature) => {
                  const featureData = { attributes: feature.attributes };

                  featureData.attributes.layerName = layerNames[index];
                  const keys = Object.keys(featureData.attributes);

                  let content = '';
                  for (let i = 0; i < keys.length; i++) {
                    content += '<b>' + keys[i] + '</b>' + ': ' + featureData.attributes[keys[i]];
                    if (i >= 0) {
                      content += '<br>';
                    }
                  }

                  featureData['popupTemplate'] = {
                    title: featureData.attributes.layerName,
                    content,
                  };

                  return featureData;
                }),
              ];
            }
          });
          return templates;
        })
        .catch((error) => {
          this.logger$.error(error);
        });
      return templates;
    }
  }

  async executeIdentifyTask(event, layers) {
    let templates = [];

    if (layers.length === 0) {
      return templates;
    } else {
      const [Identify, IdentifyParameters, IdentifyResult] = await loadModules([
        'esri/rest/identify',
        'esri/rest/support/IdentifyParameters',
        'esri/rest/support/IdentifyResult',
      ]);

      const identifyTasks = [];

      for (let i = 0; i < layers.length; i++) {
        const params = new IdentifyParameters();
        params.tolerance = 10;
        params.layerOption = 'all';
        params.width = this.mapView.width;
        params.height = this.mapView.height;
        params.mapExtent = this.mapView.extent;
        params.geometry = event.mapPoint;

        // create promise array to find features on all visible layers
        if (layers[i].visible) {
          let visibleLayerIDs = [];
          visibleLayerIDs = this.layers$.getVisibleLayers(layers[i], true, []);
          params.layerIds = visibleLayerIDs;

          const identifyTask = Identify.identify(layers[i].url, params);
          identifyTasks.push(identifyTask);
        }
      }

      await Promise.all(identifyTasks)
        .then((results: (typeof IdentifyResult)[]) => {
          // iterate through results and create popup template
          for (let i = 0; i < results.length; i++) {
            const response = results[i].results;

            templates = [
              ...templates,
              ...response.map((result: typeof IdentifyResult) => {
                const feature = result.feature;
                const layerName = result.layerName;

                feature.attributes.layerName = layerName;
                const keys = Object.keys(feature.attributes);

                let content = '';
                for (let i = 0; i < keys.length; i++) {
                  content += '<b>' + keys[i] + '</b>' + ': ' + feature.attributes[keys[i]];
                  if (i >= 0) {
                    content += '<br>';
                  }
                }

                feature.popupTemplate = {
                  title: layerName,
                  content,
                };

                return feature;
              }),
            ];
          }
          return templates;
        })
        .catch((error) => {
          this.logger$.error(error);
        });
      return templates;
    }
  }

  enableMapControls(enable: boolean) {
    if (!enable) {
      this.dragEvent = this.mapView.on('drag', (event) => {
        event.stopPropagation();
      });
    } else if (this.dragEvent) {
      this.dragEvent.remove();
    }
  }

  get identifyEnabled(): boolean {
    return this._identifyEnabled;
  }
}

@NgModule({
  declarations: [
    EsriMapComponent,
    EsriMapLayersComponent,
    EsriMapLegendComponent,
    EsriMapMeasurementComponent,
    EsriMapBasemapsComponent,
  ],
  imports: [FlexLayoutModule, MaterialModule, BaseEsriMapModule],
  exports: [
    EsriMapComponent,
    EsriMapLayersComponent,
    EsriMapLegendComponent,
    EsriMapMeasurementComponent,
    EsriMapBasemapsComponent,
  ],
})
export class EsriMapModule {}
