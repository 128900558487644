import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import { Subject } from 'rxjs';
import { MatIcon } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { DatabaseLayer, LayerConfig } from '../../services/layer-manager/types/layer.types';

const DEFAULT_DURATION = 300;

@Component({
  selector: 'app-wms-control-slot',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, CdkAccordionModule, MatIcon],
  animations: [
    trigger('collapse', [
      state('true', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state('false', style({ height: '0', visibility: 'hidden' })),
      transition('true => false', animate(DEFAULT_DURATION + 'ms ease-in')),
      transition('false => true', animate(DEFAULT_DURATION + 'ms ease-out')),
    ]),
  ],
  template: `
    <div class="layer-slot sub-layer">
      <div class="left">
        <button class="flex flex-row justify-center items-center bg-transparent border-none outline-none w-fit h-fit">
          <mat-icon
            [ngStyle]="{
              color: isVisible && !hiddenByParent ? '#008AFF' : '#E5E5E5',
              'font-size': '20px',
              'text-align': 'center',
              width: '20px',
              height: '20px'
            }"
            (click)="toggleVisibility()"
            svgIcon="visible" />
        </button>
      </div>
      <div class="right">
        <button
          class="flex flex-row justify-center items-center bg-transparent border-none outline-none w-fit h-fit p-0 uppercase text-center font-rajdhani font-semibold text-headline-6">
          <span>
            {{ layerProps.displayName || '' }}
          </span>
        </button>
        <button
          class="cursor-pointer flex flex-row justify-center items-center bg-transparent border-none outline-none w-fit h-fit p-0 uppercase text-center font-rajdhani font-semibold text-headline-6"
          (click)="toggleLabels()">
          <mat-icon class="size-4 text-lg"
                    [ngStyle]="{color: config?.showLabels ? '#008AFF' : '#E4E4E4'}"
                    svgIcon="label"></mat-icon>
        </button>
      </div>
    </div>
  `,
  styleUrls: ['./wms-control-slot.component.scss'],
})
export class WMSControlSlotComponent implements OnInit, OnChanges, OnDestroy {
  // IO
  @Input() layerProps: DatabaseLayer;
  @Input() parentVisible: IsVisible = true;
  @Output() visibilityChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() configChanged: EventEmitter<void> = new EventEmitter<void>();

  // services
  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  // observables
  private destroy$ = new Subject<void>();
  protected isVisible: IsVisible = true;
  protected myVisibility: IsVisible = true;
  protected config: LayerConfig = {
    showLabels: false,
    isVisible: true,
  };

  protected hiddenByParent = false;

  constructor() {}

  ngOnInit(): void {
    const { isDefaultOn, config } = this.layerProps;

    if (config) {
      this.config = config;
    }

    this.isVisible = this.checkIfVisible();

    this.myVisibility = isDefaultOn === 1;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.parentVisible) {
      this.isVisible = changes.parentVisible.currentValue && this.myVisibility;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  checkIfVisible() {
    return this.layerProps.isDefaultOn === 1 && this.parentVisible;
  }

  toggleVisibility() {
    this.myVisibility = !this.isVisible;
    this.isVisible = !this.isVisible;
    this.layerProps.config.isVisible = this.isVisible;
    this.visibilityChanged.emit(this.isVisible);
  }

  toggleLabels() {
    this.config.showLabels = !this.config.showLabels;
    this.configChanged.emit();
    this.cdr.markForCheck()
  }
}

type IsVisible = boolean;
