import { Component, OnDestroy, OnInit } from '@angular/core';
import { HeaderOptions, RouteOptions } from '../../shared/page-navigation/page-nav-options';
import { UserCategoryEditorService } from '../user-category-editor.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-user-category-editor-list',
  templateUrl: './user-category-editor-list.component.html',
})
export class UserCategoryEditorListComponent implements OnInit, OnDestroy {
  routes: RouteOptions[] = [];
  errorMessage?: string = null;
  header: HeaderOptions = {
    title: "Categories",
  };
  private unsubscribe$ = new Subject<void>();

  constructor(private userCategoryEditorService: UserCategoryEditorService) { }

  ngOnInit(): void {
    this.userCategoryEditorService.tables$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(tables => {
        if (tables && tables.UserCategories) {
          this.setupSideNav(tables.UserCategories);
        } else {
          this.errorMessage = `${tables.Status}: ${tables.message}`;
        }
      });

    this.userCategoryEditorService.queryTables(); // Trigger the initial data fetch
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private setupSideNav(UserCategoryIDs) {
    this.routes = UserCategoryIDs.map((userCategory) => ({
      path: userCategory.UserCategoryID,
      label: userCategory.Title,
    }));
  }
}
