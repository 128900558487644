import { Injectable } from '@angular/core';
import { ApiService, UtilocateApiRequest } from '../../core/api/baseapi.service';
import { apiKeys } from '../../../ENDPOINTS';
import { environment } from '../../../../environments/environment';
import Layer from 'ol/layer/Layer';

@Injectable({
  providedIn: 'root',
})
export class GeoserverService {
  constructor(private apiService: ApiService) {}

  async getCredentials() {
    const request: UtilocateApiRequest = {
      API_TYPE: 'PUT',
      API_KEY: apiKeys.u2.getMapLayers,
      API_BODY: { query: { auth: { geoserver: { group: 0 } } } },
    };
    const response = await this.apiService.invokeUtilocateApi(request);
    return response.body.result;
  }

  makeRequest(url: string, options: RequestInit) {
    if (environment.localhost) {
      if (url.includes('.cloud')) {
        options.headers['X-Forwarded-For'] = 'live';
      } else if (url.includes('.wtf')) {
        options.headers['X-Forwarded-For'] = 'dev';
      }
      url = 'http://localhost:3000/geoserver' + url.split('geoserver')[1];
    }
    return fetch(url, options);
  }

  public async getCapabilities(layer: Layer) {
    try {
      const { username, password, layerURL } = layer.getProperties();
      if (!layerURL) {
        return null;
      }
      const capabilitiesURL = new URL(layerURL);
      const queryParams = {
        service: 'WMS',
        version: '1.3.0',
        request: 'GetCapabilities',
      };
      Object.entries(queryParams).forEach(([key, value]) => capabilitiesURL.searchParams.set(key, value));
      let credential: string;
      let requestHeaders = {};
      
      if (username && password) {
        credential = 'Basic ' + btoa(`${username}:${password}`);
        requestHeaders = {
          Authorization: credential,
        }
      }

      const capabilitiesResponse = await this.makeRequest(capabilitiesURL.toString(), {
        mode: 'cors',
        headers:requestHeaders,
      });

      if (capabilitiesResponse.status !== 200) {
        throw new Error('Failed to fetch capabilities');
      }
      return capabilitiesResponse.text();
    } catch (error) {
      console.error('Failed to fetch capabilities');
      return null;
    }
  }

  public getBasicAuthHeaderFromLayer(layer: Layer): string {
    const { username, password } = layer.getProperties();
    if (!username || !password) {
      return undefined;
    }
    return `Basic ${btoa(`${username}:${password}`)}`;
  }
}
