import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {CanActivateAuthGuard} from './modules/core/guards/can-activate-auth.guard';
import {ConfirmDeactivateGuard} from './modules/core/guards/confirm-deactivate.guard';
import {Error404Component} from './modules/error404/error404/error404.component';
import {AppNavComponent} from './modules/app-nav/app-nav/app-nav.component';
import {LandingComponent} from './modules/landing/landing/landing.component';
import {RoutingPresetPageComponent} from './modules/routing-preset/routing-preset/routing-preset-page/routing-preset-page.component';
import {RoutingPresetComponent} from './modules/routing-preset/routing-preset/routing-preset.component';
import {SecurityTroubleshootComponent} from './modules/security-troubleshoot/security-troubleshoot/security-troubleshoot.component';
import {UserManagerComponent} from './modules/user-manager/user-manager/user-manager.component';
import {DocumentViewerComponent} from './modules/document-viewer/document-viewer/document-viewer.component';
import {localStorageKeys} from './LOCAL_STORAGE';
import {CreateTicketPageComponent} from './modules/create-ticket/create-ticket.component';
import {SchedulerPageComponent} from './modules/scheduler/scheduler.component';
import {EsriMapViewerComponent} from './modules/shared/esri-map/esri-map-viewer/esri-map-viewer.component';
import {EsriMapDigsiteComponent} from './modules/shared/esri-map/esri-map-digsite/esri-map-digsite.component';
import {EditTablesComponent} from './modules/edit-tables/edit-tables/edit-tables.component';
import {DigsitePageComponent} from './modules/digsite/digsite/digsite.component';
import {LoginComponent} from './modules/shared/login/login/login.component';
import {EditTableComponent} from './modules/edit-tables/edit-table/edit-table.component';
import {CreatePageComponent} from './modules/create/create.component';
import {TicketDetailsComponent} from './modules/shared/ticket-details/ticket-details/ticket-details.component';
import {U2_USER_CATEGORY_ID} from './modules/core/services/user/user';
import {FailedEmailsComponent} from './modules/system-maintenance/failed-emails/failed-emails.component';
import {ThirdPartyJobsComponent} from './modules/system-maintenance/failed-third-party-jobs/failed-third-party-jobs.component';
import {SystemMaintenanceComponent} from './modules/system-maintenance/system-maintenance-page.component';
import {SettingID} from './modules/core/services/user/setting';
import {RateListComponent} from './modules/rates/rate-list/rate-list.component';
import {RateComponent} from './modules/rates/rate/rate.component';
import {FieldsideComponent} from './routes/fieldside/components/fieldside.component';
import {FieldsideTicketListComponent} from './routes/fieldside/components/fieldside-ticket-list/fieldside-ticket-list.component';
import {SelfAssignTicketListComponent} from './routes/fieldside/components/self-assign-ticket-list/self-assign-ticket-list.component';
import {FieldsideMapComponent} from './routes/fieldside/components/ticket-summary/ticket/(ID)/map/fieldside-map.component';
import {Ticket} from './modules/shared/ticket/ticket.component';
import {PunchClockComponent} from './modules/shared/ticket/punch-clock/punch-clock.component';
import {RuleBuilderTypesComponent} from './modules/automated-engine/rule-builder-types/rule-builder-types.component';
import {RuleBuilderListComponent} from './modules/automated-engine/rule-builder-list/rule-builder-list.component';
import {VersionInformationComponent} from './modules/version-information/version-information/version-information.component';
import {DemoComponent} from './shared/demo/demo.component';
import {HomeWorkspaceComponent} from './routes/home/home-workspace.component';
import {InvoiceReportComponent} from './modules/invoicing-manager/invoice-report/invoice-report.component';
import {InvoicingManagerComponent} from './modules/invoicing-manager/invoicing-manager/invoicing-manager.component';
import {environment} from '../environments/environment';
import {Failed360Component} from './modules/system-maintenance/failed-360/failed-360.component';
import {AccountSettingsItemComponent} from './modules/general-settings/account-settings-item/account-settings-item.component';
import {AccountSettingsPageComponent} from './modules/general-settings/account-settings-page/account-settings-page.component';
import {UserCategoryEditorListComponent} from './modules/user-category-editor/user-category-editor-list/user-category-editor-list.component';
import {UserCategoryEditorListItemComponent} from './modules/user-category-editor/user-category-editor-list-item/user-category-editor-list-item.component';
import {ReportGeneratorTypesComponent} from './modules/report-generator/report-generator-types/report-generator-types.component';
import {ReportGeneratorItemComponent} from './modules/report-generator/report-generator-item/report-generator-item.component';
import { AuthorizedUserComponent } from './modules/shared/login/authorized-user/authorized-user.component';

const routes: Routes = [
  {path: '*', component: LandingComponent},
  {path: '', component: LandingComponent},
  {
    path: 'app',
    component: AppNavComponent,
    canActivate: [CanActivateAuthGuard],
    children: [
      {
        path: 'demo',
        component: DemoComponent,
      },
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        component: HomeWorkspaceComponent,
        canActivate: [CanActivateAuthGuard],
        data: {
          SettingID: SettingID.HOME_WORKSPACE,
        },
      },
      {
        path: 'ticket-summary',
        component: FieldsideTicketListComponent,
        canActivate: [CanActivateAuthGuard],
        data: {
          VISIBLE_URL_KEYS: [],
          SettingID: SettingID.TICKET_SUMMARY,
        },
      },
      {
        path: 'punch-clock',
        component: PunchClockComponent,
        canActivate: [CanActivateAuthGuard],
        data: {
          VISIBLE_URL_KEYS: [],
          SettingID: SettingID.SHOW_PUNCH_CLOCK,
        },
      },
      {
        path: 'ticket-summary/ticket/:aid',
        component: Ticket,
        children: [{path: 'map', component: FieldsideMapComponent}],
      },
      {
        path: 'create',
        component: CreatePageComponent,
        canActivate: [CanActivateAuthGuard],
      },
      {
        path: ':app/create-ticket',
        component: CreateTicketPageComponent,
        canActivate: [CanActivateAuthGuard],
        data: {
          VISIBLE_URL_KEYS: [],
          SettingID: SettingID.CREATE_TICKET,
        },
      },
      {
        path: 'create-ticket',
        component: CreateTicketPageComponent,
        canActivate: [CanActivateAuthGuard],
        data: {
          UserCategoryIDs: [U2_USER_CATEGORY_ID.SystemAdmin, U2_USER_CATEGORY_ID.Manager],
          VISIBLE_URL_KEYS: [],
          SettingID: SettingID.CREATE_TICKET,
        },
      },
      {
        path: 'digsite',
        component: DigsitePageComponent,
        canActivate: [CanActivateAuthGuard],
      },
      {
        path: 'settings',
        component: AccountSettingsPageComponent,
        canActivate: [CanActivateAuthGuard],
        children: [
          {
            path: '',
            redirectTo: 'list',
            pathMatch: 'full',
          },
          {
            path: 'list',
            component: AccountSettingsItemComponent,
          },
          {
            path: 'rule-builder',
            component: RuleBuilderTypesComponent,
            canActivate: [CanActivateAuthGuard],
            children: [
              {
                path: '',
                redirectTo: '0',
                pathMatch: 'full',
              },
              {
                path: ':ruleTypeID',
                component: RuleBuilderListComponent,
              },
            ]
          },
          {
            path: 'version-information',
            component: VersionInformationComponent,
            canActivate: [CanActivateAuthGuard],
          },
          {
            path: 'user-categories',
            component: UserCategoryEditorListComponent,
            canActivate: [CanActivateAuthGuard],
            children: [
              {
                path: '',
                redirectTo: '1',
                pathMatch: 'full',
              },
              {
                path: ':userCategoryID',
                component: UserCategoryEditorListItemComponent,
              },
            ]
          },
          {
            path: 'table-editor',
            component: EditTablesComponent,
            canActivate: [CanActivateAuthGuard],
            children: [
              {
                path: ':tableID',
                component: EditTableComponent,
                canActivate: [CanActivateAuthGuard],
              },
            ],
            data: {
              VISIBLE_URL_KEYS: [],
              SettingID: SettingID.GENERAL_SETTINGS,
            },
          },
          {
            path: 'rate-editor',
            component: RateListComponent,
            canActivate: [CanActivateAuthGuard],
            children: [
              {
                path: '',
                redirectTo: '1',
                pathMatch: 'full',
              },
              {
                path: ':rateTypeID',
                component: RateComponent,
                canActivate: [CanActivateAuthGuard],
              },
            ],
            data: {
              VISIBLE_URL_KEYS: [],
              SettingID: SettingID.RATES,
            },
          },
          {
            path: 'user-manager',
            component: UserManagerComponent,
            canActivate: [CanActivateAuthGuard],
            data: {
              VISIBLE_URL_KEYS: [],
              SettingID: SettingID.USER_MANAGER,
            },
          },
          {
            path: 'invoicing-manager',
            component: InvoicingManagerComponent,
            canActivate: [CanActivateAuthGuard],
            children: [
              {
                path: ':reportID',
                component: InvoiceReportComponent,
                canActivate: [CanActivateAuthGuard],
              },
            ],
            data: {
              VISIBLE_URL_KEYS: [],
              SettingID: SettingID.INVOICING_MANAGER,
            },
          },
          {
            path: 'routing-manager',
            component: RoutingPresetPageComponent,
            canActivate: [CanActivateAuthGuard],
            children: [
              {
                path: ':algorithmid',
                component: RoutingPresetComponent,
                // canActivate: [CanActivateAuthGuard],
                canDeactivate: [ConfirmDeactivateGuard],
              },
            ],
            data: {
              VISIBLE_URL_KEYS: [],
              SettingID: SettingID.ROUTING_PRESETS,
            },
          },
        ]
      },
      {
        path: 'scheduler',
        component: SchedulerPageComponent,
        canActivate: [CanActivateAuthGuard],
        data: {
          UserCategoryIDs: [U2_USER_CATEGORY_ID.SystemAdmin, U2_USER_CATEGORY_ID.Manager],
          VISIBLE_URL_KEYS: [],
          SettingID: SettingID.SCHEDULER,
        },
      },
      {
        path: 'security-troubleshoot',
        component: SecurityTroubleshootComponent,
      },
      {
        path: 'reports',
        component: ReportGeneratorTypesComponent,
        canActivate: [CanActivateAuthGuard],
        children: [
          {
            path: ':reportID',
            component: ReportGeneratorItemComponent,
            canActivate: [CanActivateAuthGuard],
          },
        ],
        data: {
          VISIBLE_URL_KEYS: [],
          SettingID: SettingID.REPORTS_TAB_ACCESS,
        },
      },
      {
        path: 'system-maintenance',
        component: SystemMaintenanceComponent,
        canActivate: [CanActivateAuthGuard],
        children: [
          {
            path: 'emails',
            component: FailedEmailsComponent,
            data: {
              SettingID: SettingID.SYSTEM_MAINTENANCE,
            },
          },
          {
            path: 'third-party-jobs',
            component: ThirdPartyJobsComponent,
            canActivate: [CanActivateAuthGuard],
            data: {
              SettingID: SettingID.SYSTEM_MAINTENANCE,
              SubSettingID: '2',
            },
          },
          {
            path: '360',
            component: Failed360Component,
            canActivate: [CanActivateAuthGuard],
            data: {
              SettingID: SettingID.SYSTEM_MAINTENANCE
            },
          },
        ],
        data: {
          VISIBLE_URL_KEYS: [],
          SettingID: SettingID.SYSTEM_MAINTENANCE,
        },
      },
      {
        path: 'no-auth',
        component: Error404Component,
      },
      {
        path: 'general-settings',
        redirectTo: 'settings/table-editor',
        pathMatch: 'full',
      },
      {
        path: 'table',
        redirectTo: 'settings/table-editor',
        pathMatch: 'full',
      },
      {
        path: 'routing-preset',
        redirectTo: 'settings/routing-manager',
        pathMatch: 'full',
      },
      {
        path: 'routing-manager',
        redirectTo: 'settings/routing-manager',
        pathMatch: 'full',
      },
      {
        path: 'routing',
        redirectTo: 'settings/routing-manager',
        pathMatch: 'full',
      },
      {
        path: 'user-manager',
        redirectTo: 'settings/user-manager',
        pathMatch: 'full',
      },
      {
        path: 'users',
        redirectTo: 'settings/user-manager',
        pathMatch: 'full',
      },
      {
        path: 'rule-builder',
        redirectTo: 'settings/rule-builder',
        pathMatch: 'full',
      },
      {
        path: 'rules',
        redirectTo: 'settings/rule-builder',
        pathMatch: 'full',
      },
      {
        path: 'rates',
        redirectTo: 'settings/rate-editor',
        pathMatch: 'full',
      },
      {
        path: 'invoicing-manager',
        redirectTo: 'settings/invoicing-manager',
        pathMatch: 'full',
      },
      {
        path: 'info',
        redirectTo: 'settings/version-information',
        pathMatch: 'full',
      },
      {
        path: 'about',
        redirectTo: 'settings/version-information',
        pathMatch: 'full',
      },
      {
        path: 'version-information',
        redirectTo: 'settings/version-information',
        pathMatch: 'full',
      },
      {
        path: 'user-categories',
        redirectTo: 'app/settings/user-categories',
        pathMatch: 'full',
      },
      {
        path: 'user-category-editor',
        redirectTo: 'settings/user-categories',
        pathMatch: 'full',
      },
      {
        path: 'user-categories',
        redirectTo: 'settings/user-categories',
        pathMatch: 'full',
      },
      {
        path: 'user-category',
        redirectTo: 'settings/user-categories',
        pathMatch: 'full',
      },
      {
        path: '**',
        component: Error404Component,
      },
    ].filter((route) => {
      if (environment.production) {
        return route.path !== 'demo';
      } else {
        return true;
      }
    }) as Routes,
  },
  {
    path: 'ticket-details',
    component: TicketDetailsComponent,
    canActivate: [CanActivateAuthGuard],
    canDeactivate: [ConfirmDeactivateGuard],
    data: {
      VISIBLE_URL_KEYS: [localStorageKeys.VISIBLE_URL_KEYS.requestnumber],
      UserCategoryIDs: [U2_USER_CATEGORY_ID.SystemAdmin, U2_USER_CATEGORY_ID.Manager, U2_USER_CATEGORY_ID.LeadHand],
    },
  },
  {
    path: 'ticket-details',
    component: TicketDetailsComponent,
    canActivate: [CanActivateAuthGuard],
    canDeactivate: [ConfirmDeactivateGuard],
    data: {
      VISIBLE_URL_KEYS: [localStorageKeys.VISIBLE_URL_KEYS.requestnumber],
      UserCategoryIDs: [U2_USER_CATEGORY_ID.SystemAdmin, U2_USER_CATEGORY_ID.Manager, U2_USER_CATEGORY_ID.LeadHand],
    },
  },
  {
    path: 'fieldside',
    component: FieldsideComponent,
    canActivate: [CanActivateAuthGuard],
    children: [
      {
        path: 'ticket-summary',
        component: FieldsideTicketListComponent,
      },
      {
        path: 'ticket-summary/ticket/:aid',
        component: Ticket,
        children: [{path: 'map', component: FieldsideMapComponent}],
      },

      {
        path: 'self-assign',
        component: SelfAssignTicketListComponent,
      },
      {
        path: 'punch-clock',
        component: PunchClockComponent,
      },
      {
        path: 'version-information',
        component: VersionInformationComponent,
      },
    ],
  },
  {
    path: 'document-viewer',
    component: DocumentViewerComponent,
    canActivate: [CanActivateAuthGuard],
    data: {
      VISIBLE_URL_KEYS: [
        localStorageKeys.VISIBLE_URL_KEYS.documenttoangularid,
        localStorageKeys.VISIBLE_URL_KEYS.documentid,
        localStorageKeys.URL_KEYS.assignmentid,
      ],
    },
  },
  {
    path: 'esri-viewer',
    component: EsriMapViewerComponent,
    canActivate: [CanActivateAuthGuard],
    data: {
      SettingID: SettingID.ESRI_MAP_LINK_TO_U4_AVAILABLE,
    },
  },
  {
    path: ':app/esri-viewer',
    component: EsriMapViewerComponent,
    canActivate: [CanActivateAuthGuard],
    data: {
      SettingID: SettingID.ESRI_MAP_LINK_TO_U4_AVAILABLE,
    },
  },

  {
    path: 'esri-digsite',
    component: EsriMapDigsiteComponent,
    canActivate: [CanActivateAuthGuard],
  },
  {
    path: ':app/esri-digsite',
    component: EsriMapDigsiteComponent,
    canActivate: [CanActivateAuthGuard],
  },
  {
    path: 'security-troubleshoot',
    component: SecurityTroubleshootComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'authorized-user',
    component: AuthorizedUserComponent,
  },
  {
    path: 'no-auth',
    component: LandingComponent,
  },
  {
    path: 'badurl',
    component: Error404Component,
  },
  // redirects
  {
    path: 'settings',
    redirectTo: 'app/settings/list',
    pathMatch: 'full',
  },
  {
    path: 'create',
    redirectTo: 'app/create',
    pathMatch: 'full',
  },
  {
    path: 'create-ticket',
    redirectTo: 'app/create-ticket',
    pathMatch: 'full',
  },
  {
    path: ':app/create-ticket',
    redirectTo: 'app/:app/create-ticket',
    pathMatch: 'full',
  },
  {
    path: 'digsite',
    redirectTo: 'app/digsite',
    pathMatch: 'full',
  },
  {
    path: 'scheduler',
    redirectTo: 'app/scheduler',
    pathMatch: 'full',
  },

  //general settings START
  {
    path: 'general-settings',
    redirectTo: 'app/settings/table-editor',
    pathMatch: 'full',
  },
  {
    path: 'table',
    redirectTo: 'app/settings/table-editor',
    pathMatch: 'full',
  },
  {
    path: 'routing-preset',
    redirectTo: 'app/settings/routing-manager',
    pathMatch: 'full',
  },
  {
    path: 'routing-manager',
    redirectTo: 'app/settings/routing-manager',
    pathMatch: 'full',
  },
  {
    path: 'routing',
    redirectTo: 'app/settings/routing-manager',
    pathMatch: 'full',
  },
  {
    path: 'user-manager',
    redirectTo: 'app/settings/user-manager',
    pathMatch: 'full',
  },
  {
    path: 'users',
    redirectTo: 'app/settings/user-manager',
    pathMatch: 'full',
  },
  {
    path: 'rule-builder',
    redirectTo: 'app/settings/rule-builder',
    pathMatch: 'full',
  },
  {
    path: 'rules',
    redirectTo: 'app/settings/rule-builder',
    pathMatch: 'full',
  },
  {
    path: 'rates',
    redirectTo: 'app/settings/rate-editor',
    pathMatch: 'full',
  },
  {
    path: 'invoicing-manager',
    redirectTo: 'app/settings/invoicing-manager',
    pathMatch: 'full',
  },
  {
    path: 'info',
    redirectTo: 'app/settings/version-information',
    pathMatch: 'full',
  },
  {
    path: 'about',
    redirectTo: 'app/settings/version-information',
    pathMatch: 'full',
  },
  {
    path: 'version-information',
    redirectTo: 'app/settings/version-information',
    pathMatch: 'full',
  },
  {
    path: 'user-categories',
    redirectTo: 'app/settings/user-categories',
    pathMatch: 'full',
  },
  {
    path: 'user-category-editor',
    redirectTo: 'app/settings/user-categories',
    pathMatch: 'full',
  },
  {
    path: 'user-categories',
    redirectTo: 'app/settings/user-categories',
    pathMatch: 'full',
  },
  {
    path: 'user-category',
    redirectTo: 'app/settings/user-categories',
    pathMatch: 'full',
  },
  //general settings END

  {
    path: 'system-maintenance',
    redirectTo: 'app/system-maintenance',
    pathMatch: 'full',
  },

  // end of redirects
  {path: '**', component: Error404Component},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
