import { inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, firstValueFrom, merge, Observable, Subject } from 'rxjs';
import { combineLatestWith, filter, takeUntil, tap } from 'rxjs/operators';
import { SnackbarService } from '../../shared/snackbar/snackbar.service';
import { SnackbarType } from '../../shared/snackbar/snackbar/snackbar';
import { TransactionWriterService } from './transaction-writer.service';
import { MapInteractionService } from './map-interaction.service';
import { Collection, Feature, Map } from 'ol';
import { CollectionFlattener, FeatureOrCollection } from '../classes/collection-flattener';
import { DrawingShapesFeature, ShapeType } from '../utilities/types';
import { LoggerService } from '../../core/services/logger/logger.service';
import { RedoLength, UndoLength, UndoRedo } from '../classes/undo-redo';
import { FeatureChangeType, MapFeatureChange } from '../classes/map-feature-change';
import { ColourFormatOption, StyleToolbox } from '../classes/style-toolbox';
import { Circle, GeometryCollection, LineString, MultiLineString, Point, Polygon } from 'ol/geom';
import { Ticket, TicketService } from '../../shared/ticket/ticket.service';
import { Style } from 'ol/style';
import { LayerManagerService } from './layer-manager/layer-manager.service';
import { OpenLayersUtilities } from '../classes/open-layers-utilities';
import CircleStyle from 'ol/style/Circle';
import { OpenLayersService } from './open-layers.service';
import VectorSource from 'ol/source/Vector';
import { DrawingSettingsService, MapSettings } from '../drawing-settings.service';
import VectorLayer from 'ol/layer/Vector';
import { LocationService, Position } from 'src/app/shared/services/location/location.service';
import { fromLonLat } from 'ol/proj';
import { Coordinate } from 'ol/coordinate';
import { DrawingService, DrawingTypes } from '../drawing.service';
import Layer from 'ol/layer/Layer';
import { GeoServerLayerBuilder } from './layer-manager/layer-builders/geoserver-layer.builder';

@Injectable({
  providedIn: 'root',
})
export class MapFeatureService implements OnDestroy {
  // services
  private logger = inject(LoggerService);
  private drawingService = inject(DrawingService);
  private ticketService: TicketService = inject(TicketService);
  private mapService: OpenLayersService = inject(OpenLayersService);
  private locationService: LocationService = inject(LocationService);
  private snackbarService: SnackbarService = inject(SnackbarService);
  private layerManager: LayerManagerService = inject(LayerManagerService);
  private interactionService = inject(MapInteractionService);
  private settingsService: DrawingSettingsService = inject(DrawingSettingsService);
  private transactionService: TransactionWriterService = inject(TransactionWriterService);
  private geoserverLayerBuilder = inject(GeoServerLayerBuilder);

  // observables
  private destroy$: Subject<void> = new Subject<void>();
  private ticketSubject$: BehaviorSubject<Record<string, any> | null> = new BehaviorSubject<unknown | null>(null);

  // members
  private changeStack: UndoRedo;
  private flattener = new CollectionFlattener();
  private ticket: Partial<Ticket> | null = null;
  private selectedLayer: Layer | null = null;
  private mapRef: Map;

  private pins: Record<string, Feature<Point>> = {
    ticket: undefined,
    userLocation: undefined,
  };

  constructor() {
    this.changeStack = new UndoRedo();
    this.init();
  }

  init() {
    this.drawingService.drawingType$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.changeStack.rollback();
    });

    this.layerManager.miscLayer$
      .pipe(combineLatestWith(this.settingsService.mapSettings$), takeUntil(this.destroy$))
      .subscribe(([layer, settings]: [VectorLayer<VectorSource>, MapSettings]) => {
        if (layer && settings) {
          const { pinTicketLocation, pinUserLocation } = settings;
          if (pinTicketLocation) {
            firstValueFrom(this.ticketService.ticket).then((ticket) => {
              const [lat, lon] = [Number(ticket?.Latitude), Number(ticket?.Longitude)] as Position;
              if (lat && lon) {
                this.buildPin('ticket', fromLonLat([lon, lat]));
              }
            });
          }
          if (pinUserLocation) {
            this.locationService
              .updateUserLocation()
              .then(([lat, lon]) => {
                this.buildPin('userLocation', fromLonLat([lon, lat]));
              })
              .catch(() => {
                // do nothing
              });
          }
        }
      });

    this.mapService.map$.pipe(takeUntil(this.destroy$)).subscribe((map) => {
      this.mapRef = map;
    });
    this.ticketService.ticket.pipe(takeUntil(this.destroy$)).subscribe((ticket) => {
      this.ticket = ticket;
    });
    this.interactionService.featureChanges
      .pipe(
        tap((change) => {
          if (change.changeType !== FeatureChangeType.deleted) {
            const flattenedFeatures = this.flattener.flattenFeature(change.feature);
            flattenedFeatures.map((feature) => {
              this.updateProps(feature);
            });
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((change: MapFeatureChange<Collection<Feature>>) => this.changeStack.addChange(change));

    this.layerManager.selectedLayer$
      .pipe(
        filter((x) => x !== null),
        takeUntil(this.destroy$)
      )
      .subscribe((layer) => {
        this.selectedLayer = layer;
      });

    this.geoserverLayerBuilder.checkChanges = this.checkChanges.bind(this);
  }

  public checkChanges(): MapFeatureChange<FeatureOrCollection>[] {
    return this.changeStack.reportChanges().undoStack;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.ticketSubject$.complete();
  }

  private buildPin(name: string, pos: Coordinate) {
    if (this.pins[name]) {
      this.pins[name].getGeometry().setCoordinates(pos);
      return this.pins[name];
    } else {
      this.pins[name] = new Feature<Point>({
        geometry: new Point(pos),
      });
    }
  }

  async saveMap() {
    if (this.selectedLayer?.getProperties()['isLocateAreaLayer']) {
      if ((this.selectedLayer.getSource() as VectorSource).getFeatures().length > 1) {
        this.snackbarService.openSnackbar(
          'Locate Area layer can only contain one feature.\nPlease Delete the current area and save before adding a new one.',
          SnackbarType.error,
          'Error'
        );
        return;
      }
    }
    try {
      this.interactionService.endInteraction();
      if (this.selectedLayer === null && this.drawingService.drawingType === DrawingTypes.map) {
        throw new Error('No layer selected');
      }
      const toSave = this.changeStack.empty();
      await this.transactionService.writeMapFeatures(this.selectedLayer, toSave);
      this.layerManager.refreshLayers();
    } catch (error) {
      console.log(error);
      this.snackbarService.openSnackbar('save failed', SnackbarType.error, 'error');
    }
  }

  applyStylesToFeatures(features: Feature[], style: Style) {
    features.forEach((feature) => {
      feature.setStyle(style);
    });
  }

  updateProps(feature: Feature): void {
    const newProps: Partial<DrawingShapesFeature> = {};
    const toolbox = new StyleToolbox();
    const olUtil = new OpenLayersUtilities();
    const geom = feature.getGeometry();
    let hasText = false;
    newProps.date_updated = new Date(Date.now()).toISOString();
    try {
      const shapeType = feature.get('shape_type_id');
      if (feature.get('date_created') === undefined) {
        newProps.date_created = new Date(Date.now()).toISOString();
      } else {
        newProps.date_created = feature.get('date_created');
      }

      if (this.ticket) {
        newProps.assignment_id = this.ticket.AssignmentID;
        newProps.request_number = this.ticket.RequestNumber;
      }

      if (this.selectedLayer) {
        const { UtilityID, isLocateAreaLayer } = this.selectedLayer.getProperties();
        if (UtilityID) {
          newProps.utility_id = UtilityID;
        }
        if (isLocateAreaLayer) {
          newProps.isLocateArea = isLocateAreaLayer;
        }
      }

      if (feature.get('shape_type_id') === ShapeType.Label) {
        feature.setProperties(newProps);
        return;
      }

      if (feature.get('shape_type_id') === ShapeType.Circle && geom instanceof Circle) {
        newProps.radius = geom.getRadius();
      }

      const style = toolbox.getStyleFromStyleLike(feature, this.mapRef?.getView().getResolution() ?? 1);
      if (style) {
        const text = style.getText();
        const stroke = style.getStroke();
        const fill = style.getFill();
        const image = style.getImage();

        if (text) {
          hasText = true;
          const textContent = text.getText() as string;
          const textFill = text.getFill();
          const textColour = Array.isArray(textFill) ? textFill : textFill.getColor();
          const textBackgroundFill = text.getBackgroundFill();
          const textRotation = text.getRotation();
          if (textColour) {
            newProps.text_colour = toolbox.convertColour(textColour, ColourFormatOption.hex) as string;
            newProps.fill_opacity = textColour[3];
          }
          if (textBackgroundFill) {
            const backgroundFillColour = Array.isArray(textBackgroundFill)
              ? textBackgroundFill
              : textBackgroundFill.getColor();
            newProps.fill_colour = toolbox.convertColour(backgroundFillColour, ColourFormatOption.hex) as string;
          } else {
            newProps.fill_colour = null;
          }
          if (textContent) {
            if (Array.isArray(textContent)) {
              newProps.text_label = textContent.join('|');
            } else {
              newProps.text_label = textContent;
            }
          }
          if (textRotation) {
            newProps.image_rotation = textRotation;
          }
        } else {
          newProps.text_label = null;
        }

        if (image && !(image instanceof CircleStyle)) {
          const imageRotation = image.getRotation();
          if (imageRotation) {
            newProps.image_rotation = imageRotation;
          }
          const { height, width } = olUtil.measureImage(feature.get('image_source'));
          newProps.image_height = height;
          newProps.image_width = width;
        } else {
          newProps.image_height = null;
          newProps.image_width = null;
          newProps.image_rotation = null;
        }

        if (shapeType !== ShapeType.Label) {
          if (stroke) {
            const strokeColor = stroke.getColor();
            if (strokeColor) {
              newProps.stroke_colour = toolbox.convertColour(strokeColor, ColourFormatOption.hex) as string;
              newProps.fill_opacity = Array.isArray(strokeColor) ? strokeColor[3] : 1;
            } else {
              newProps.stroke_colour = null;
            }
            const strokeWidth = stroke.getWidth();
            if (strokeWidth) {
              newProps.stroke_width = strokeWidth;
            } else {
              newProps.stroke_width = null;
            }
            const strokeDashArray = stroke.getLineDash();
            if (strokeDashArray) {
              newProps.stroke_dasharray = strokeDashArray.join(',');
            } else {
              newProps.stroke_dasharray = null;
            }
          }

          if (fill) {
            const fillColor = fill.getColor();
            newProps.fill_colour = toolbox.convertColour(fillColor, ColourFormatOption.hex) as string;
            newProps.fill_opacity = Array.isArray(fillColor) ? fillColor[3] : 1;
          } else {
            newProps.fill_colour = null;
            newProps.fill_opacity = null;
          }
        }

        if (feature.get('shape_type_id')) {
          newProps.shape_type_id = feature.get('shape_type_id');
        } else {
          // set shape_type_id base on geometry type
          const geometry = feature.getGeometry();
          if (geometry && !(geometry instanceof GeometryCollection)) {
            if (geometry instanceof Point) {
              newProps.shape_type_id = 1;
            } else if (geometry instanceof LineString) {
              newProps.shape_type_id = 2;
            } else if (geometry instanceof MultiLineString) {
              if (hasText) {
                newProps.shape_type_id = 9;
              } else {
                newProps.shape_type_id = 3;
              }
            } else if (geometry instanceof Polygon) {
              newProps.shape_type_id = 4;
            } else if (geometry instanceof Circle) {
              newProps.shape_type_id = 5;
              newProps.radius = geometry.getRadius();
            } else {
              newProps.shape_type_id = 0;
            }
          }
        }
      }
    } catch (error) {
      this.logger.error(error);
    } finally {
      feature.setProperties(newProps);
    }
  }

  addUndoTrigger(trigger: Observable<void>, destroySignal: Observable<any>) {
    trigger
      .pipe(
        tap(() => this.interactionService.endInteraction()),
        takeUntil(merge(this.destroy$, destroySignal))
      )
      .subscribe(() => this.changeStack.undo());
  }

  addRedoTrigger(trigger: Observable<void>, destroySignal: Observable<any>) {
    trigger
      .pipe(
        tap(() => this.interactionService.endInteraction()),
        takeUntil(merge(this.destroy$, destroySignal))
      )
      .subscribe(() => this.changeStack.redo());
  }

  getUndoRedoStackLengths(): Observable<[UndoLength, RedoLength]> {
    return this.changeStack.stackLengths;
  }

  nukeChanges() {
    this.changeStack.nukeChanges();
  }

  clearSession() {
    this.changeStack.rollback();
    this.interactionService.endInteraction();
  }

  prepFeaturesForWrite(features: Feature[]): Feature[] {
    return features.map((x) => {
      let feat: Feature;
      const geometry = x.getGeometry();
      if (geometry instanceof Circle) {
        feat = x.clone();
        feat.setGeometry(new Point(geometry.getCenter()));
      } else if (x.get('shape_type_id') === ShapeType.Image) {
        feat = this.transactionService.prepareImage(x);
      } else {
        feat = x;
      }
      feat.setId(crypto.randomUUID());
      return feat;
    });
  }

  get hasUnsavedChanges(): boolean {
    return this.changeStack.hasChanges();
  }

  public getTicketSubject() {
    return this.ticketSubject$;
  }
}
