import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-vertical-collapsible',
  standalone: true,
  imports: [MatIconModule],
  template: `
    <button
      class="flex flex-row justify-between items-center w-full appearance-none bg-transparent cursor-pointer border-none p-0"
      (click)="toggle()">
      <span class="w-full flex justify-start items-center">
        <ng-content select="[title]"></ng-content>
      </span>
      @if (showArrow) {
        <span class="appearance-none bg-transparent cursor-pointer border-none p-0">
          <mat-icon svgIcon="drop_down"></mat-icon>
        </span>
      }
    </button>
    <div class="{{ isOpen ? '' : 'h-0 overflow-hidden ' }} w-full">
      <ng-content select="[content]"></ng-content>
    </div>
  `,
})
export class VerticalCollapsibleComponent {
  @Input() isOpen = true;
  @Input() showArrow = true;
  @Output() isOpenChange = new EventEmitter<boolean>();

  constructor() {}

  toggle() {
    this.isOpen = !this.isOpen;
    this.isOpenChange.emit(this.isOpen);
  }
}
