import { inject, Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { Feature } from 'ol';
import {
  Circle,
  Geometry,
  GeometryCollection,
  LinearRing,
  LineString,
  MultiLineString,
  MultiPoint,
  MultiPolygon,
  Point,
  Polygon,
} from 'ol/geom';
import { WFS } from 'ol/format';
import { WriteTransactionOptions } from 'ol/format/WFS';
import { CollectionFlattener } from '../classes/collection-flattener';
import { FeatureChangeType, MapFeatureChange } from '../classes/map-feature-change';
import { ShapeType } from '../utilities/types';
import Style from 'ol/style/Style';
import { DrawingService } from '../drawing.service';
import { OpenLayersService } from './open-layers.service';
import { SnackbarType } from '../../shared/snackbar/snackbar/snackbar';
import { SnackbarService } from '../../shared/snackbar/snackbar.service';
import { DrawingManagerService } from './drawing-manager.service';
import { ProjectionLike } from 'ol/proj';
import Layer from 'ol/layer/Layer';
import { GeoserverService } from './geoserver.service';
import { DatabaseLayer } from './layer-manager/types/layer.types';

@Injectable({
  providedIn: 'root',
})
export class TransactionWriterService {
  // services
  private drawingService = inject(DrawingService);
  private drawingManager = inject(DrawingManagerService);
  private snackbarService: SnackbarService = inject(SnackbarService);
  private openLayersService = inject(OpenLayersService);
  private geoserverService = inject(GeoserverService);

  // members
  private collectionFlattener = new CollectionFlattener();

  public async writeMapFeatures(layer: Layer, changes: MapFeatureChange<Feature>[]) {
    try {
      if (layer.get('isEditable') === false) {
        return throwError(() => new Error('Layer is not editable'));
      }
      const formatted = this.formatChanges(changes).map((feature) => this.prepareFeature(feature));
      if (formatted.length <= 0) {
        return throwError(() => new Error('No changes to save'));
      }
      const [toInsert, toUpdate, toDelete] = this.partitionChanges(formatted);
      const utilityLayerID = layer.get('utilityID') || -1;
      const isLocateArea = layer.get('subLayerID') === 1;
      if (isLocateArea) {
        this.setLocateAreaProps(formatted);
      }
      // do a final property cleanup
      if (utilityLayerID !== -1) {
        formatted.forEach((feature: Feature) => {
          feature.set('utility_id', utilityLayerID);
        });
      }
      return this.performWrite(layer, toInsert, toUpdate, toDelete);
    } catch (e) {
      return e;
    }
  }

  private async performWrite(
    layer: Layer,
    toInsert: Array<Feature>,
    toUpdate: Array<Feature>,
    toDelete: Array<Feature>
  ) {
    const { username, password, layerURL, subLayerName } = layer.getProperties() as DatabaseLayer;
    const [namespace, featureType] = subLayerName.split(':');

    const wfs = new WFS({});
    const options: WriteTransactionOptions = {
      featurePrefix: namespace,
      nativeElements: [],
      featureNS: namespace,
      featureType,
      srsName: 'EPSG:4326',
      version: '1.1.0',
    };

    const node = wfs.writeTransaction(toInsert, toUpdate, toDelete, options);
    const xs = new XMLSerializer();
    const payload = xs.serializeToString(node);
    const url = new URL(layerURL);
    const response = await this.geoserverService.makeRequest(url.toString(), {
      method: 'POST',
      body: payload,
      headers: {
        'Content-Type': 'application/xml',
        Authorization: 'Basic ' + btoa(`${username}:${password}`),
      },
    });

    // if we don't have a response, throw an error
    if (!response || response.status !== 200 || !response.headers.get('content-type')?.includes('xml')) {
      throw new Error('save failed');
    }
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(await response.text(), 'text/xml');
    const topNode = xmlDoc.childNodes[0];
    // try to parse an error message from the response
    if (topNode.nodeName === 'ows:ExceptionReport') {
      const exception = Array.from(topNode.childNodes).reduce((acc, curr) => {
        if (curr.nodeName === 'ows:Exception') {
          return curr;
        }
        return acc;
      }, undefined);
      const exceptionText =
        Array.from(exception.childNodes).reduce((acc, curr) => {
          if (curr.nodeName === 'ows:ExceptionText') {
            return curr;
          }
          return acc;
        }, undefined)?.textContent ?? 'save failed';
      // read-only is a common error
      // typically caused by permissions issues on the layer in Geoserver
      // also caused by the 'Locator' role not having permissions in the db
      this.snackbarService.openSnackbar(exceptionText, SnackbarType.error, 'Error');
    } else {
      // no problems, save was successful🤌
      this.snackbarService.openSnackbar('saved drawing', SnackbarType.success, 'success');
      return 'ok';
    }
  }

  private formatChanges(changes: Array<MapFeatureChange<Feature>>): Array<Feature> {
    const acc: Array<Feature> = [];
    for (let i = changes.length - 1; i >= 0; i--) {
      this.collectionFlattener.flattenFeature(changes[i].feature).forEach((feature) => {
        if (!acc.find((x) => x.getId() === feature.getId())) {
          feature.set('changeType', changes[i].changeType);
          acc.push(feature);
        }
      });
    }
    return acc.map((feature) => {
      if ([ShapeType.SvgSymbol, ShapeType.Image].includes(feature.get('shape_type_id'))) {
        return this.prepareImage(feature);
      }
      return feature;
    });
  }

  /**
   * This function takes a feature and prepares it for writing to the server.
   * It clones the geometry and converts the coordinates to lat/lon.
   * ID gets set only if it does not start with 'new'.
   * @param feature
   * @param sourceProjection
   */
  prepareFeature(feature: Feature, sourceProjection: ProjectionLike = 'EPSG:3857'): Feature {
    const dest = 'EPSG:4326';
    const feat = feature;
    const props = feat.getProperties();
    const clone = feat.clone();
    const id = feat.getId();
    const geom = feat.getGeometry().clone();
    const transformed = geom.transform(sourceProjection, dest);
    const converted = this.convertToLatLon(transformed);
    if (typeof id === 'string' && !(id as string).startsWith('new')) {
      clone.setId(id);
    } else if (typeof id === 'number') {
      clone.setId(id.toString());
    }
    clone.setProperties(props);
    clone.setGeometry(converted);
    return clone;
  }

  prepareImage(feature: Feature) {
    const poly = (feature.getGeometry() as GeometryCollection)
      .getGeometriesArray()
      .find((geom) => geom instanceof Polygon);
    if (poly) {
      const clone = feature.clone();
      clone.setId(feature.getId());
      clone.setStyle(new Style());
      clone.setGeometry(poly);
      return clone;
    } else {
      throw new Error('image map feature is broken');
    }
  }

  convertToLatLon(geometry: Geometry): Geometry {
    let geom = geometry.clone();
    if (geom instanceof Point) {
      const coords = geom.getCoordinates();
      geom.setCoordinates([coords[1], coords[0]]);
    } else if (
      geom instanceof MultiPolygon ||
      geom instanceof LineString ||
      geom instanceof MultiLineString ||
      geom instanceof LinearRing ||
      geom instanceof MultiPoint ||
      geom instanceof Polygon
    ) {
      const coords = geom.getFlatCoordinates();
      geom.setFlatCoordinates(
        geom.getLayout(),
        coords
          .reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index / 2);
            if (!resultArray[chunkIndex]) {
              resultArray[chunkIndex] = []; // start a new chunk
            }
            resultArray[chunkIndex].push(item);
            return resultArray;
          }, [])
          .map((x) => [x[1], x[0]])
          .flatMap((x) => x)
      );
    } else if (geom instanceof Circle) {
      geom = this.convertToLatLon(new Point(geom.getCenter()));
    } else if (geom instanceof GeometryCollection) {
      const geomArr = geom.getGeometriesArrayRecursive();
      geomArr.forEach((x) => this.convertToLatLon(x));
    }
    return geom;
  }

  private partitionChanges(changes: Array<Feature>): [Array<Feature>, Array<Feature>, Array<Feature>] {
    const [toInsert, rest] = changes
      .filter((x) => {
        if (x.getId() === undefined) {
          const changeType = x.get('changeType');
          return !(changeType === FeatureChangeType.deleted);
        } else {
          return true;
        }
      })
      .reduce(
        ([pass, fail], feature) => {
          if (feature.get('changeType') === FeatureChangeType.added || feature.getId() === undefined) {
            feature.unset('changeType');
            return [[...pass, feature], fail];
          } else {
            return [pass, [...fail, feature]];
          }
        },
        [[], []]
      );
    const [toUpdate, toDelete] = rest.reduce(
      ([pass, fail], feature) => {
        if (feature.get('changeType') === FeatureChangeType.updated) {
          feature.unset('changeType');
          return [[...pass, feature], fail];
        } else {
          feature.unset('changeType');
          return [pass, [...fail, feature]];
        }
      },
      [[], []]
    );
    return [toInsert, toUpdate, toDelete];
  }

  /**
   * changes feature properties in place
   */
  setLocateAreaProps(features: Array<Feature>) {
    const allowed = [
      'locate_area_id',
      'request_number',
      'assignment_id',
      'user_id',
      'date_created',
      'date_updated',
      'geometry',
      'stroke_colour',
      'stroke_width',
      'stroke_dasharray',
      'fill_colour',
      'fill_opacity',
    ];
    features.forEach((feature) => {
      Object.keys(feature.getProperties()).forEach((key) => {
        if (!allowed.includes(key)) {
          feature.unset(key);
        }
      });
    });
  }
}
