import {
  Component,
  ViewEncapsulation,
  ElementRef,
  Input,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { ModalService } from "./modal.service";

@Component({
  selector: "app-base-modal",
  templateUrl: "base-modal.component.html",
  styleUrls: ["base-modal.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class BaseModalComponent implements OnInit, OnDestroy {
  @Input() id: string;
  @Input() maxwidth: string = "100%";
  @Input() maxheight: string = "100%";
  @Input() height = "100%";
  element: any;

  constructor(private modalService: ModalService, private el: ElementRef) {
    this.element = el.nativeElement;
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   if (changes && changes.id && changes.id.currentValue.length > 0) {
  //     document.body.appendChild(this.element);
  //     this.modalService.add(this);

  //     window.addEventListener("message", messageEvent => {
  //       if (messageEvent.data === 'Close Modal') {
  //         this.close();
  //       }
  //     });
  //   }
  // }

  ngOnInit(): void {
    // console.log(new Date());
    if (!this.id) {
      return;
    }
    this.modalService.add(this);
  }

  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }

  open(): void {
    this.element.style.display = "block";
    document.body.classList.add("competers-modal-open");
  }

  close(): void {
    this.element.style.display = "none";
    document.body.classList.remove("competers-modal-open");
  }
}
