import { Injectable } from '@angular/core';
import { BaseLayerBuilder } from './base-layer.builder';
import { LayerQueryFilters } from '../../../utilities/types';
import Layer from 'ol/layer/Layer';
import { DatabaseLayer, ServiceType } from '../types/layer.types';
import TileLayer from 'ol/layer/Tile';
import { ImageWMS, TileWMS } from 'ol/source';
import ImageLayer from 'ol/layer/Image';

@Injectable({
  providedIn: 'root',
})
export class GenericLayerBuilder extends BaseLayerBuilder {
  async build(layer: DatabaseLayer, filters: Partial<LayerQueryFilters>): Promise<Layer> {
    switch (layer.serviceType) {
      case ServiceType.TileLayer:
        return this.buildTileLayer(layer);
      case ServiceType.ImageLayer:
        return this.buildImageLayer(layer);
      default:
        return null;
    }
  }

  private buildTileLayer(layer: DatabaseLayer) {
    return new TileLayer({
      source: new TileWMS({
        url: layer.layerURL,
        params: {
          request: 'GetMap',
          layers: `${layer.layerServiceName}`,
          TILED: true,
          version: '1.1.0',
          format: 'image/png',
        },
        serverType: 'geoserver',
        transition: 0,
      }),
      zIndex: layer.index,
    });
  }

  private buildImageLayer(layer: DatabaseLayer) {
    return new ImageLayer({
      source: new ImageWMS({
        url: layer.layerURL,
        ratio: 1,
        serverType: 'geoserver',
      }),
      zIndex: layer.index,
    });
  }
}
