import { inject, Injectable } from '@angular/core';
import { BaseLayerBuilder } from './layer-builders/base-layer.builder';
import { GeoServerLayerBuilder } from './layer-builders/geoserver-layer.builder';
import { EsriLayerBuilder } from './layer-builders/esri-layer.builder';
import { ServiceType } from './types/layer.types';
import { GenericLayerBuilder } from './layer-builders/generic-layer.builder';

@Injectable({
  providedIn: 'root',
})
export class LayerBuilderFactory {
  private esriBuilder = inject(EsriLayerBuilder);
  private geoServerBuilder = inject(GeoServerLayerBuilder);
  private genericBuilder = inject(GenericLayerBuilder);

  constructor() {}

  getBuilder(serviceType: ServiceType): BaseLayerBuilder {
    switch (serviceType) {
      case ServiceType.EsriMapServer:
      case ServiceType.EsriMapServerSubLayer:
      case ServiceType.EsriFeatureServer:
      case ServiceType.EsriFeatureServerSubLayer:
        return this.esriBuilder;
      case ServiceType.WMSLayerGeoServer:
      case ServiceType.WMSLayerGeoServerSubLayer:
      case ServiceType.GeoserverWFSLayer:
        return this.geoServerBuilder;
      default:
        return this.genericBuilder;
        throw new Error(`No builder found for service type: ${serviceType}`);
    }
  }
}
