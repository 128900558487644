<div
  #olay
  appClickStopPropagation
  (click)="handleBackdropClick()"
  class="fixed z-[60] w-screen h-screen top-0 left-0 bg-accent bg-opacity-20"></div>
<div
  #container
  class="flex justify-start items-start absolute top-0 right-0 pb-6 pr-6 {{
    (ticketSearchService.advancedSearchIsOpen$ | async) ? 'h-full w-[1415px] z-[70]' : 'h-0 w-0 overflow-hidden'
  }} ">
  <div class="size-full justify-start items-start bg-white overflow-hidden rounded-md">

    <div class="flex flex-col justify-start items-start max-h-full h-full w-full pt-[29px] overflow-hidden">
      <div>
      <span class="font-rajdhani text-headline-6 uppercase font-semibold pl-[24px]">
        @if (ticketSearchService.editingSearch$ | async) {
          edit search
          @if (ticketSearchService.prefillSearch$ | async) {
            : "{{ (ticketSearchService.prefillSearch$ | async).SearchName }}"
          }
        } @else {
          advanced search
        }
      </span>
      </div>
      <div class="flex flex-row h-full max-h-full w-full justify-start items-start overflow-hidden pt-6">
        <!--            left -->
        <div
          class="flex flex-col gap-6 justify-start items-start px-[24px] pb-[83px] border-r-2 border-r-gray-300 h-full max-h-full w-fit">
          <!--              top-->
          <div>
            <div class="h-9 w-[300px]">
              <app-competers-search-bar
                class="box-border w-full h-full"
                placeHolderText="Search Filters"
                (searchValueChange)="onSearchValueChange($event)" />
            </div>
          </div>
          <!--              bottom -->
          <div class="flex flex-col justify-start items-start h-full w-[312px] overflow-y-auto scroll-auto">
            @if ((_searchValue$ | async) === '') {
              @for (category of _filterCategories$ | async; track $index) {
                <app-vertical-collapsible [showArrow]="false" [isOpen]="$index === 0" class="w-full">
                  <div title class="flex justify-end items-center px-[9.67px] w-full">
                   <span class="text-lg font-rajdhani font-semibold uppercase leading-[30px]">
                     {{ category['filterCategoryName'] ?? 'err' }}
                   </span>
                    <div
                      class="flex justify-end items-center flex-grow appearance-none bg-transparent cursor-pointer border-none p-0">
                      <mat-icon svgIcon="drop_down"></mat-icon>
                    </div>
                  </div>
                  <div content class="flex flex-col justify-start items-start gap-0.5 w-full ">
                    @for (filter of category['filters'] ?? []; track filter['filterID']) {
                      <button
                        class="box-border flex flex-row justify-start items-center border-0 border-none rounded-md w-full h-[18px] px-[9.67px] hover:cursor-pointer"
                        [style.background-color]="_selectedFilters.isSelected(filter) ? 'rgba(206, 223, 237, 0.2)' : 'rgba(255, 255, 255, 0)'"
                        (click)="_selectedFilters.toggle(filter)">
                      <span
                        class="text-md font-rajdhani font-semibold text-[rgba(112,112,112,1)] uppercase leading-[24px]">
                        {{ filter['visibleName'] }}
                      </span>
                        <div class="flex justify-end flex-grow items-center">
                          <button
                            class="flex justify-end items-center appearance-none border-none bg-transparent p-1 cursor-pointer hover:bg-grey-200 hover:bg-opacity-30">
                            @if (_selectedFilters.isSelected(filter)) {
                              <mat-icon class="text-success" style="width: 16.67px; height: 16.67px"
                                        svgIcon="yes-filled" />
                            } @else {
                              <mat-icon svgIcon="toggle_off" style="width: 16.67px; height: 16.67px" />
                            }
                          </button>
                        </div>
                      </button>
                    }
                  </div>
                </app-vertical-collapsible>
              }
            } @else {
              <div content class="flex flex-col justify-start items-start w-full">
                @for (filter of (_filteredOptions$ | async) ?? []; track filter) {
                  <button
                    class="flex flex-row justify-start items-start border-none bg-transparent w-full hover:cursor-pointer"
                    (click)="_selectedFilters.toggle(filter)">
                  <span class="text-md font-rajdhani font-semibold text-[#707070] uppercase leading-[24px]">
                    {{ filter['visibleName'] }}
                  </span>
                    <div class="flex justify-end flex-grow items-center">
                      <button
                        class="flex justify-end items-center appearance-none border-none bg-transparent p-1 cursor-pointer hover:bg-grey-200 hover:bg-opacity-30">
                        @if (_selectedFilters.isSelected(filter)) {
                          <mat-icon class="text-success" style="width: 20px; height: 20px" svgIcon="yes-filled" />
                        } @else {
                          <mat-icon svgIcon="toggle_off" style="width: 20px; height: 20px" />
                        }
                      </button>
                    </div>
                  </button>
                }
              </div>
            }
          </div>
        </div>
        <!--            right -->
        <div [formGroup]="searchFormGroup"
             class="flex flex-col justify-between items-start size-full border-solid border-0 border-l-1 border-l-[rgba(209,211,212,1)] ">
          <!--              top -->
          <div
            class="flex flex-col flex-grow gap-0 justify-start items-start overflow-y-auto scroll-smooth w-auto">
            @for (filter of _selectedFilters.selected; track filter['filterID']) {
              @if (getControl(filter, 'value')) {
                <div
                  class="group flex flex-row justify-start items-center w-auto">
                  <div
                    class="flex flex-row pl-6 py-3 justify-between items-center border-solid border-[rgba(209,211,212,1)] border-0 border-b-1 group-first:border-t-1">
                    <app-competers-checkbox class="mr-3" [checkStyle]="'invert'" [formControl]="getControl(filter, 'excluded')" />
                    <div class="flex justify-start items-center w-24 mr-3">
                      <span class="font-rajdhani font-semibold text-md uppercase">
                        {{ filter['visibleName'] }}
                      </span>
                    </div>
                    @if ([2, 6, 10].includes(filter['dataTypeID'])) {
                      <app-competers-input
                        class="w-[336px]"
                        [title]="filter['visibleName']"
                        [showLabel]="false"
                        [formControl]="getControl(filter, 'value')"
                        type="{{ filter['dataTypeID'] === 6 ? 'number' : 'text' }}"></app-competers-input>
                    } @else if ([3, 4].includes(filter['dataTypeID'])) {
                      <app-searchable-dropdown
                        class="w-[336px]"
                        [inlined]="true"
                        [showLabel]="false"
                        [multiple]="filter['dataTypeID'] === 4"
                        [formControl]="getControl(filter, 'value')"
                        [options]="filter['options'] | dbOptionToSelectionOption"
                        [title]="filter['visibleName']"></app-searchable-dropdown>
                    } @else if (filter['dataTypeID'] === 5) {
                      <app-competers-date-range-picker
                        class="w-[336px]"
                        [inlined]="true"
                        [showLabel]="false"
                        [title]="filter['visibleName']"
                        [formControl]="getControl(filter, 'value')"></app-competers-date-range-picker>
                    } @else if ([1, 7].includes(filter['dataTypeID'])) {
                      <div class="w-0 h-0 overflow-hidden">
                        <input type="checkbox" [formControl]="getControl(filter, 'value')" />
                      </div>
                      <div class="w-[336px]"></div>
                    }
                  </div>
                  <span class="flex justify-center items-center">
                      <button
                        (click)="_selectedFilters.deselect(filter)"
                        class="flex justify-end items-center appearance-none border-none bg-transparent p-1 cursor-pointer hover:bg-grey-200 hover:bg-opacity-30">
                        <mat-icon class="text-warn" style="width: 20px; height: 20px" svgIcon="close_circle" />
                      </button>
                    </span>
                </div>
              }
            }
          </div>
          <!--              bottom -->
          <div class="flex flex-row gap-6 justify-end items-center p-6 w-full">
            @if (ticketSearchService.editingSearch$ | async) {
              <button
                (click)="cancelEdit()"
                class="flex justify-center items-center w-[132px] h-[35px] appearance-none border-none bg-transparent p-0 cursor-pointer hover:bg-warn hover:text-white hover:rounded text-warn font-rajdhani font-semibold uppercase">
                cancel
              </button>
            } @else {
              <button
                (click)="clearAdvancedSearch()"
                class="flex justify-center items-center w-[132px] h-[35px] appearance-none border-none bg-transparent p-0 cursor-pointer hover:bg-warn hover:text-white hover:rounded text-warn font-rajdhani font-semibold uppercase">
                clear
              </button>
            }
            <button
              (click)="handleSearch()"
              class="flex justify-center items-center w-[132px] h-[35px] appearance-none rounded border-solid border-2 border-primary bg-primary p-0 cursor-pointer hover:bg-gray-500 hover:border-gray-500 text-white font-rajdhani font-semibold uppercase">
              search
            </button>
            <button
              (click)="initializeSaveAndSearch()"
              class="box-border flex justify-center items-center w-[132px] h-[35px] appearance-none rounded border-primary border-solid border-2 bg-transparent p-0 cursor-pointer hover:bg-grey-500 hover:bg-primary hover:text-white text-gray-500 font-rajdhani font-semibold uppercase">
              save & search
            </button>
            <button
              (click)="initializeSave()"
              class="flex justify-center items-center appearance-none border-none bg-transparent p-1 cursor-pointer hover:bg-grey-500 hover:bg-opacity-60">
              <mat-icon class="text-black" style="width: 24px; height: 24px" svgIcon="save" />
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<ng-template #save>
  <div class="flex flex-col justify-start items-start gap-4 p-4">
    <h2 class="text-headline-6 font-rajdhani font-semibold uppercase">Save Search</h2>
    <form class="flex flex-col justify-start items-start gap-4" [formGroup]="saveFormGroup">
      <div class="flex flex-row w-full gap-3 justify-between">
        <app-competers-input
          class="w-[432px]"
          [title]="'Name'"
          formControlName="name"
          [type]="InputType.text" />
      </div>
      <div class="flex flex-row gap-4 justify-end items-center w-full">
        <button
          (click)="myDialog.close('cancel')"
          class="flex justify-center items-center w-[132px] h-[35px] appearance-none border-none bg-transparent p-0 cursor-pointer hover:bg-warn hover:text-white hover:rounded text-warn font-rajdhani font-semibold uppercase">
          Cancel
        </button>
        @if ((ticketSearchService.editingSearch$ | async) && (ticketSearchService.prefillSearch$ | async)?.ClientID >= 0) {
          <button
            (click)="handleUpdateClick()"
            class="flex justify-center items-center w-[132px] h-[35px] appearance-none rounded border-solid border-2 border-primary bg-amber-200 p-0 cursor-pointer hover:bg-gray-500 hove:text-white hover:border-gray-500 text-accent font-rajdhani font-semibold uppercase">
            Update
          </button>
          <button
            (click)="handleSaveClick()"
            class="flex justify-center items-center w-[132px] h-[35px] appearance-none rounded border-solid border-2 border-primary bg-primary p-0 cursor-pointer hover:bg-gray-500 hover:border-gray-500 text-white font-rajdhani font-semibold uppercase">
            Save New
          </button>
        } @else {
          <button
            (click)="handleSaveClick()"
            class="flex justify-center items-center w-[132px] h-[35px] appearance-none rounded border-solid border-2 border-primary bg-primary p-0 cursor-pointer hover:bg-gray-500 hover:border-gray-500 text-white font-rajdhani font-semibold uppercase">
            Save
          </button>
        }
      </div>
    </form>
  </div>
</ng-template>
