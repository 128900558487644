import { inject, Injectable } from '@angular/core';
import { GeoserverService } from '../../geoserver.service';
import Layer from 'ol/layer/Layer';
import Tile, { LoadFunction } from 'ol/Tile';
import TileState from 'ol/TileState.js';
import { DatabaseLayer } from '../types/layer.types';
import { GetMapParams } from '../types/tile.types';

@Injectable({
  providedIn: 'root',
})
export class GeoServerTileLoaderService {
  private geoserverService: GeoserverService = inject(GeoserverService);

  constructor() {}

  createBasicLoader(layer: Layer): LoadFunction {
    let layerNames: string[] = [];
    this.getGeoserverWMSLayerNames(layer).then((names) => {
      layerNames = names;
      layer.set(
        'subLayers',
        names.map((name) => ({ subLayerName: name, config: { isVisible: true } }))
      );
    });

    return async (img, src) => {
      const url = new URL(src);
      if (!layerNames || layerNames.length === 0) {
        // @ts-ignore
        img.getImage().src = '';
        img.setState(TileState.EMPTY);
        return;
      }

      url.searchParams.set('LAYERS', layerNames.join(','));

      img.setState(TileState.LOADING);
      try {
        const headers = {};
        const auth = await this.getBasicAuth(layer);
        if (auth !== '') {
          headers['Authorization'] = auth;
        }
        const response = await this.geoserverService.makeRequest(url.toString(), {
          headers,
        });

        await this.handleTileResponse(response, img);
      } catch (e) {
        console.error('Failed to load tile:', e);
        img.setState(TileState.ERROR);
      }
    };
  }

  createSubLayerLoader(layer: Layer): LoadFunction {
    const geoserverService = this.geoserverService;
    return async (img, src) => {
      const url = new URL(src);
      url.searchParams.set('LAYERS', GeoServerTileLoaderService.getVisibleLayers(layer).join(','));

      img.setState(TileState.LOADING);
      try {
        const headers = {
          'Content-Type': 'application/xml',
        };
        const auth = await this.getBasicAuth(layer);
        if (auth !== '') {
          headers['Authorization'] = auth;
        }
        const response = await geoserverService.makeRequest(url.toString(), {
          headers,
        });

        await this.handleTileResponse(response, img);
      } catch (e) {
        console.error('Failed to load tile:', e);
        img.setState(TileState.ERROR);
      }
    };
  }

  private static parseUrlParams(url: URL): Record<string, string> {
    const params = Object.fromEntries(url.searchParams);
    Object.keys(params).forEach((key) => url.searchParams.delete(key));
    return params;
  }

  private static createGetMapParams(params: Record<string, string>, layer: Layer): GetMapParams {
    return {
      bbox: params.BBOX,
      width: parseInt(params.WIDTH),
      height: parseInt(params.HEIGHT),
      layers: GeoServerTileLoaderService.getVisibleLayers(layer),
      styles: params.STYLES ? params.STYLES.split(',') : [],
      crs: params.CRS,
      format: params.FORMAT,
      version: params.VERSION,
      transparent: true,
      tiled: true,
    };
  }

  private static getVisibleLayers(layer: Layer): string[] {
    return layer
      .get('subLayers')
      .filter((subLayer: DatabaseLayer) => subLayer.config?.isVisible)
      .map((subLayer: DatabaseLayer) => {
        const parts = subLayer.subLayerName.split(':');
        if (parts.length === 2) {
          return parts[1];
        }
        return subLayer.subLayerName;
      });
  }

  private async getBasicAuth(layer: Layer): Promise<string> {
    const { username, password } = layer.getProperties();
    if (!username || !password) {
      return '';
    }
    return 'Basic ' + btoa(`${username}:${password}`);
  }

  private async handleTileResponse(response: Response, img: Tile): Promise<void> {
    if (!response.ok) {
      // @ts-ignore
      img.getImage().src = '';
      img.setState(TileState.ERROR);
      return;
    }

    const blob = await response.blob();
    // @ts-ignore
    img.getImage().src = URL.createObjectURL(blob);
    img.setState(TileState.LOADED);
  }

  static createGetMapXML(params: GetMapParams): string {
    const [minX, minY, maxX, maxY] = params.bbox.split(',').map(Number);

    const doc = document.implementation.createDocument(null, 'GetMap', null);
    const root = doc.documentElement;

    root.setAttribute('xmlns:ogc', 'http://www.opengis.net/ows');
    root.setAttribute('xmlns:gml', 'http://www.opengis.net/gml');
    root.setAttribute('version', params.version);
    root.setAttribute('service', 'WMS');

    const sld = doc.createElement('StyledLayerDescriptor');
    sld.setAttribute('version', '1.0.0');

    params.layers.forEach((layerName, index) => {
      const namedLayer = doc.createElement('NamedLayer');
      const name = doc.createElement('Name');
      name.textContent = layerName;
      namedLayer.appendChild(name);

      const style = params.styles?.[index];
      if (style) {
        const namedStyle = doc.createElement('NamedStyle');
        const styleName = doc.createElement('Name');
        styleName.textContent = style;
        namedStyle.appendChild(styleName);
        namedLayer.appendChild(namedStyle);
      }

      sld.appendChild(namedLayer);
    });
    root.appendChild(sld);

    const bbox = this.createBoundingBox(doc, params.crs, minX, minY, maxX, maxY);
    root.appendChild(bbox);

    const output = this.createOutput(doc, params);
    root.appendChild(output);

    return new XMLSerializer().serializeToString(doc);
  }

  private static createBoundingBox(
    doc: Document,
    crs: string,
    minX: number,
    minY: number,
    maxX: number,
    maxY: number
  ): Element {
    const bbox = doc.createElement('BoundingBox');
    bbox.setAttribute('srsName', `http://www.opengis.net/gml/srs/epsg.xml#${crs.split(':')[1]}`);

    const minCoord = doc.createElement('gml:coord');
    const minXElem = doc.createElement('gml:X');
    minXElem.textContent = minX.toString();
    const minYElem = doc.createElement('gml:Y');
    minYElem.textContent = minY.toString();
    minCoord.appendChild(minXElem);
    minCoord.appendChild(minYElem);

    const maxCoord = doc.createElement('gml:coord');
    const maxXElem = doc.createElement('gml:X');
    maxXElem.textContent = maxX.toString();
    const maxYElem = doc.createElement('gml:Y');
    maxYElem.textContent = maxY.toString();
    maxCoord.appendChild(maxXElem);
    maxCoord.appendChild(maxYElem);

    bbox.appendChild(minCoord);
    bbox.appendChild(maxCoord);
    return bbox;
  }

  private static createOutput(doc: Document, params: GetMapParams): Element {
    const output = doc.createElement('Output');

    const format = doc.createElement('Format');
    format.textContent = params.format;
    output.appendChild(format);

    const size = doc.createElement('Size');
    const width = doc.createElement('Width');
    width.textContent = params.width.toString();
    const height = doc.createElement('Height');
    height.textContent = params.height.toString();
    size.appendChild(width);
    size.appendChild(height);
    output.appendChild(size);

    const transparent = doc.createElement('Transparent');
    transparent.textContent = params.transparent ? 'true' : 'false';
    output.appendChild(transparent);

    const tiled = doc.createElement('Tiled');
    tiled.textContent = params.tiled ? 'true' : 'false';
    output.appendChild(tiled);

    return output;
  }

  private parseXML(text: string) {
    const parser = new DOMParser();
    return parser.parseFromString(text, 'text/xml');
  }

  private async getGeoserverWMSLayerNames(layer: Layer) {
    const text = await this.geoserverService.getCapabilities(layer);
    if (!text) {
      return null;
    }
    const xmlDoc = this.parseXML(text);
    return Array.from(xmlDoc.getElementsByTagName('Layer'))
      .map((layerNode) => layerNode.getElementsByTagName('Name')[0].textContent)
      .filter((layerName) => layerName !== '');
  }
}
