import { NgModule } from '@angular/core';
import { MaterialModule } from '../material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { Ticket } from './ticket.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DigsiteModule } from '../../digsite/digsite.module';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { WebcamModule } from 'ngx-webcam';
import { FloatingActionMenuComponent } from '../fab/floating-action-menu/floating-action-menu.component';
import { EsriMapSimpleDrawingModule } from '../esri-map/esri-map-set/esri-map-simple-drawing/esri-map-simple-drawing.component';
import { GoogleMapsSetModule } from '../google-map/google-maps-set/google-maps-set.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfirmationModalComponent } from './modals/generic-confirmation-modal/confirmation-modal.component';
import { DatetimeSelectionModalComponent } from './modals/datetime-selection-modal/datetime-modal.component';
import { EditTimeRangeModalComponent } from './modals/edit-timerange/edit-timerange.component';
import { CameraModalComponent } from './modals/camera-modal/camera-modal.component';
import { CameraGalleryModalComponent } from './modals/gallery-modal/gallery-modal.component';
import { U4GoogleMapsDrawingModule } from './google-maps/google-maps-drawing.components';
import { SplitCamelCasePipe } from './pipes/split-camel-case.pipe';
import { DocumentTypeIDDescPipe } from './pipes/documentid-desc.pipe';
import { TicketCompletionsComponent } from './ticket-completions/ticket-completions.component';
import { TicketDrawingComponent } from './ticket-drawing/ticket-drawing.component';
import { TicketAutologListItemComponent } from './ticket-autologs/ticket-autologs-item/ticket-autologs-item.component';
import { TicketAutologsComponent } from './ticket-autologs/ticket-autologs.component';
import { TicketDocumentListItemComponent } from './ticket-documents/ticket-documents-item/ticket-documents-item.component';
import { TicketDocumentsComponent } from './ticket-documents/ticket-documents.component';
import { TicketTagsModule } from '../ticket-tags/ticket-tags.module';
import { LightboxModule } from 'ng-gallery/lightbox';
import { CallModalComponent } from './modals/call-modal/call-modal.component';
import { EmailModalComponent } from './modals/email-modal/email-modal.component';
import { MapPreviewModalComponent } from './modals/map-preview-modal/map-preview-modal.component';
import { TicketMapViewerComponent } from './ticket-map-viewer/ticket-map-viewer.component';
import { StorageModalComponent } from './modals/storage-modal/storage-modal.component';
import { TicketDetailsComponent } from './ticket-details/ticket-details.component';
import { TicketSyncModalComponent } from './modals/ticket-sync-modal/ticket-sync-modal.component';
import { UnitModalComponent } from './modals/unit-modal/unit-modal.component';
import { PunchClockComponent } from './punch-clock/punch-clock.component';
import { PunchClockTimerComponent } from './punch-clock/punch-clock-timer/punch-clock-timer.component';
import { PunchClockTimesheetComponent } from './punch-clock/punch-clock-timesheet/punch-clock-timesheet.component';
import { PunchClockTimesheetItemComponent } from './punch-clock/punch-clock-timesheet/punch-clock-timesheet-item/punch-clock-timesheet-item.component';
import { CreateAuditModalComponent } from './modals/create-audit-modal/create-audit-modal.component';
import { FormInputTemplateComponent } from './form-template-inputs/form-input-template.component';
import { FilterAutologPipe } from './ticket-autologs/filter-autolog.pipe';
import { TicketModalComponent } from './modals/ticket-modal/ticket-modal.component';
import { FloatingTabSelectComponent } from 'src/app/shared/components/misc/selection/floating-tab-select/floating-tab-select.component';
import { TicketDocumentsGalleryComponent } from './ticket-documents/ticket-documents-gallery/ticket-documents-gallery.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DrawingMapComponent } from '../../drawing-module/drawing-map/drawing-map.component';
import { DrawingComponent } from '../../drawing-module/drawing/drawing.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { CompetersCheckboxComponent } from '../../../shared/components/inputs/competers-checkbox/competers-checkbox.component';
import { ClickStopPropagationDirective } from '../../core/directives/click-stop-propagation.directive';
import { DocumentTypePickerModalComponent } from './modals/document-type-picker-modal/document-type-picker-modal.component';
import { ParseIntPipe } from '../../../shared/pipes/parse-int.pipe';
import { CompetersInputComponent } from '../../../shared/components/inputs/competers-input/competers-input.component';
import { HideTabGroupHeaderDirective } from '../../../shared/directives/hide-tab-group-header/hide-tab-group-header.directive';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [
    Ticket,
    TicketDetailsComponent,
    TicketDocumentsComponent,
    TicketDocumentListItemComponent,
    TicketAutologsComponent,
    TicketAutologListItemComponent,
    TicketDrawingComponent,
    TicketCompletionsComponent,
    TicketDocumentsGalleryComponent,
    // TicketTagsComponent,
    DocumentTypeIDDescPipe,
    ConfirmationModalComponent,
    DatetimeSelectionModalComponent,
    CallModalComponent,
    EditTimeRangeModalComponent,
    CameraModalComponent,
    CameraGalleryModalComponent,
    EmailModalComponent,
    UnitModalComponent,
    MapPreviewModalComponent,
    TicketMapViewerComponent,
    StorageModalComponent,
    TicketSyncModalComponent,
    PunchClockComponent,
    PunchClockTimerComponent,
    PunchClockTimesheetComponent,
    PunchClockTimesheetItemComponent,
    CreateAuditModalComponent,
    FilterAutologPipe,
    TicketModalComponent,
    DocumentTypePickerModalComponent,
  ],
  exports: [Ticket, FormInputTemplateComponent, TicketModalComponent],
  imports: [
    MaterialModule,
    FormsModule,
    LightboxModule,
    U4GoogleMapsDrawingModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    DigsiteModule,
    NgxMaterialTimepickerModule,
    WebcamModule,
    FloatingTabSelectComponent,
    FloatingActionMenuComponent,
    EsriMapSimpleDrawingModule,
    GoogleMapsSetModule,
    BrowserAnimationsModule,
    TicketTagsModule,
    FormInputTemplateComponent,
    MatDialogModule,
    DrawingMapComponent,
    DrawingComponent,
    ScrollingModule,
    CommonModule,
    CompetersCheckboxComponent,
    ClickStopPropagationDirective,
    ParseIntPipe,
    CompetersInputComponent,
    HideTabGroupHeaderDirective,
    SplitCamelCasePipe,
    MatTabsModule,
    MatIconModule,
    MatDividerModule,
    MatButtonModule
  ],
})
export class TicketModule {}
