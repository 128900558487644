<div class="flex flex-col h-full">
  <!-- header -->
  @if (!_hideTabGroupHeader$$()) {
    <div class="flex-none">
      <div
        *ngIf="TicketDetailSummary && stopwatchState"
        class="box-border p-3 h-24 flex flex-row justify-between items-center overflow-hidden bg-white">
        <!-- Street address, late color, date -->
        <div class="flex-none min-w-60 max-w-60 lg:max-w-none">
          <!-- flex-none so this row is always w-60 -->
          <div class="flex flex-row items-start justify-start">
            <!-- Row 1 - Ticket Late Colour -->
            <div class="pt-1 pr-2">
              <mat-icon class="w-5 h-5" [style.color]="lateColour" svgIcon="point"></mat-icon>
            </div>
            <!-- Row 2 - Ticket address, time, and tags  -->
            <div class="flex-column justify-normal font-rajdhani uppercase font-semibold text-lg">
              <div class="flex flex-row items-center">
                <mat-icon svgIcon="pin_black" class="w-5 h-5 text-primary pr-1" />
                <p class="my-1 text-primary">
                  {{ TicketDetailSummary.Address }}
                </p>
              </div>
              <div class="flex flex-row items-center">
                <mat-icon svgIcon="calendar" class="w-5 h-5 text-accent-icons pr-1" />
                <p class="my-1 text-accent-icons">{{ timeFormatted }}</p>
              </div>
              <div class="my-1 max-w-64 overflow-auto sm:max-w-full">
                <app-ticket-list-tags [AssignmentID]="TicketDetailSummary.AssignmentID"></app-ticket-list-tags>
              </div>
            </div>
          </div>
        </div>

        <!-- Timer and Clear buttons -->
        <div class="flex-1">
          <!-- flex-1 so this row changes its width -->
          <div class="flex shrink flex-col-reverse xs:flex-row items-end xs:items-start justify-end pr-2">
            <!-- Clear dropdown -->
            <div *ngIf="showClearOptions">
              <button
                [matMenuTriggerFor]="menu"
                class="bg-white h-9 rounded-md border-[#707070] border-[0.25px] hover:cursor-pointer flex items-center justify-center text-md xs:text-lg font-rajdhani font-semibold uppercase max-w-12 xs:max-w-36 xs">
                <span>Clear </span>
                <mat-icon svgIcon="drop_down" class="mx-0 p-0 xs:pl-1 hidden xs:flex" />
              </button>

              <mat-menu #menu="matMenu">
                <button
                  mat-menu-item
                  class="font-rajdhani uppercase font-semibold text-md"
                  *ngFor="let option of clearOptions"
                  (click)="clearTicket(option)">
                  {{ option.Description }}
                </button>
              </mat-menu>
            </div>

            <!-- Timer button -->

            <div class="flex flex-col items-end justify-end">
              <button
                *ngIf="stopwatchState"
                (click)="onTimeToggle()"
                mat-flat-button
                [ngClass]="stopwatchColour"
                class="pl-3 flex flex-col items-center mr-0 mb-[2px]">
                <div class="w-6 h-6 px-3">
                  <mat-icon svgIcon="{{ stopwatchState }}" />
                </div>
              </button>
              <mat-progress-bar
                *ngIf="stopwatchProgressBarColour && stopwatchProgressBarMode"
                class="rounded-md w-12 scale-y-50 mb-[2px]"
                [color]="stopwatchProgressBarColour"
                [mode]="stopwatchProgressBarMode"
                value="100"></mat-progress-bar>
            </div>
          </div>
        </div>
      </div>
    </div>
  }

  <!-- divider -->
  <div class="bg-white">
    <hr class="border-1 border-solid border-divider border-x-0 border-t-0 bg-white m-0 ml-9" />
  </div>

  <!-- Tab Groups -->
  <div class="flex-grow overflow-hidden h-full">
    <mat-tab-group
      #matTabGroup
      fitInkBarToContent="true"
      mat-align-tabs="center"
      animationDuration="250ms"
      [(selectedIndex)]="selected"
      (selectedTabChange)="onTabChange($event, matTabGroup)"
      [appHideTabGroupHeader]="_hideTabGroupHeader$$()"
      class="h-full font-rajdhani uppercase text-lg bg-white overflow-hidden">
      <mat-tab>
        <ng-template mat-tab-label class="border-solid border border-black">
          <div
            class="w-11 h-8 text-blue flex justify-center items-center"
            [ngClass]="{ 'bg-primary rounded-md text-white': selected === 0, 'text-[#414042]': selected !== 0 }">
            <mat-icon svgIcon="info" />
          </div>
        </ng-template>
        <app-ticket-details
          (finishAssignUserToTicket)="onAssignTicketToUser($event)"
          *ngIf="TicketDetails"
          [TicketDetails]="TicketDetails"
          [PrimaryID]="PrimaryID"
          [TicketDetailSummary]="TicketDetailSummary"
          (TimeInOutToggle)="onTimeInOutToggle($event)"
          (ExcavationDateChange)="onExcavationDateChange($event)"
          (cancelTicketEvent)="onCancelTicketEvent()"
          (finishSendToLSP)="sendToLSP($event)"
          (EmailChange)="onEmailChange($event)"
          (InputChange)="onInputChange($event)"
          (TicketTagsChange)="onTicketTagsChange()"
          (AppointmentDateChange)="onAppointmentDateChange($event)"
          (OpenGoogleMapsDirections)="openGoogleMapsDirections()"
          (swipe)="onSwipe()"></app-ticket-details>
      </mat-tab>

      <mat-tab #ticketMap="matTab">
        <ng-template mat-tab-label>
          <div
            class="w-11 h-8 flex justify-center items-center"
            [ngClass]="{ 'bg-primary rounded-md text-white': selected === 1, 'text-[#414042]': selected !== 1 }">
            <mat-icon svgIcon="map" />
          </div>
        </ng-template>
        @if (useOpenLayerMappingService) {
          <app-ticket-drawing
            #openLayerMap
            [drawingFormType]="getSettingValue(SettingID.SHOW_DRAWING_FORM) | parseInt:10"
            (drawingModeChange)="_ticketMapDrawingModeActive$$.set($event)"
            [isActive]="ticketMap.isActive"
            [ticket]="ticket"
            [primaryID]="ticketPrimary?.PrimaryID ?? undefined"
            [assignmentID]="ticketPrimary?.AssignmentID ?? undefined"
            [utilities]="activeUtilities"
            (documentUpload)="onDocumentUpload($event)"
            (Swipe)="onSwipe()"
            (mapControlEnabled)="checkMapControls($event)"></app-ticket-drawing>
        } @else {
          <app-ticket-map-viewer
            *ngIf="!useOpenLayerMappingService"
            [TicketMap]="TicketMap"
            (documentUpload)="onDocumentUpload($event)"
            (OpenGoogleMapsDirections)="openGoogleMapsDirections()"
            [layerOptions]="layerOptions"
            (Swipe)="onSwipe()"
            (mapControlEnabled)="checkMapControls($event)"></app-ticket-map-viewer>
        }
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <div
            class="w-11 h-8 flex justify-center items-center"
            [ngClass]="{ 'bg-primary rounded-md text-white': selected === 2, 'text-[#414042]': selected !== 2 }">
            <mat-icon svgIcon="report" />
          </div>
        </ng-template>
        <ng-template matTabContent>
          <app-ticket-documents
            [DocumentsList]="DocumentList"
            [AssignmentID]="AssignmentID"
            [PrimaryID]="PrimaryID"
            [ticket]="ticket"
            [ExpectedDocHashDifference]="expectedDocHash"
            (documentSelect)="onDocumentDownload($event)"
            (documentUpload)="onDocumentUpload($event)"
            (documentDelete)="onDocumentDelete($event)"
            (markSendable)="onMarkSendable($event)"
            (captionChange)="onCaptionChange($event)"
            (swipe)="onSwipe()"></app-ticket-documents>
        </ng-template>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <div
            class="w-11 h-8 flex justify-center items-center"
            [ngClass]="{ 'bg-primary rounded-md text-white': selected === 3, 'text-[#414042]': selected !== 3 }">
            <mat-icon svgIcon="autolog" />
          </div>
        </ng-template>
        <ng-template matTabContent>
          <app-ticket-autologs
            [AutologList]="AutologList"
            (newAutolog)="onCreateAutolog()"
            (swipe)="onSwipe()"></app-ticket-autologs>
        </ng-template>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <div
            class="w-11 h-8 flex justify-center items-center"
            [ngClass]="{ 'bg-primary rounded-md text-white': selected === 4, 'text-[#414042]': selected !== 4 }">
            <mat-icon svgIcon="completions" />
          </div>
        </ng-template>
        <ng-template matTabContent>
          <app-ticket-completions
            [CompletionsBillingDetails]="CompletionsBillingDetails"
            [CompletionsPrimaryDetails]="CompletionsPrimaryDetails"
            [CompletionsCommonDetails]="CompletionsCommonDetails"
            [primaryID]="PrimaryID | parseInt"
            [assignmentID]="AssignmentID | parseInt"
            [isU2AuditTicket]="isU2AuditTicket"
            [activeUtilities]="activeUtilities"
            [locateStatusID]="ticket.locateStatusID ?? ticket.LocateStatusID"
            (CompletionsChanges)="onCompletionsChanges($event)"
            (UtilitiesChanged)="onUtilitiesChanged($event)"
            (VerifyEvent)="verify($event)"
            (MarkAsCompleteEvent)="onComplete($event)"
            (swipe)="onSwipe()"></app-ticket-completions>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
