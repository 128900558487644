import Layer from 'ol/layer/Layer';
import { Injectable } from '@angular/core';
import TileState from 'ol/TileState.js';
import { DatabaseLayer } from '../types/layer.types';
import { LoadFunction } from 'ol/Tile';

@Injectable({
  providedIn: 'root',
})
export class EsriTileLoaderService {
  createDynamicLoader(layer: Layer): LoadFunction {
    return async (tile, src) => {
      const srcUrl = new URL(src);
      const formData = this.extractUrlParamsToFormData(srcUrl);

      this.addAuthTokenIfPresent(formData, layer);
      this.addDynamicLayers(formData, layer);

      try {
        const response = await fetch(srcUrl.toString(), {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          tile.setState(TileState.ERROR);
          return;
        }

        const blob = await response.blob();
        // @ts-ignore
        tile.getImage().src = URL.createObjectURL(blob);
      } catch (e) {
        console.warn('Failed to load tile', e);
        tile.setState(TileState.ERROR);
      }
    };
  }

  createBasicLoader(layer: Layer): LoadFunction {
    return async (tile, src) => {
      const srcUrl = new URL(src);
      if (layer.get('token')) {
        srcUrl.searchParams.set('token', layer.get('token'));
      }
      try {
        const response = await fetch(srcUrl.toString());

        if (!response.ok) {
          tile.setState(TileState.ERROR);
          return;
        }

        const blob = await response.blob();
        // @ts-ignore
        tile.getImage().src = URL.createObjectURL(blob);
      } catch (e) {
        console.warn('Failed to load tile', e);
        tile.setState(TileState.ERROR);
      }
    };
  }

  private extractUrlParamsToFormData(url: URL): FormData {
    const formData = new FormData();
    const params = Array.from(url.searchParams.entries());
    for (const [key, value] of params) {
      formData.append(key, value);
      url.searchParams.delete(key);
    }
    return formData;
  }

  private addAuthTokenIfPresent(formData: FormData, layer: Layer): void {
    const token = layer.get('token');
    if (token) {
      formData.set('token', token);
    }
  }

  private addDynamicLayers(formData: FormData, layer: Layer): void {
    const dynamicLayers = layer
      .get('subLayers')
      .filter((x: DatabaseLayer) => x.config?.isVisible)
      .map((x: DatabaseLayer) => ({
        id: x.serviceSubLayerID,
        source: {
          type: 'mapLayer',
          mapLayerId: x.serviceSubLayerID,
        },
        drawingInfo: {
          showLabels: x.config?.showLabels ?? false,
        },
      }));
    formData.append('dynamicLayers', JSON.stringify(dynamicLayers));
  }
}
