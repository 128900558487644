import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, concatMap, from, Observable, of, Subscription, tap, throwError } from 'rxjs';
import JSZip from 'jszip';
import { DownloadDocumentService } from 'src/app/modules/core/services/document/download-document.service';
import { DocumentService } from 'src/app/modules/core/services/document/document.service';
import { UtilocateDocumentsCacheService } from '../services/cache/utilocate-documents.service';
import { NodeDocumentService } from '../services/documents/node-document.service';
import { zipFile } from "src/app/modules/core/services/document/zip-helper";
import { SnackbarService } from '../../snackbar/snackbar.service';
import { SnackbarType } from '../../snackbar/snackbar/snackbar';

@Injectable({
  providedIn: 'root',
})
export class TicketDocumentsService {
  private isHeldDownSubject = new BehaviorSubject<boolean>(false);
  isHeldDown$: any = this.isHeldDownSubject.asObservable();

  private docsSubject = new BehaviorSubject<any>([]);

  private reloadDocsSubject = new BehaviorSubject<boolean>(false);
  reloadTab$: any = this.reloadDocsSubject.asObservable();

  private disableSyncSubject = new BehaviorSubject<boolean>(true);
  disableSync$: any = this.disableSyncSubject.asObservable();

  combinedArrays: any = [];
  private queueSubscription: Subscription;

  private documentQueue: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private mutationQueue: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  private isUploading: boolean = false;
  private isUploadingMutation: boolean = false;

  constructor(
    private downloadDocService: DownloadDocumentService,
    private documentService: DocumentService,
    private documentCacheService: UtilocateDocumentsCacheService,
    private nodeDocumentService: NodeDocumentService,
    private snackBarService: SnackbarService
  ) {}

  addDocument(document: any) {
    const currentQueue = this.documentQueue.value;
    this.documentQueue.next([
      ...currentQueue,
      { document, status: 'pending', progress: 0, retries: 0, uploadSubscription: null },
    ]);
    this.startQueue();
  }

  addMutation(document: any) {
    const currentQueue = this.mutationQueue.value;

    this.mutationQueue.next([
      ...currentQueue,
      { document, status: 'pending', progress: 0, retries: 0, uploadSubscription: null },
    ]);
    this.startMutationQueue();
  }

  restartQueue() {
    this.documentQueue.next(this.documentQueue.value.map((doc) => ({ ...doc, status: 'pending', retries: 0 })));
    this.startQueue();
  }
  restartMutationQueue() {
    this.mutationQueue.next(this.mutationQueue.value.map((doc) => ({ ...doc, status: 'pending' })));
    this.startMutationQueue();
  }

  startQueue() {
    if (this.isUploading) return;
    console.log('startQueue');
    this.isUploading = true;
    this.processQueue();
  }

  processQueue() {
    from(this.documentQueue.value)
      .pipe(
        concatMap((doc) => {
          if (doc.status === 'errored') {
            return of(null); // Skip errored documents
          }

          return this.uploadDocument(doc).pipe(
            concatMap((res) => {       
                     
              if (res !== true) {
                return throwError(() => new Error('Error uploading document')); // Trigger an error
              }
              doc.status = 'completed'; // Mark document as completed
              return of(res);
            }),
            catchError((err) => {
              doc.status = 'failed'; // Mark document as failed
              doc.retries++; // Increment the retries
              this.requeueFailedDocument(doc); // Requeue the failed document
              return of(null); // Return null after error to continue processing
            })
          );
        })
      )
      .subscribe({
        complete: () => {
          this.isUploading = false; // Set uploading flag to false when complete

          // If there are pending documents, restart the queue
          if (this.documentQueue.value.some((doc) => doc.status === 'pending')) {
            this.startQueue();
          }
        },
      });
  }

  startMutationQueue() {
    if (this.isUploadingMutation) return;
    console.log('startMutationQueue');

    this.isUploadingMutation = true;
    this.processMutationQueue();
  }

  processMutationQueue() {
    from(this.mutationQueue.value)
      .pipe(
        concatMap((doc) => {
          if (doc.status === 'errored') {
            return of(null); // Skip errored documents
          }
          // Check document type and handle accordingly
          if (doc.document.type === 'delete') {
            // Call deleteDoc if doc.type is delete
            return this.deleteDoc(doc).pipe(
              concatMap((res) => {
                console.log(res);

                if (res != true) {
                  return throwError(() => new Error('Error deleting document')); // Trigger an error
                }
                doc.status = 'completed';
                return of(res);
              }),
              catchError((err) => {
                doc.status = 'failed';
                doc.retries++;
                this.requeueFailedMutatedDocument(doc); // Requeue on error
                return of(null);
              })
            );
          } else if (doc.document.type === 'sendable') {
            // Call markSendable if doc.type is sendable
            return this.markSendable(doc).pipe(
              concatMap((res) => {
                console.log(res);

                if (res[0] !== true) {
                  return throwError(() => new Error('Error marking document as sendable')); // Trigger an error
                }
                doc.status = 'completed';
                return of(res);
              }),
              catchError((err) => {
                doc.status = 'failed';
                doc.retries++;
                this.requeueFailedMutatedDocument(doc); // Requeue on error
                return of(null);
              })
            );
          }
          return of(null); // If neither 'delete' nor 'sendable', return an empty observable
        })
      )
      .subscribe({
        complete: () => {
          this.isUploadingMutation = false;
          if (this.mutationQueue.value.some((doc) => doc.status === 'pending')) {
            this.startMutationQueue(); // Restart the queue if there are pending documents
          }
        },
      });
  }

  uploadDocument(document: any): Observable<any> {
    if (document.status === 'cancelled' || document.status === 'errored' || document.status === 'failed') {
      return null;
    }
    
    return from(this.nodeDocumentService.uploadDoc(document.document, document.document.metadata)).pipe(
      tap(async (result) => {
        if (result) {

          // Remove the document from the queue after processing each one
          const currentQueue = this.documentQueue.value;
          const updatedQueue = currentQueue.filter((d) => d.document.s3UUID !== document.document.s3UUID);
          this.documentQueue.next(updatedQueue);

          this.updateDocList({
            assignmentID: document.document.AssignmentID,
            CreationDate: document.document.CreationDate,
            DocumentID: document.document.DocumentID,
            DocumentTypeID: document.document.DocumentTypeID,
            FileName: document.document.FileName,
            bFieldAdded: document.document.bFieldAdded,
            file: document.document.file,
            isDownloaded: document.document.isDownloaded,
            S3DocumentID: document.document.S3DocumentID,
            isS3Document: document.document.isS3Document,
            isSendable: document.document.isSendable,
            selected: document.document.selected,
            Caption: document.document.Caption,
            Status: 'completed',
            s3UUID: document.document.s3UUID,
          });

          await this.documentCacheService.removeSingleDocumentFromQueue(
            document.document.AssignmentID,
            document.document.DocumentID
          );
          await this.documentCacheService.addDocumentToAssignment(
            document.document.tmpDocRow,
            document.document.isS3Document,
            document.document.AssignmentID
          );
        }
      }),
      catchError((err) => {
        console.log(err);

        console.error('Upload failed:', err);
        return of(err); // Return the error as an observable
      })
    );
  }

  deleteDoc(document: any): Observable<any> {
    if (document.status === 'cancelled' || document.status === 'errored' || document.status === 'failed') {
      return null;
    }
    
  return from(this.downloadDocService.removeDocumentRow(document.document.DocumentID.toString(), document.document.isS3Document)).pipe(
    tap((result) => {        
      if (result) {
        console.log(result);
        
        const existingObjIndex = this.combinedArrays.findIndex((item) => item.DocumentID == document.document.DocumentID && item.isS3Document == document.document.isS3Document);
        
        this.combinedArrays.splice(existingObjIndex, 1);
        this.addToDocs(this.combinedArrays)
        this.documentCacheService.removeSingleDocumentFromManifest(document.document.AssignmentID, document.document.s3UUID);
          // Remove the document from the queue after processing each one
        const currentQueue = this.mutationQueue.value;
        
        const updatedQueue = currentQueue.filter(d => d.document.DocumentID != document.document.DocumentID);

          this.mutationQueue.next(updatedQueue);

          this.snackBarService.openSnackbar('Deleted document successfully', SnackbarType.success);
        }
      }),

      catchError((err) => {
        console.error('Deletion failed:', err);
        return of(err); // Return the error as an observable
      })
    );
  }

  clearMutationQueue() {
    this.mutationQueue.next([]);
  }

  clearDocumentQueue() {
    this.documentQueue.next([]);
  }

  markSendable(document: any): Observable<any> {
    if (document.status === 'cancelled' || document.status === 'errored' || document.status === 'failed') {
      return null;
    }

    return from(
      this.downloadDocService.updateDocumentRow(
        document.document.DocumentID,
        document.document.isS3Document,
        document.document.changes
      )
    ).pipe(
      tap(async (result) => {
        if (result[0] == true) {
          if (document.document.isS3Document) {
            await this.documentCacheService.updateS3TicketDocuments(
              { isSendable: document.document.changes[0].value },
              document.document.DocumentID,
              document.document.AssignmentID
            );
          } else {
            await this.documentCacheService.updateTicketDocuments(
              { isSendable: document.document.changes[0].value },
              document.document.DocumentID,
              document.document.AssignmentID
            );
          }
          const existingObjIndex = this.combinedArrays.findIndex(
            (item) =>
              item.DocumentID === document.document.DocumentID && item.isS3Document === document.document.isS3Document
          );
          console.log(existingObjIndex);

          if (existingObjIndex !== -1) {
            this.combinedArrays[existingObjIndex].isSendable = document.document.changes[0].value;
            console.log(this.combinedArrays);

            this.addToDocs(this.combinedArrays);
          }
          const currentQueue = this.mutationQueue.value;
          const updatedQueue = currentQueue.filter((d) => d.document.DocumentID != document.document.DocumentID);
          this.mutationQueue.next(updatedQueue);
          console.log(this.mutationQueue.value);
        }
      }),

      catchError((err) => {
        console.error('Marking sendable failed:', err);
        return of(err); // Return the error as an observable
      })
    );
  }

  requeueFailedDocument(doc: any) {
    console.log('requeueFailedDocument');
    const currentQueue = this.documentQueue.value;
    if (doc.retries >= 1) {
      console.log('Document failed', doc);
      this.snackBarService.openSnackbar(
        'Error occurred during upload. Please sync to try again',
        SnackbarType.error,
        'Close'
      );

      this.documentQueue.next([
        ...currentQueue.filter((d) => d.document.DocumentID !== doc.document.DocumentID),
        { ...doc, status: 'errored', progress: 0, retries: doc.retries },
      ]);
      return null;
    }
    this.documentQueue.next([
      ...currentQueue.filter((d) => d.document.DocumentID !== doc.document.DocumentID),
      { ...doc, status: 'pending', progress: 0, retries: doc.retries },
    ]);
    this.processQueue();
  }

  requeueFailedMutatedDocument(doc: any) {
    console.log('requeueFailedMutatedDocument');

    const currentQueue = this.mutationQueue.value;
    if (doc.retries >= 1) {
      console.log('Document failed after 1 retry:', doc);
      if (doc.document.type === 'delete') {
        this.snackBarService.openSnackbar(
          'Error occurred during deletion. Please try again',
          SnackbarType.error,
          'Close'
        );
      }
      if (doc.document.type === 'sendable') {
        this.snackBarService.openSnackbar(
          'Error occurred while marking as sendable. Please try again',
          SnackbarType.error,
          'Close'
        );
      }
      this.mutationQueue.next([
        ...currentQueue.filter((d) => d.document.DocumentID != doc.document.DocumentID)
      ]);
      return null;
    }

    this.mutationQueue.next([
      ...currentQueue.filter((d) => d.document.DocumentID !== doc.document.DocumentID),
      { ...doc, status: 'pending', progress: 0, retries: doc.retries },
    ]);
    this.processMutationQueue();
  }

  async cancelDocumentUpload(doc: any) {
    try {      
      const currentQueue = this.documentQueue.value;
      const updatedQueue = currentQueue.filter((d) => d.document.DocumentID != doc.DocumentID);
      this.documentQueue.next(updatedQueue);
      await this.documentCacheService.removeSingleDocumentFromManifest(doc.AssignmentID, doc.DocumentID);
      await this.documentCacheService.removeSingleDocumentFromQueue(doc.AssignmentID, doc.DocumentID);
    } catch (error) {
      console.error('Error occurred while removing document:', error);
    }
  }

  getDocumentQueue(): Observable<any[]> {
    return this.documentQueue.asObservable();
  }

  getDocumentQueueValue() {
    return this.documentQueue.value;
  }

  /**
   * Sets docs list into select mode
   */
  setIsHeldDown(value: boolean) {
    this.isHeldDownSubject.next(value);
  }

  get docRows$(): Observable<any> {
    return this.docsSubject.asObservable();
  }

  setreloadTab(value: boolean) {
    this.reloadDocsSubject.next(value);
  }

  disableSync(value) {
    this.disableSyncSubject.next(value);
  }

  /**
   * Clears the document list observable
   */
  clearDocs() {
    this.docsSubject.next([]);
  }

  /**
   * Sets the document list observable
   */
  addToDocs(value) {
    this.docsSubject.next(value);
  }

  /**
  Combines two arrays by filename which is being used to combine S3 Docs files with there file names
   */

  combineArrays(array1: any[], array2: any[]): any[] {
    const mergedArray: any[] = [...array1];

    array2.forEach((obj) => {
      const existingObjIndex = mergedArray.findIndex((item) => item.FileName === obj.FileName);
      if (existingObjIndex !== -1) {
        // If the filename exists in array1, add the propertys from array2
        mergedArray[existingObjIndex] = { ...mergedArray[existingObjIndex], ...obj };
      } else {
        // If the filename does not exist in array1, add the object from array2 to mergedArray
        mergedArray.push(obj);
      }
    });
    return mergedArray;
  }

  /**
  Combines two arrays by documnet Id which is being used to combine S3 docs combined array with tbcompletions_documents and tbcompletions_S3documents
   */

  combineDocArrays(array1: any[], array2: any[]): any[] {
    const mergedArray: any[] = [...array1];

    array2.forEach((obj) => {
      const existingObjIndex = mergedArray.findIndex((item) => item.DocumentID === obj.DocumentID);
      const existingObjS3Index = mergedArray.findIndex((item) => item.DocumentID === obj.S3DocumentID);

      if (existingObjIndex !== -1 && existingObjIndex !== mergedArray.length) {
        mergedArray[existingObjIndex] = {
          ...mergedArray[existingObjIndex],
          ...obj,
          FileName: mergedArray[existingObjIndex].FileName,
        };
      } else if (existingObjS3Index !== -1 && existingObjS3Index !== mergedArray.length) {
        // If the filename does not exist in array1, add the object from array2 to mergedArray
        mergedArray[existingObjS3Index] = {
          ...mergedArray[existingObjS3Index],
          ...obj,
          FileName: mergedArray[existingObjS3Index].FileName,
        };
      }
    });
    this.combinedArrays = mergedArray;
    return mergedArray;
  }

  /**
  Adds a selected flag to the docs list so it is highlighted
   */
  addSelected(DocumentID: any, isS3: any, selected: any): any {
    const existingObjIndex = this.combinedArrays.findIndex(
      (item) => item.DocumentID === DocumentID && item.isS3Document === isS3
    );
    if (existingObjIndex !== -1) {
      this.combinedArrays[existingObjIndex].selected = selected;
    }
    this.addToDocs(this.combinedArrays);
  }

  addFile(DocumentID: any, isS3: any, url: any): any {
    const link = url.split(',');
    const modifiedUrl = 'data:image/jpeg;base64,' + link[1];

    const existingObjIndex = this.combinedArrays.findIndex(
      (item) => item.DocumentID === DocumentID && item.isS3Document === isS3
    );
    if (existingObjIndex !== -1) {
      this.combinedArrays[existingObjIndex].file = modifiedUrl;
    }

    this.addToDocs(this.combinedArrays);
  }

  /**
   * Updates the caption of a document
   * - Updates the local row value in memory
   * - Updates the value in the DB
   *
   * @param {number} DocumentID
   * @param {boolean} isS3Document
   * @param {string} caption
   * @return {Promise<any>}
   * @memberof TicketDocumentsService
   */
  async updateCaption(AssignmentID: any, DocumentID: number, isS3Document: boolean, caption: string) {
    //update the document internally

    //for some reason, saving with newline characters is not allowed. So, remove all special characters to be safe.
    caption = caption ? caption.trim() : ''; //set to empty string if caption is null
    caption = caption.replace(/\s/g, ' ');
    caption = caption.replace(/(\r\n|\n|\r)/gm, ' ');

    const existingObjIndex = this.combinedArrays.findIndex(
      (item) => item.DocumentID === DocumentID && item.isS3Document === isS3Document
    );
    if (existingObjIndex !== -1) {
      this.combinedArrays[existingObjIndex].Caption = caption;
      this.addToDocs(this.combinedArrays);
    }

    //if our document is on the server, call the API to update the caption
    if (this.combinedArrays[existingObjIndex].bFieldAdded != 1) {
      return this.downloadDocService.updateDocumentRow(DocumentID, isS3Document, [
        { key: 'Caption', value: caption.toString() },
      ]);

      //else our document is local, so we need to update the metadata in the local db
    } else {
      return this.documentCacheService.updateDocumentMetadata(AssignmentID, DocumentID, isS3Document, [
        { key: 'Caption', value: caption.toString() },
      ]);
    }
  }

  /**
  clears all selected flags
   */

  clearSelected() {
    for (const i of this.combinedArrays) {
      i.selected = false;
    }
    this.addToDocs(this.combinedArrays);
    this.setIsHeldDown(false);
  }

  /**
  Downloads documents from S3 and creates the combined document list
   */

  async createDocList(AssignmentID) {
    const docZip = await this.downloadDocService.getDocumentAll(AssignmentID);
    if (docZip) {
      const docZipBuffer = await fetch(docZip['buffer']).then((r) => r.blob());
      const docList = docZip['documentList'];
      const zip = await JSZip.loadAsync(docZipBuffer);
      const files = [];

      zip.forEach((relativePath, zipEntry) => {
        files.push({
          FileName: zipEntry['name'],
          file:
            'data:image/jpeg;base64,' +
            this.documentService.arrayBufferToBase64(zipEntry['_data']['compressedContent']),
          selected: false,
        });
      });

      const unzippedFiles = files;

      const mergedS3Docs = this.combineArrays(docList, unzippedFiles);
      let mergedDocsArrays = this.combineDocArrays(this.combinedArrays, mergedS3Docs);
      // mergedDocsArrays = mergedDocsArrays.filter(item => item.file);
      this.combinedArrays = mergedDocsArrays;
      this.addToDocs(mergedDocsArrays);
      return mergedDocsArrays;
    }
  }

  initDocList(DocumentsList) {
    this.combinedArrays = DocumentsList;
    this.addToDocs(DocumentsList);
    return DocumentsList;
  }

  updateDocList(newDoc) {
    this.combinedArrays.push(newDoc);
    this.addToDocs(this.combinedArrays);
  }

  ngOnDestroy() {
    this.queueSubscription.unsubscribe();
    this.documentQueue.unsubscribe();
    this.mutationQueue.unsubscribe();
  }

  public documentsAreWaitingUpload(): boolean {
    return this.documentQueue.value.length > 0;
  }
}
